import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import {
    actionDialogFacetsChanged,
    actionDialogFilterPluginsChanged,
    actionDialogPaginationChanged,
    actionDialogSearchTermChanged,
    clearActionDialogData,
    clearActionFromCache,
    createActionSuccess,
    deleteActions,
    findAction,
    loadActionDialogData,
    updateAction,
} from '../actions/action-editor.actions';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Action } from '../../action/model/action';
import { actionEditorSelectors } from '../selectors/action-editor.selectors';
import { LoggerService } from '@backoffice/utils';
import { plainToInstance } from 'class-transformer';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { ActionService } from '../../../../../../editor/data-access/action/src/lib/services/action.service';
import { OverviewActionDto } from '../../dto/overview/overview-action.dto';
import { Page } from '@shared/data-access';
import { AppFacade } from '@core/facades/app.facade';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ActionCreatedDto } from '../../action/dto/action-created.dto';
import { ActionTestDto, Argument, SelectedFacets } from '@backoffice/data-access/editor';
import { ComponentType } from '@angular/cdk/overlay';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialog } from '@angular/material/dialog';
import { ActionTestService } from '../../action/services/action-test.service';
import { ActionTestOutputAssertion } from '../../action/dto/action-test-output-assertion.dto';
import { ActionCtxList } from '../../action/model/action-ctx-list';

// TODO: dienen moet applicatie wijd.
interface Context {
    userLanguage: string;
    selectedCompany: CompanyDto;
    selectedApplication: ApplicationDto;
}

@Injectable()
export class ActionEditorFacade {
    currentContext$: Observable<Context | undefined> = this.applicationStateStore.select(selectCurrentContext);

    get dialogData(): Observable<Page<OverviewActionDto> | undefined> {
        return this.store.select(actionEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(actionEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(actionEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(actionEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(actionEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins: boolean;
    }> {
        return this.store.select(actionEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly applicationStateStore: Store<ApplicationState>,
        private readonly actionService: ActionService,
        private readonly actionTestService: ActionTestService,
        private readonly appFacade: AppFacade,
        private readonly log: LoggerService,
        private readonly dialog: MatDialog
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearActionDialogData());
    }

    executeAction(action: Action, _arguments: Argument[], outputAssertions?: ActionTestOutputAssertion[]) {
        this.log.info('Create new action execution');
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            switchMap(([{ id: companyId }, { id: applicationId }]) => {
                return this.actionService.createActionExecution(applicationId, companyId, {
                    actionId: action.id,
                    trigger: 'test',
                    triggerType: 'TEST',
                    applicationId: applicationId,
                    companyId: companyId,
                    arguments: _arguments,
                    formResults: [],
                    outputAssertions: outputAssertions,
                });
                return of(null);
            })
        );
    }

    getActionTests(actionId: string) {
        this.log.info('Create new action test');
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            switchMap(([{ id: companyId }, { id: applicationId }]) => {
                return this.actionTestService.getActionTests(applicationId, companyId, actionId);
            })
        );
    }

    deleteActionTest(actionId: string, actionTestId: string) {
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            switchMap(([{ id: companyId }, { id: applicationId }]) => {
                return this.actionTestService.deleteActionTest(applicationId, companyId, actionId, actionTestId);
            })
        );
    }

    saveActionTest(actionId: string, actionTest: ActionTestDto) {
        this.log.info('Create new action test');
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            switchMap(([{ id: companyId }, { id: applicationId }]) => {
                if (actionTest.id) {
                    return this.actionTestService.updateActionTest(
                        applicationId,
                        companyId,
                        actionId,
                        actionTest.id,
                        Object.assign(
                            {
                                companyId: companyId,
                                applicationId: applicationId,
                            },
                            actionTest
                        )
                    );
                } else {
                    return this.actionTestService.createActionTest(
                        applicationId,
                        companyId,
                        actionId,
                        Object.assign(
                            {
                                companyId: companyId,
                                applicationId: applicationId,
                            },
                            actionTest
                        )
                    );
                }
            })
        );
    }

    create(componentType: ComponentType<any>): Observable<string> {
        this.log.info('Create new action');
        return combineLatest([
            this.appFacade.selectedCompany,
            this.appFacade.selectedApplication,
            this.dialog
                .open(componentType, Object.assign({ disableClose: true }, backofficeEnvironment.dialogConfig.extrasmall))
                .afterClosed(),
        ]).pipe(
            take(1),
            switchMap(
                ([
                    { id: companyId },
                    { id: applicationId },
                    {
                        name,
                        type,
                        authenticationLogicInPlace,
                        authenticationLogic,
                        apiDocumentation,
                        apiAuthentication,
                        apiHost,
                        apiAuthenticationDocumentation,
                    },
                ]) => {
                    return this.actionService
                        .create({
                            companyId,
                            applicationId,
                            name,
                            type,
                            authenticationLogicInPlace,
                            authenticationLogic,
                            apiDocumentation,
                            apiAuthentication,
                            apiHost,
                            apiAuthenticationDocumentation,
                        })
                        .pipe(
                            tap(({ id }) => this.store.dispatch(createActionSuccess({ id }))),
                            map(({ id }) => id)
                        );
                }
            )
        );
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(actionDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(actionDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(actionDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(actionDialogFilterPluginsChanged({ filterPlugins }));
    }

    copyAction(copyFromActionId: string): Observable<ActionCreatedDto> {
        return this.currentContext$.pipe(
            filter((currentContext: any | Context): currentContext is Context => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.create({
                    companyId: currentContext.selectedCompany.id,
                    applicationId: currentContext.selectedApplication.id,
                    copyFromActionId,
                });
            })
        );
    }

    copyActions(actions: { id: string }[]): Observable<ActionCreatedDto[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return forkJoin(actions.map(action => this.copyAction(action.id)));
            })
        );
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting actions <${ids}>`);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteActions({ ids }));
        }
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadActionDialogData());
    }

    update(action: Action): void {
        console.log('Updating action');
        this.store.dispatch(updateAction({ action }));
    }

    findAll(keyword: string, filters: string[], orderBy: string, page: number, maxResults: number): Observable<Page<OverviewActionDto>> {
        return this.currentContext$.pipe(
            filter((currentContext: Context | undefined): currentContext is Context => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.findAll(currentContext.selectedCompany.id, currentContext.selectedApplication.id, {
                    keyword,
                    orderBy,
                    filters,
                    page,
                    maxResults,
                });
            })
        );
    }

    getAllContexts(): Observable<ActionCtxList[]> {
        return this.currentContext$.pipe(
            filter((currentContext: Context | undefined): currentContext is Context => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.findContexts(currentContext.selectedCompany.id, currentContext.selectedApplication.id);
            })
        );
    }

    clearFromCache(id: string) {
        this.store.dispatch(clearActionFromCache({ id }));
    }

    findById(id: string): Observable<Action> {
        this.log.info(`Find action by id ${id}`);
        this.store.dispatch(findAction({ id }));
        return this.store.select(actionEditorSelectors.byId(id)).pipe(
            filter((action: Action | undefined): action is Action => !!action),
            map(action => plainToInstance(Action, action))
        );
    }
}
