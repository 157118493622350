import { Injectable } from '@angular/core';
import { EditorState, Tab } from '../editor.state';
import { Store } from '@ngrx/store';
import { TabDefinition } from '../../interfaces/tab-definition.interface';
import { editorSelectors } from '../selectors/editor.selectors';
import { Observable } from 'rxjs';
import { ConfirmDialog } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';
import { closeTab, registerTab, selectTab, updateTab } from '../actions/editor.actions';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class EditorFacade {
    constructor(
        private readonly store: Store<EditorState>,
        public readonly confirmDialog: ConfirmDialog
    ) {}

    get tabs(): Observable<Tab[]> {
        return this.store.select(editorSelectors.tabs);
    }

    get activeTab(): Observable<Tab> {
        return this.store.select(editorSelectors.activeTab).pipe(
            filter(tab => !!tab),
            map(tab => tab as Tab)
        );
    }

    updateTab(definition: TabDefinition, updateReference: boolean = false): void {
        this.store.dispatch(updateTab({ definition, updateReference }));
    }

    registerTab(definition: TabDefinition): void {
        this.store.dispatch(registerTab({ definition }));
    }

    selectTab(typeId: string): void {
        this.store.dispatch(selectTab({ typeId }));
    }

    closeTab(
        typeId: string,
        tabType:
            | 'action'
            | 'api'
            | 'data'
            | 'data-format'
            | 'template'
            | 'media'
            | 'job'
            | 'designsystem'
            | 'rights'
            | 'group'
            | 'application-overview'
    ): void {
        this.store.dispatch(closeTab({ typeId, tabType }));
    }
}
