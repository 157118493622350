<div class="title-button-header">
    <h1 class="mb-2">{{ 'v2.plugins.overview.title' | translate }}</h1>
</div>
<div class="ml-3 mb-3 mr-3 mt-1 flex-col flex">
    <search-form
        data-name="plugins-search"
        [searchPlaceHolder]="'v2.plugins.overview.search'"
        (search)="handleSearchTermChanged($event)"></search-form>

    <ng-container *ngIf="installations$ | async as page">
        <ng-container *ngIf="page.content && page.content.length > 0; else noResult">
            <div class="plugins-search__results">
                <div class="overview-items-full">
                    <div class="overview-item-progress-wrapper" *ngFor="let installation of page.content">
                        <div class="overview-item">
                            <div class="thumbnail">
                                <img
                                    *ngIf="!installation.productIconUrl"
                                    src="/apps/no-code-x-backoffice/src/images/theme/image_placeholder.svg" />
                                <img
                                    *ngIf="!!installation.productIconUrl"
                                    [src]="
                                        environment.rest.v2.products + installation.productId + '/icon?v=' + installation.productIconUrl
                                    " />
                            </div>
                            <div class="name-data" (click)="onInstallationClick(installation.productId)">
                                <span class="name">{{ installation.productName }}</span>
                                <div class="product-reference">
                                    {{ 'v2.plugins.overview.version' | translate }} : {{ installation.versionName }}
                                </div>
                            </div>
                            <div class="actions gap-1">
                                <button
                                    class="primary-button button-medium"
                                    type="button"
                                    *ngIf="installation.updateAvailable"
                                    (click)="handleUpdatedClicked(installation)">
                                    <mat-icon>update</mat-icon>
                                    {{ 'v2.plugins.overview.actions.update' | translate }}
                                </button>
                                <button class="destructive-button button-medium" type="button" (click)="handleUninstall(installation)">
                                    <mat-icon>delete</mat-icon>
                                    {{ 'v2.plugins.overview.actions.uninstall' | translate }}
                                </button>
                            </div>
                        </div>
                        <ng-container *ngIf="processing$ | async as processing">
                            <ng-container *ngIf="processing === installation.id">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            <mat-paginator
                class="mt-2"
                [length]="page.count"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="handlePaginationChanged($event)"
                aria-label="Select page">
            </mat-paginator>
        </ng-container>
        <ng-template #noResult>
            <div class="pt-2 flex-auto w-[70%] mb-5 gap-1 flex flex-col scrollbar scrollbar-primary !overflow-x-visible self-center">
                <div class="flex-col flex justify-center items-center">
                    <img class="w-[350px] h-[150px] object-cover" src="/src/images/theme/no_results_found.gif" />
                    <h1 class="text-center !mb-2">We couldn't find any plugins within your application.</h1>
                    <p class="!mb-8 text-white">
                        Start building building your application extremely fast by installing pre-defined plugins. Head over to the hub &
                        discover!
                    </p>
                </div>
            </div>
        </ng-template>
    </ng-container>
</div>
