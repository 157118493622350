<mat-dialog-content *ngIf="{ application: this.application$ | async } as observables" class="scrollbar scrollbar-primary">
    <div class="tabs-header-wrapper">
        <ul
            class="tabs-header"
            data-tabs-toggle="#general-tab-content"
            role="tablist"
            data-tabs-inactive-classes="tabs-tab-inactive"
            data-tabs-active-classes="tabs-tab-active">
            <li class="tabs-tab">
                <button
                    id="general-tab"
                    class="tabs-tab-button"
                    aria-current="page"
                    data-tabs-target="#general"
                    role="tab"
                    aria-controls="general"
                    aria-selected="true">
                    <mat-icon data-name="general-info-tab" class="example-tab-icon">public</mat-icon>
                    {{ 'v2.application.edit.general.info' | translate }}
                </button>
            </li>
            <li class="tabs-tab">
                <button
                    id="meta-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#meta"
                    role="tab"
                    aria-controls="meta"
                    aria-selected="false">
                    <mat-icon data-name="meta-tab" class="example-tab-icon">feed</mat-icon>
                    {{ 'v2.application.edit.meta.tab' | translate }}
                </button>
            </li>
            <li *ngIf="false" class="tabs-tab">
                <button
                    id="error-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#error"
                    role="tab"
                    aria-controls="error"
                    aria-selected="false">
                    <mat-icon data-name="error-tab" class="example-tab-icon">error</mat-icon>
                    {{ 'v2.application.edit.error.tab' | translate }}
                </button>
            </li>
        </ul>
    </div>
    <div id="users-tab-content" class="p-2">
        <div id="general" class="hidden" role="tabpanel" aria-labelledby="developers-tab">
            <codex-application-edit-general
                [application]="observables.application"
                (deleteApplication)="onDeleteApplication()"
                (updateApplication)="onUpdateApplication()"
                (validateDomain)="onValidateDomain()"
                (cancelUpdateApplication)="onCancel()"></codex-application-edit-general>
        </div>
        <div id="meta" class="hidden" role="tabpanel" aria-labelledby="developers-tab">
            <codex-application-edit-meta
                class="scrollbar scrollbar-primary"
                [application]="observables.application"
                (updateApplication)="onUpdateApplication()"
                (cancelUpdateApplication)="onCancel()"></codex-application-edit-meta>
        </div>
        <div id="error" class="hidden" role="tabpanel" aria-labelledby="developers-tab">
            <codex-application-edit-error
                class="scrollbar scrollbar-primary"
                [application]="observables.application"
                (updateApplication)="onUpdateApplication()"
                (cancelUpdateApplication)="onCancel()"></codex-application-edit-error>
        </div>
    </div>
</mat-dialog-content>
