import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateApplicationDto } from '../../../app/v2-application/dto/create-application.dto';
import { ApplicationCreatedDto } from '../../../app/v2-application/dto/application-created.dto';
import { backofficeEnvironment } from '@shared/environment';
import { Application } from '../../../app/v2-application/model/application';
import { ApplicationDto } from '../../../app/v2-application/dto/application.dto';
import { Page } from '@shared/data-access';
import { LoggerService } from '@backoffice/utils';
import { map } from 'rxjs/operators';

@Injectable()
export class EntriService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    public requestToken(): Observable<{ auth_token: string }> {
        return this.httpClient.get<{ auth_token: string }>(`${backofficeEnvironment.rest.v2.entri}oauth/request-jwt`);
    }
}
