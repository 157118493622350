import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { ApplicationDto } from '../../../v2-application/dto/application.dto';
import { initFlowbite } from 'flowbite';
import { EditorFacade } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-top-right-navigation',
    templateUrl: './top-right-navigation.component.html',
    styleUrls: ['./top-right-navigation.component.scss'],
    standalone: false,
})
export class TopRightNavigationComponent implements AfterViewInit {
    protected readonly environment = backofficeEnvironment;

    @Input()
    application: ApplicationDto;

    @Input()
    showVersionNav: boolean;

    @Output()
    openDialog: EventEmitter<'logs' | 'audit-logs' | 'generative'> = new EventEmitter<'logs' | 'audit-logs' | 'generative'>();

    @Output()
    openApplicationVersions: EventEmitter<void> = new EventEmitter<void>();

    constructor(public editorFacade: EditorFacade) {}

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    openGenerative() {
        const generativeChat = document.getElementById('generative-chat');
        const rightPanels = document.getElementsByClassName('right-panel');
        if (generativeChat) {
            // Move right-panels out
            Array.from(rightPanels).forEach(panel => {
                panel.classList.remove('show');
            });
            generativeChat.classList.add('show');
        }
    }

    openApplicationOverview() {
        this.editorFacade.registerTab({
            typeId: '1',
            type: 'application-overview',
            icon: 'home',
        });
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
}
