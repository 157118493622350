import { PartDetail } from '../../../part-detail.model';

export class ImagePartDetail extends PartDetail {
    code: string;
    url: string;
    svg: string;
    processedSvg: string;
    linkType: string;
    mediaId: string;
    title: string;
    automaticResizing: boolean;

    isValid(): boolean {
        return this.isCodeValid();
    }

    getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.image.error.code');
        }
        return validationErrors;
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }
}
