<h4 class="!mb-2">Type: {{ item.types }}</h4>
<div *ngIf="this.item.attributes && this.item.types.indexOf('object') > -1" class="property__container">
    <div class="property__configuration">
        <div class="property__properties">
            <div class="flex gap-1 justify-end mb-2">
                <h4>Properties</h4>
                <button class="primary-button button-medium" type="button" (click)="onAddPropertyClicked()">
                    <mat-icon>add_circle</mat-icon>
                    {{ 'v2.data-format.edit.add.property' | translate }}
                </button>
            </div>
            <div
                [attr.id]="'accordion-array-attributes-' + this.attribute.id"
                data-accordion="collapse"
                data-active-classes="none"
                data-inactive-classes="none"
                *ngIf="this.item.attributes"
                cdkDropList
                [cdkDropListData]="this.item.attributes"
                (cdkDropListDropped)="reorderAttributes($event)">
                <ng-container *ngFor="let attribute of this.item.attributes; let i = index; trackBy: identifyAttribute">
                    <h3
                        cdkDrag
                        [attr.id]="'accordion-open-heading-' + attribute.id"
                        [attr.data-name]="'header-' + attribute.id"
                        class="p-0">
                        <button
                            type="button"
                            class="accordion-heading"
                            [class.accordion-heading-first]="i === 0"
                            [class.accordion-heading-last]="i === this.item.attributes.length - 1"
                            [attr.data-accordion-target]="'#accordion-open-body-' + attribute.id"
                            [attr.aria-controls]="'accordion-open-body-' + attribute.id"
                            aria-expanded="true">
                            <span class="accordion-heading-icon"> {{ attribute.name }} ({{ attribute.noCodeXType }})</span>
                            <button cdkDragHandle class="accordion-heading-button" [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                                <mat-icon>import_export</mat-icon>
                            </button>
                            <div *cdkDragPreview class="border bg-primarylight border-primary w-[100%] !h-9 rounded"></div>
                            <button
                                type="button"
                                class="accordion-heading-button destructive-accordion-heading-button"
                                (click)="onDeleteAttribute(attribute)"
                                [matTooltip]="'Remove attribute'">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <svg
                                data-accordion-icon
                                class="w-3 h-3 rotate-180 shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6">
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M9 5 5 1 1 5" />
                            </svg>
                        </button>
                    </h3>
                    <codex-json-schema-property
                        [index]="i"
                        [attributeAmount]="this.item?.attributes?.length"
                        [attribute]="attribute"
                        (propertyChanged)="change.emit()">
                    </codex-json-schema-property>
                </ng-container>
            </div>
        </div>
    </div>
</div>
