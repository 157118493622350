import { Component, Input } from '@angular/core';
import { GenerativeTaskDto } from '../../../../../../../data-access/editor/src/lib/generative-tasks/interfaces/generative-task.dto';
import { GenerativeTaskService } from '../../../../../../../data-access/editor/src/lib/services/generative-task.service';
import { ActionEditorFacade, EditorFacade } from '@backoffice/data-access/editor';
import { switchMap } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-generative-ai-message-text',
    templateUrl: './generative-ai-message-text.component.html',
    styleUrls: ['./generative-ai-message-text.component.scss'],
    standalone: false,
})
export class GenerativeAiMessageTextComponent {
    @Input()
    message: GenerativeTaskDto;

    showQualityOverlay: boolean = false;
    selectedQuality: number = 5; // Default value

    onGenerateTests(message: any) {}

    onSkipTests(message: any) {}

    onOpenChangeQuality(message: any) {
        this.showQualityOverlay = true;
    }

    onConfirmQuality(message: any) {
        this.onChangeQuality(message);
        this.showQualityOverlay = false;
    }

    onCloseQualityOverlay() {
        this.showQualityOverlay = false;
    }

    constructor(
        private generativeTaskService: GenerativeTaskService,
        private editorFacade: EditorFacade,
        private actionFacade: ActionEditorFacade
    ) {}

    onExecuteActionChange(message: GenerativeTaskDto) {
        this.generativeTaskService
            .executeChange(message, message.id, message.applicationId, message.companyId)
            .pipe(
                switchMap(() => {
                    const settings = document.getElementById('invocation-settings');
                    if (settings) {
                        settings.classList.remove('show');
                    }
                    this.actionFacade.clearFromCache(message.reference);
                    return this.actionFacade.findById(message.reference);
                }),
                take(1)
            )
            .subscribe(() => {
                console.log('Change done');
            });
    }

    onExecuteExplanation(message: GenerativeTaskDto) {}

    onChangeQuality(message: GenerativeTaskDto) {
        message.quality = this.selectedQuality;
        this.generativeTaskService
            .changeQuality(message, message.id, message.applicationId, message.companyId)
            .pipe(
                tap(() => {
                    this.onCloseQualityOverlay();
                }),
                take(1)
            )
            .subscribe(() => {
                console.log('Undo done');
            });
    }

    onExecuteChangeReference(message: GenerativeTaskDto) {
        this.generativeTaskService
            .executeChangeReference(message, message.id, message.applicationId, message.companyId)
            .pipe(take(1))
            .subscribe(() => {
                console.log('Undo done');
            });
    }

    onExecuteActionUndo(message: GenerativeTaskDto) {
        this.generativeTaskService
            .executeUndo(message, message.id, message.applicationId, message.companyId)
            .pipe(
                switchMap(() => {
                    const settings = document.getElementById('invocation-settings');
                    if (settings) {
                        settings.classList.remove('show');
                    }
                    this.actionFacade.clearFromCache(message.reference);
                    return this.actionFacade.findById(message.reference);
                }),
                take(1)
            )
            .subscribe(() => {
                console.log('Undo done');
            });
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
}
