<form *ngIf="company && form" [formGroup]="form" (ngSubmit)="onUpdateCompany()" novalidate>
    <label for="company-name" class="small-input-label">{{ 'v2.company.edit.name.title' | translate }}</label>
    <input
        class="small-input"
        type="text"
        id="company-name"
        name="company-name"
        data-name="company-name"
        formControlName="name"
        (change)="onChangeCompanyName()"
        required
        autocomplete="off" />
    <p
        class="small-input-error"
        *ngIf="form.controls['name'].touched && form.controls['name'].invalid && form.controls['name'].hasError('required')">
        {{ 'v2.company.edit.name.required' | translate }}
    </p>
    <hr class="seperator" />
    <label for="company-slug" class="small-input-label">{{ 'v2.company.edit.slug.title' | translate }}</label>
    <input
        class="small-input"
        type="text"
        id="company-slug"
        name="company-slug"
        data-name="company-slug"
        formControlName="slug"
        (change)="onChangeCompanySlug()"
        required
        autocomplete="off" />

    <p class="small-input-error" *ngIf="this.form.get('slug').invalid && this.form.get('slug').errors.required">
        {{ 'v2.application.edit.endpoint.error' | translate }}
    </p>
    <p class="small-input-error" *ngIf="this.form.get('slug').invalid && this.form.get('slug').errors.pattern">
        Please only use alphanumerical characters & '-', '_'.
    </p>
    <ul class="url-list">
        <li>
            https://{{ '\{\{APPLICATION-SUBDOMAIN\}\}' }}-{{ this.form.get('slug').value }}.no-code-x.com
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="'https://' + '\{\{APPLICATION-SUBDOMAIN\}\}-' + this.form.get('slug').value + '.no-code-x.com'"
                [matTooltip]="'v2.api.edit.endpoint.copy' | translate"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
    </ul>

    <button *ngIf="false" class="destructive-button button-medium" data-name="delete" type="button" (click)="onDeleteWorkspace()">
        <mat-icon>delete</mat-icon>
        {{ 'v2.company.edit.delete' | translate }}
    </button>
    <div class="flex justify-end gap-1">
        <button class="primary-button button-medium" data-name="save">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-medium" data-name="cancel" [mat-dialog-close]="true">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
