import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProgressService } from './common/components/progress';
import { AppFacade } from '../modules/core/facades/app.facade';
import { FlowbiteNow } from '@backoffice/utils';

@Component({
    selector: 'app-root',
    template: `<codex-progress *ngIf="(loadingBusys$ | async) === true"></codex-progress>
        <router-outlet *ngIf="!isMobile"></router-outlet>
        <div *ngIf="isMobile" class="mobile-overlay">
            <img class="w-48 mb-4" src="/src/images/theme/nocode-x-full-logo.svg" />
            <p class="text-s mb-2">Hey there! Just a quick heads-up.</p>
            <p class="text-s mb-4">
                NoCode-X is packed with powerful features, and to give you the best experience, we recommend using it on a
                <strong>larger screen</strong>, like a <strong>desktop or laptop</strong>. A <strong>bigger screen</strong> makes it much
                easier to navigate and take full advantage of everything NoCode-X has to offer.
            </p>
            <p class="text-s">Give it a try on a computer, and you'll see the difference!</p>
            <button (click)="isMobile = false" type="button" class="mt-4 primary-button button-large" autofocus>Continue anyway</button>
        </div>`,
    standalone: false,
})
export class AppComponent implements AfterViewChecked, OnInit {
    loadingBusys$: Observable<boolean> = this.progressService.isLoading;
    isMobile: boolean;

    constructor(
        private readonly progressService: ProgressService,
        private readonly appFacade: AppFacade,
        private cdRef: ChangeDetectorRef
    ) {}

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this.isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
        this.appFacade.loadUser();
        // This works, as soon as we have no need for calls during scope loading we will be able to make it run using a webworker.
        //const worker = new Worker(new URL('./workers/scope.worker', import.meta.url));
        //worker.postMessage(this.appFacade)
    }
}
