<ng-container
    *ngIf="{
        billingPackage: billingPackage$ | async,
    } as observables">
    <div class="generative-container">
        <div class="generative-messages">
            <div *ngIf="showHeader" class="generative-header">
                <div class="link" (click)="onCloseGenerative()">
                    <svg aria-hidden="true" focusable="false" class="icon ng-star-inserted">
                        <use href="#collapse_off" class="ui-element"></use>
                    </svg>
                </div>
                <div class="generative-credits">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#generative-credits" class="ui-element"></use>
                    </svg>
                    <span>Credits left: {{ observables.billingPackage?.amountOfAiCreditsLeft }}</span>
                </div>
            </div>
            <div class="generative-chat-messages" *ngIf="false">
                <ng-container *ngFor="let currentConversationMessage of currentConversation | async; trackBy: identifyConversation">
                    <codex-generative-sender-message-text
                        *ngIf="currentConversationMessage.prompt"
                        [message]="currentConversationMessage"></codex-generative-sender-message-text>
                    <codex-generative-ai-message-text [message]="currentConversationMessage"></codex-generative-ai-message-text>
                </ng-container>
            </div>
            <div class="generative-actions shrink-0" *ngIf="false">
                <div
                    *ngIf="actions.indexOf('generate-name') > -1"
                    class="generative-action"
                    (click)="executeGenerativeTask('GENERATE_ACTION_NAME', activeActionId, 'ACTION')">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#name" class="ui-element"></use>
                    </svg>
                    <span>Generate name</span>
                </div>
                <div
                    *ngIf="actions.indexOf('generate-description') > -1"
                    class="generative-action"
                    (click)="executeGenerativeTask('GENERATE_ACTION_DESCRIPTION', activeActionId, 'ACTION')">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#description" class="ui-element"></use>
                    </svg>
                    <span>Generate description</span>
                </div>
                <div
                    *ngIf="actions.indexOf('explain-action') > -1"
                    class="generative-action"
                    (click)="executeGenerativeTask('EXPLAIN_ACTION', activeActionId, 'ACTION')">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#explanation" class="ui-element"></use>
                    </svg>
                    <span>Explain action</span>
                </div>
            </div>

            <div class="mb-2 input-button-container" *ngIf="false">
                <textarea
                    class="generative-chat-input small-input shrink-0 scrollbar scrollbar-primary"
                    type="text"
                    tabindex="-1"
                    id="sender-chat"
                    name="sender-chat"
                    autocomplete="off"
                    [(ngModel)]="prompt"
                    [rows]="rows"
                    [style.height]="textareaHeight"
                    (keydown.enter)="executeGenerativeTask('OPEN', activeActionId, 'ACTION')"></textarea>
                <svg
                    class="icon input-button !h-5 !w-5 !right-2 z-[10000]"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="actionNamePopover"
                    appendTo="body"
                    [preventOverflow]="false"
                    [boundariesElement]="'rootBoundary'"
                    [positionFixed]="true"
                    [placement]="NgxFloatUiPlacements.RIGHT"
                    [appendTo]="'body'"
                    (click)="changeRows()">
                    <use href="#expand" class="ui-element"></use>
                </svg>
                <float-ui-content #actionNamePopover>
                    <div class="popover navigation-info">
                        <div class="draggable-card-popover-title-without-text">
                            <h3 class="draggable-card-popover-title-element">Make this field bigger/smaller</h3>
                        </div>
                    </div>
                </float-ui-content>
            </div>

            <deep-chat
                style="margin-top: 2px; background-color: transparent; border: none; height: 100%; width: 100%"
                speechToText='{
                        "webSpeech": true,
                        "translations": {"hello": "goodbye", "Hello": "Goodbye"},
                        "commands": {"resume": "resume", "settings": {"commandMode": "hello"}},
                        "button": {
                            "position": "outside-right",
                            "default": {
                                "svg": {
                                    "content": "<?xml version=\"1.0\" encoding=\"utf-8\"?><svg id=\"microphone\" width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M15.8334 8.33341V10.0001C15.8334 13.2217 13.2217 15.8334 10.0001 15.8334M4.16675 8.33341V10.0001C4.16675 13.2217 6.77842 15.8334 10.0001 15.8334M10.0001 15.8334V18.3334M6.66675 18.3334H13.3334M10.0001 12.5001C8.61937 12.5001 7.50008 11.3808 7.50008 10.0001V4.16675C7.50008 2.78604 8.61937 1.66675 10.0001 1.66675C11.3808 1.66675 12.5001 2.78604 12.5001 4.16675V10.0001C12.5001 11.3808 11.3808 12.5001 10.0001 12.5001Z\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
                                    "styles": {"default" : {
                                         "stroke": "#94a3b8",
                                         "filter": "none"
                                    }}
                                }
                            }
                        }
                      }'
                inputAreaStyle='{"justify-content": "start"}'
                textInput='{
                        "placeholder": {
                            "text": "Insert prompt here...",
                            "style": {
                                "color": "#94a3b8"
                            }
                        },
                        "styles": {
                            "text": {"color": "#94a3b8"},
                            "container": {"box-shadow": "none", "width": "calc(100% - 40px)", "height": "120px", "backgroundColor": "#170206", "border": "1px solid #475569"}
                        }
                    }'
                auxiliaryStyle="
                        ::-webkit-scrollbar {
                          width: 0.5rem;
                          height: 10px;
                          background-color: #020617;
                        }
                        ::-webkit-scrollbar-thumb {
                          background-color: #9C38FF;
                          border-radius: 12px;
                        }
                        ::-webkit-scrollbar-track {
                          background-color: #020617;
                          border-radius: 12px;
                        }
                "
                messageStyles='{
                        "default": {
                          "ai": {"bubble": {"backgroundColor": "#DD7EFF15", "border": "1px solid #334155"}},
                          "system": {"bubble": {"backgroundColor": "#DD7EFF15", "border": "1px solid #334155"}},
                          "user": {"bubble": {"backgroundColor": "lightblue"}}
                        }
                      }'
                connect='{"stream": {"simulation": 6}}'
                demo="true"
                mixedFiles='{
                        "button": {
                            "position": "inside-left",
                            "default": {
                                "svg": {
                                    "content": "<?xml version=\"1.0\" encoding=\"utf-8\"?><svg id=\"microphone\" width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M15.8334 8.33341V10.0001C15.8334 13.2217 13.2217 15.8334 10.0001 15.8334M4.16675 8.33341V10.0001C4.16675 13.2217 6.77842 15.8334 10.0001 15.8334M10.0001 15.8334V18.3334M6.66675 18.3334H13.3334M10.0001 12.5001C8.61937 12.5001 7.50008 11.3808 7.50008 10.0001V4.16675C7.50008 2.78604 8.61937 1.66675 10.0001 1.66675C11.3808 1.66675 12.5001 2.78604 12.5001 4.16675V10.0001C12.5001 11.3808 11.3808 12.5001 10.0001 12.5001Z\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
                                    "styles": {"default" : {
                                         "stroke": "#94a3b8",
                                         "filter": "none"
                                    }}
                                }
                            }
                        }
                      }'
                id="nocode-x-assistant"
                [history]="history"></deep-chat>
        </div>
        <div class="generative-assistant-no-ai-credits-overlay" *ngIf="observables.billingPackage?.amountOfAiCreditsLeft <= 0">
            You're out of AI credits! To continue using this feature, please top up your credits.
            <button class="primary-button button-medium" (click)="openTopUp()" type="button">
                <mat-icon>add_circle</mat-icon>
                Buy AI Credits
            </button>
        </div>
    </div>
</ng-container>
