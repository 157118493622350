export interface TabDefinition {
    name?: string | undefined;
    typeId: string;
    type:
        | 'action'
        | 'api'
        | 'data'
        | 'data-format'
        | 'media'
        | 'template'
        | 'job'
        | 'designsystem'
        | 'rights'
        | 'group'
        | 'application-overview';
    icon?: string | undefined;
    arguments?: { key: string; value: string }[];
}
