import { CompanyIdentityProvider } from '@shared/interfaces/company-identity-provider.dto';

export class CompanyDto {
    id: string;
    companyName?: string;
    companySlug?: string;
    createdBy?: string;
    creationDate?: string;
    modifiedBy?: string;
    modificationDate?: string;

    loginApplicationId?: string;
    loginTemplateId?: string;
    loginRedirectTemplateId?: string;
    loginOtpTemplateId?: string;
    loginConfigOtpTemplateId?: string;
    loginResetPasswordTemplateId?: string;
    loginVerifyEmailTemplateId?: string;
    loginPageExpiredTemplateId?: string;
    loginUpdatePasswordTemplateId?: string;

    identityProviders?: CompanyIdentityProvider[];
    idpCode: string;

    constructor(base?: Partial<CompanyDto>) {
        if (base) {
            this.id = base.id;
            this.companyName = base.companyName;
            this.companySlug = base.companySlug;
            this.createdBy = base.createdBy;
            this.creationDate = base.creationDate;
            this.modifiedBy = base.modifiedBy;
            this.modificationDate = base.modificationDate;
            this.loginApplicationId = base.loginApplicationId;
            this.loginTemplateId = base.loginTemplateId;
            this.loginRedirectTemplateId = base.loginRedirectTemplateId;
            this.idpCode = base.idpCode;
        }
    }
}
