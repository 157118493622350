import 'reflect-metadata';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CompanySandbox } from '../facade/company.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog as MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { AppFacade } from '@core/facades/app.facade';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { CompanyIdentityProvider } from '@shared/interfaces/company-identity-provider.dto';
import { initFlowbite } from 'flowbite';
import { CompanySecurityDto } from '@shared/interfaces/company-security.dto';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ConfirmDialog } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';
import { Page } from '@shared/data-access';
import { UserDto } from '../dto/deprecated/user.dto';
import { UserFacade } from '@core/facades/user.facade';
import { RouterFacade } from '@backoffice/utils-routing';
import { BillingFacade } from '@billing/facade/billing.facade';

@Component({
    selector: 'my-company-edit',
    styleUrls: ['my-company-edit.component.scss'],
    templateUrl: 'my-company-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class CompanyEditComponent implements OnInit, AfterViewInit {
    company$: BehaviorSubject<CompanyDto> = new BehaviorSubject(null);
    developers$: BehaviorSubject<Page<UserDto>> = new BehaviorSubject(null);
    billing$ = this.billingFacade.package;
    companySecurity$: BehaviorSubject<CompanySecurityDto> = new BehaviorSubject(null);

    apiSecretViewed$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    companyIdentityProviders$: BehaviorSubject<CompanyIdentityProvider[]> = new BehaviorSubject<CompanyIdentityProvider[]>(null);

    subscriptions: Subscription = new Subscription();

    companyId: string;

    constructor(
        public companySandbox: CompanySandbox,
        private readonly userFacade: UserFacade,
        private readonly routerFacade: RouterFacade,
        public snackBar: MatSnackBar,
        public translate: TranslateService,
        public dialogRef: MatDialogRef<CompanyEditComponent>,
        public dialog: MatDialog,
        private readonly appFacade: AppFacade,
        public confirmDialog: ConfirmDialog,
        private readonly billingFacade: BillingFacade,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.companyId = this.data.companyId;
    }

    ngOnInit(): void {
        this.loadCompany();
        this.fetchDevelopers();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            initFlowbite();
        });
    }

    fetchDevelopers() {
        this.subscriptions.add(
            this.userFacade.findDevelopers('email', 0, 200).subscribe(developers => {
                this.developers$.next(developers);
            })
        );
    }

    onUserInvited(mail: string) {
        this.userFacade
            .inviteUser(mail, this.companyId)
            .pipe(
                catchError(() => {
                    return of(null);
                })
            )
            .subscribe(() => {
                this.fetchDevelopers();
                this.snackBar.open(this.translate.instant('v2.usermanagement.users.invitation.sent'), null, {
                    panelClass: ['success'],
                });
            });
    }

    loadCompany() {
        this.subscriptions.add(
            this.companySandbox.getCompanyById(this.companyId).subscribe(company => {
                this.company$.next(company);
                this.appFacade
                    .getCompanySecurity(company.id)
                    .pipe(take(1))
                    .subscribe(companySecurity => {
                        this.companySecurity$.next(companySecurity);
                    });
                this.companySandbox.getIdentityProviders(company.id).subscribe(identityProviders => {
                    this.companyIdentityProviders$.next(identityProviders);
                });
            })
        );
    }

    onDeleteWorkspace($event: { company: CompanyDto; companySecurity?: CompanySecurityDto }): void {
        if ($event) {
            const { company } = $event;
            this.confirmDialog.showConfirmDialog(
                'v2.application.edit.delete.title',
                'v2.application.edit.delete.description',
                'v2.application.edit.delete.ok',
                'v2.application.edit.delete.cancel',
                'v2.application.edit.delete.success.title',
                'v2.application.edit.delete.success.description',
                () => {
                    this.companySandbox.getCompanyById(this.companyId);
                    this.dialogRef.close();
                }
            );
        }
    }

    onUpdateCompany($event: { company: CompanyDto; companySecurity?: CompanySecurityDto }): void {
        if ($event) {
            const { company, companySecurity } = $event;
            if (company) {
                this.appFacade.editCompany(company);
            }
            if (companySecurity) {
                this.appFacade.editCompanySecurity(companySecurity).pipe(take(1)).subscribe();
            }
        }
    }

    onViewSecret($event: { companySecurity?: CompanySecurityDto; environment: string }): void {
        if ($event) {
            const { companySecurity, environment } = $event;
            if (companySecurity) {
                this.appFacade
                    .editCompanySecurity(companySecurity)
                    .pipe(
                        take(1),
                        switchMap(() => this.appFacade.getCompanyApiSecret(companySecurity.companyId, environment))
                    )
                    .subscribe((secret: { secret: string }) => {
                        this.apiSecretViewed$.next(secret.secret);
                    });
            }
        }
    }

    onCreateCompanyIdentityProvider($event: { companyIdentityProvider: CompanyIdentityProvider }): void {
        if ($event.companyIdentityProvider) {
            this.subscriptions.add(
                this.companySandbox.createIdentityProvider($event.companyIdentityProvider).subscribe(() => {
                    this.loadCompany();
                    this.snackBar.open(this.translate.instant('my.company.create.identityprovider.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            );
        }
    }

    onUpdateCompanyIdentityProvider($event: { companyIdentityProvider: CompanyIdentityProvider }): void {
        if ($event.companyIdentityProvider) {
            this.subscriptions.add(
                this.companySandbox.updateIdentityProvider($event.companyIdentityProvider).subscribe(() => {
                    this.loadCompany();
                    this.snackBar.open(this.translate.instant('my.company.update.identityprovider.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            );
        }
    }

    onDeleteCompanyIdentityProvider($event: { companyIdentityProvider: CompanyIdentityProvider }): void {
        if ($event.companyIdentityProvider) {
            this.subscriptions.add(
                this.companySandbox.deleteIdentityProvider($event.companyIdentityProvider).subscribe(() => {
                    this.loadCompany();
                    this.snackBar.open(this.translate.instant('my.company.delete.identityprovider.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            );
        }
    }
}
