import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { BillingPackageService } from '@add-company/service/billingpackage.service';
import { take } from 'rxjs/operators';

declare let gtag_report_conversion: any;
@Component({
    selector: 'codex-choose-package',
    templateUrl: './choose-package.component.html',
    styleUrls: ['./choose-package.component.scss'],
    standalone: false,
})
export class ChoosePackageComponent implements OnInit {
    companyId: string;

    showFree: boolean = true;

    couponCode: string;

    amountOfCores: number = 1;

    yearly: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ChoosePackageComponent>,
        public billingPackageService: BillingPackageService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.companyId = this.data.companyId;
        this.showFree = this.data.showFree;
    }
    chooseFree() {
        this.dialogRef.close();
        gtag_report_conversion();
    }

    initiateBillingPackage(packageName: string) {
        this.billingPackageService
            .initiateUpdateBillingPackage(this.companyId, packageName, this.amountOfCores, this.couponCode, this.yearly)
            .pipe(take(1))
            .subscribe(result => {
                window.location.href = result.redirectUrl;
            });
        gtag_report_conversion();
    }
}
