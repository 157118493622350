<div
    class="flex gap-2 justify-center items-center"
    *ngIf="{ basedOnPartPositioning: basedOnPartPositioning$ | async, viewTypeSelect: _viewType | async } as observables">
    <ng-container *ngIf="observables.basedOnPartPositioning">
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'SMALL'"
            (click)="$event.stopPropagation()"
            data-name="screenSize"
            data-dropdown-toggle="screenSizeDropDown"
            data-dropdown-trigger="click"
            data-dropdown-placement="right-start"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#mobile-screens" class="stroke-white"></use>
        </svg>
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'LARGE'"
            (click)="$event.stopPropagation()"
            data-name="screenSize"
            data-dropdown-toggle="screenSizeDropDown"
            data-dropdown-trigger="click"
            data-dropdown-placement="right-start"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#tablet-screens" class="stroke-white"></use>
        </svg>
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'EXTRA_EXTRA_LARGE'"
            (click)="$event.stopPropagation()"
            data-name="screenSize"
            data-dropdown-toggle="screenSizeDropDown"
            data-dropdown-trigger="click"
            data-dropdown-placement="right-start"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#large-screens" class="stroke-white"></use>
        </svg>
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'ALL_SCREENS'"
            (click)="$event.stopPropagation()"
            data-name="screenSize"
            data-dropdown-toggle="screenSizeDropDown"
            data-dropdown-trigger="click"
            data-dropdown-placement="right-start"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#all-screens" class="stroke-white"></use>
        </svg>

        <span class="leading-7 grow text-left">{{ 'v2.part.style.size.and.position' | translate }}</span>
        <button
            *ngIf="observables.basedOnPartPositioning.isCurrentScreenType && this.part.positions && this.part.positions.length > 1"
            class="text-button"
            type="button"
            (click)="onResetStyle(); $event.stopPropagation()"
            [matTooltip]="'v2.template.style.reset' | translate">
            Reset
        </button>
    </ng-container>
    <div
        id="screenSizeDropDown"
        class="dropdown-items hidden dark:divide-gray-600"
        aria-labelledby="dropdownDelayButton"
        *ngIf="
            (getPartPositioningByNumber(part, 0) && observables.basedOnPartPositioning.type !== 'SMALL') ||
            (getPartPositioningByNumber(part, 2) && observables.basedOnPartPositioning.type !== 'LARGE') ||
            (getPartPositioningByNumber(part, 4) && observables.basedOnPartPositioning.type !== 'EXTRA_EXTRA_LARGE') ||
            (getPartPositioningByNumber(part, 5) && observables.basedOnPartPositioning.type !== 'ALL_SCREENS')
        "
        data-popper-placement="right-start">
        <div class="p-4 text-sm text-left">
            {{ 'v2.part.style.size.and.position.screentype.description' | translate }}
        </div>
        <ul class="dropdown-items-wrapper">
            <li *ngIf="getPartPositioningByNumber(part, 0) && observables.basedOnPartPositioning.type !== 'SMALL'">
                <div class="dropdown-item" (click)="setSelectedViewType(0); $event.stopPropagation()">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#mobile-screens" class="stroke-white"></use>
                    </svg>
                    <span>Edit mobile screen style</span>
                </div>
            </li>
            <li *ngIf="getPartPositioningByNumber(part, 2) && observables.basedOnPartPositioning.type !== 'LARGE'">
                <div class="dropdown-item" (click)="setSelectedViewType(2); $event.stopPropagation()">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#tablet-screens" class="stroke-white"></use>
                    </svg>
                    <span>Edit tablet screen style</span>
                </div>
            </li>
            <li *ngIf="getPartPositioningByNumber(part, 4) && observables.basedOnPartPositioning.type !== 'EXTRA_EXTRA_LARGE'">
                <div class="dropdown-item" (click)="setSelectedViewType(4); $event.stopPropagation()">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#large-screens" class="stroke-white"></use>
                    </svg>
                    <span>Edit large screen style</span>
                </div>
            </li>
            <li *ngIf="getPartPositioningByNumber(part, 5) && observables.basedOnPartPositioning.type !== 'ALL_SCREENS'">
                <div class="dropdown-item" (click)="setSelectedViewType(5); $event.stopPropagation()">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#all-screens" class="stroke-white"></use>
                    </svg>
                    <span>Edit all screen style</span>
                </div>
            </li>
        </ul>
    </div>
</div>
