<div class="overview-item" (click)="onClicked()">
    <div class="icon">
        <input (click)="$event.stopPropagation(); onCheckboxSelected()" id="default-checkbox" type="checkbox" value="" class="checkbox" />
        <i class="material-icons">
            {{ designsystem.iconName ? designsystem.iconName : 'brush' }}
        </i>
    </div>
    <div class="name-data">
        <div class="name">
            <span>{{ designsystem.name }}</span>
        </div>
        <div class="product-reference" *ngIf="designsystem.productReference">
            <mat-icon class="text-lg leading-6 !h-5 !w-5">electrical_services</mat-icon>
            <div class="leading-6">{{ designsystem.productReferenceName }}</div>
        </div>
    </div>
    <div class="audit-data">
        <div>
            {{ 'general.search.result.created.by' | translate }}
            {{ designsystem.createdBy }}
            {{ 'general.search.result.on' | translate }} {{ designsystem.creationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
        <div>
            {{ 'general.search.result.updated.by' | translate }} {{ designsystem.modifiedBy }} {{ 'general.search.result.on' | translate }}
            {{ designsystem.modificationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
    </div>
</div>
