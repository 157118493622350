import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserFacade } from '@core/facades/user.facade';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'codex-developer-overview',
    templateUrl: './create-user.component.html',
    standalone: false,
})
export class CreateUserComponent {
    formGroup = this.fb.group({
        email: ['', [Validators.required]],
        password: ['', [createPasswordStrengthValidator()]],
        confirmPassword: ['', [createPasswordStrengthValidator()]],
        temporaryPassword: [true, [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        emailVerified: [false, [Validators.required]],
        forceOtp: [false, [Validators.required]],
        passwordLess: [false, [Validators.required]],
        forceWebAuthn: [false, [Validators.required]],
        triggerEmailValidationImmediatly: [false, [Validators.required]],
        environment: ['', [Validators.required]],
    });

    showConfirmPasswordError: boolean;

    showingUserPassword: boolean = false;
    showingConfirmPassword: boolean = false;

    constructor(
        private readonly fb: FormBuilder,
        private readonly snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<CreateUserComponent>,
        private userFacade: UserFacade,
        private translateService: TranslateService
    ) {}

    onCreateUser() {
        this.showConfirmPasswordError = false;
        if (this.formGroup.valid) {
            if (this.formGroup.controls.password.value === this.formGroup.controls.confirmPassword.value) {
                this.userFacade
                    .createApplicationUser(this.formGroup.getRawValue())
                    .pipe(
                        take(1),
                        catchError(() => {
                            return of('USER_EXISTS');
                        })
                    )
                    .subscribe(result => {
                        if (!result) {
                            this.dialogRef.close();
                            this.snackBar.open('Successfully created user', undefined, {
                                panelClass: ['success'],
                            });
                        } else if (result === 'USER_EXISTS') {
                            this.snackBar.open(
                                'User with email ' +
                                    this.formGroup.controls.email.value +
                                    ' already exists on ' +
                                    this.translateService.instant(
                                        'v2.usermanagement.users.environment.' + this.formGroup.controls.environment.value?.toLowerCase()
                                    ),
                                undefined,
                                {
                                    panelClass: ['error'],
                                }
                            );
                        }
                    });
            } else {
                //SHOW ERROR MESSAGE
                this.showConfirmPasswordError = true;
            }
        } else {
            this.formGroup.markAllAsTouched();
        }
    }

    togglePasswordType(id: string) {
        const field = document.getElementById(id);
        if (field) {
            if ((field as HTMLInputElement).type === 'password') {
                (field as HTMLInputElement).type = 'text';
            } else {
                (field as HTMLInputElement).type = 'password';
            }
        }
    }

    onCancel() {
        this.dialogRef.close();
    }
}

export function createPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }
        const hasUpperCase = /[A-Z]+/.test(value);
        const hasLowerCase = /[a-z]+/.test(value);
        const hasNumeric = /[0-9]+/.test(value);
        const moreThanEightChars = value.length >= 8;

        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && moreThanEightChars;

        return !passwordValid ? { passwordStrength: true } : null;
    };
}
