import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var Swal: any;
@Injectable()
export class ConfirmDialog {
    constructor(private translateService: TranslateService) {}

    public showConfirmDialog(
        title: string,
        description: string,
        confirm: string,
        cancel: string,
        successTitle: string,
        successDescription: string,
        callback: Function,
        translationParams: any = {}
    ) {
        this.translateService
            .get([title, description, confirm, cancel, successTitle, successDescription], translationParams)
            .subscribe((translation: string) => {
                Swal.fire({
                    title: translation[title],
                    text: translation[description],
                    icon: 'warning',
                    color: '#cbd5e1',
                    showCancelButton: true,
                    confirmButtonColor: '#e02424',
                    cancelButtonColor: '#413355',
                    confirmButtonText: translation[confirm],
                    confirmButtonClass: 'confirm',
                    cancelButtonText: translation[cancel],
                    cancelButtonClass: 'cancel',
                }).then(
                    result => {
                        if (result.isConfirmed) {
                            callback();
                            Swal.fire({
                                title: translation[successTitle],
                                text: translation[successDescription],
                                icon: 'success',
                                color: '#cbd5e1',
                                showCancelButton: false,
                                confirmButtonColor: '#7a00f3',
                            });
                        }
                    },
                    () => {}
                );
            });
    }

    public showConfirmDialogWithoutSuccess(title: string, description: string, confirm: string, cancel: string, callback: Function) {
        this.translateService.get([title, description, confirm, cancel]).subscribe((translation: string) => {
            Swal.fire({
                title: translation[title],
                text: translation[description],
                icon: 'warning',
                color: '#cbd5e1',
                showCancelButton: true,
                confirmButtonColor: '#e02424',
                cancelButtonColor: '#413355',
                confirmButtonText: translation[confirm],
                confirmButtonClass: 'confirm',
                cancelButtonText: translation[cancel],
                cancelButtonClass: 'cancel',
            }).then(
                result => {
                    if (result.isConfirmed) {
                        callback();
                    }
                },
                () => {}
            );
        });
    }

    public showConfirmPaymentDialog(
        title: string,
        description: string,
        confirm: string,
        cancel: string,
        callback: Function,
        companyId: string
    ) {
        this.translateService.get([title, description, confirm, cancel], { companyId: companyId }).subscribe((translation: string) => {
            Swal.fire({
                title: translation[title],
                html: translation[description],
                icon: 'warning',
                color: '#cbd5e1',
                showCancelButton: true,
                confirmButtonColor: '#e02424',
                cancelButtonColor: '#413355',
                confirmButtonText: translation[confirm],
                confirmButtonClass: 'confirm',
                cancelButtonText: translation[cancel],
                cancelButtonClass: 'cancel',
            }).then(
                result => {
                    if (result.isConfirmed) {
                        callback();
                    }
                },
                () => {}
            );
        });
    }
}
