import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { DesignSystem, DesignSystemEditorFacade, Typography, Variable } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-design-system-detail',
    templateUrl: './design-system-detail.component.html',
    styleUrls: ['./design-system-detail.component.scss'],
    standalone: false,
})
export class DesignSystemDetailComponent implements OnInit, OnDestroy {
    @Input() designsystemId: string;

    tabs = ['general', 'color', 'typography'];
    currentTab = 'general';

    $designSystem: Observable<DesignSystem>;

    private readonly subscriptions = new Subscription();

    constructor(private readonly designsystemFacade: DesignSystemEditorFacade) {}

    ngOnInit(): void {
        this.$designSystem = this.designsystemFacade.findById(this.designsystemId).pipe(distinctUntilChanged());
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    handleColorChanged(
        event: {
            value: string;
            variable: Variable;
        },
        theme: 'STANDARD' | 'DARK',
        ds: DesignSystem
    ): void {
        const { applicationId, companyId, id } = ds;
        const { value, variable } = event;
        this.designsystemFacade.updateColor({
            applicationId,
            companyId,
            designSystemId: id,
            variable,
            value,
            theme,
            type: 'RGB',
        });
    }

    handleTabClicked(tab: string): void {
        this.currentTab = tab;
    }

    handleTypographyChanged(
        event: Typography,
        designSystem: DesignSystem,
        type:
            | 'display_large'
            | 'display_medium'
            | 'display_small'
            | 'headline_large'
            | 'headline_medium'
            | 'headline_small'
            | 'title_large'
            | 'title_medium'
            | 'title_small'
            | 'label_large'
            | 'label_medium'
            | 'label_small'
            | 'body_large'
            | 'body_medium'
            | 'body_small'
    ): void {
        const { applicationId, companyId, id } = designSystem;
        const { fontWeight, fontFamily, fontSize, fontStyle, letterSpacing, color } = event;
        this.designsystemFacade.updateTypography({
            applicationId,
            companyId,
            designSystemId: id,
            color: color,
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontWeight: fontWeight,
            italic: fontStyle,
            letterSpacing: letterSpacing,
            unit: fontSize.unit,
            type,
        });
    }

    handleDesignSystemChanged(
        event: {
            name: string;
            active: boolean;
            iconName: string;
        },
        ds: DesignSystem
    ): void {
        const { applicationId, companyId, id } = ds;
        this.designsystemFacade.update({
            id,
            applicationId,
            companyId,
            ...event,
        });
    }
}
