import { NgModule } from '@angular/core';
import { ManageUsersPageComponent } from './components/manage-users-page/manage-users-page.component';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { BackofficeUiCompanyUsersModule } from '@backoffice/ui/company/users';
import { BackofficeUiEditorModule } from '@backoffice/ui/editor';

@NgModule({
    imports: [CommonsModule, BackofficeUiCompanyUsersModule, BackofficeUiEditorModule],
    declarations: [ManageUsersPageComponent],
    exports: [ManageUsersPageComponent],
})
export class BackofficeFeatureCompanyUsersModule {}
