import { NgModule } from '@angular/core';
import { CompanyEditComponent } from './page/my-company-edit.component';
import { CompanyEditGeneralInfoComponent } from './components/company-edit-general-info/company-edit-general-info.component';
import { CompanyEditAuthenticationComponent } from './components/company-edit-authentication/company-edit-authentication.component';
import { InviteUserComponent } from './components/invite-user/invite-user.component';
import { CompanyEditAuthenticationIdentityprovidersComponent } from './components/company-edit-authentication-identityproviders/company-edit-authentication-identityproviders.component';
import { AddIdentityproviderComponent } from './components/add-identityprovider/add-identityprovider.component';
import { OpenidconnectComponent } from './components/openidconnect/openidconnect.component';
import { TrustelemComponent } from './components/trustelem/trustelem.component';
import { AzureadComponent } from './components/azuread/azuread.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { BackofficeDataAccessEditorModule } from '@backoffice/data-access/editor';
import { BackofficeFeatureEditorPickersModule } from 'backoffice/feature/editor/pickers';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { V2SharedModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-shared/v2-shared.module';
import { HttpLoaderInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-authentication.interceptor';
import { BillingModule } from '@billing/billing.module';
import { CompanyEditApiSecurityComponent } from './components/company-edit-api-security/company-edit-api-security.component';
import { BackofficeUiCompanyUsersModule } from '@backoffice/ui/company/users';

@NgModule({
    declarations: [
        CompanyEditComponent,
        CompanyEditGeneralInfoComponent,
        CompanyEditAuthenticationComponent,
        InviteUserComponent,
        CompanyEditAuthenticationIdentityprovidersComponent,
        AddIdentityproviderComponent,
        OpenidconnectComponent,
        TrustelemComponent,
        AzureadComponent,
        CompanyEditApiSecurityComponent,
    ],
    imports: [
        RouterModule,
        CommonsModule,
        V2SharedModule,
        TranslateModule,
        BackofficeUiArgumentsModule,
        BackofficeDataAccessEditorModule,
        BackofficeFeatureEditorPickersModule,
        BillingModule,
        BackofficeUiCompanyUsersModule,
    ],
    providers: [HttpLoaderInterceptorProvider, HttpErrorInterceptorProvider, HttpAuthenticationInterceptorProvider],
})
export class BackofficeFeatureEditCompanyModule {}
