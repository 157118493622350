import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '@shared/data-access';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { OverviewActionDto } from '../../../../../../data-access/editor/src/lib/dto/overview/overview-action.dto';
import { Action } from '../../../../../../data-access/editor/src/lib/action/model/action';
import { CreateActionDto } from '../interface/create-action.dto';
import { ActionCreatedDto } from '../../../../../../data-access/editor/src/lib/action/dto/action-created.dto';
import { ActionCtx } from '../../../../../../data-access/editor/src/lib/action/model/action-ctx';
import { Method } from '../../../../../../data-access/editor/src/lib/action/model/method';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { CreateActionExecutionDto } from '../interface/create-action-execution.dto';
import { ActionExecutionCreatedDto } from '../interface/action-execution-created.dto';
import { ActionCtxList } from '../../../../../../data-access/editor/src/lib/action/model/action-ctx-list';
@Injectable()
export class ActionService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public createActionExecution(
        applicationId: string,
        companyId: string,
        createActionExecutionDto: CreateActionExecutionDto
    ): Observable<ActionExecutionCreatedDto> {
        const params = new HttpParams().set('companyId', companyId);
        return this.httpClient.post<ActionExecutionCreatedDto>(
            `${backofficeEnvironment.rest.v2.actions}company/${companyId}/application/${applicationId}/test`,
            createActionExecutionDto,
            {
                params: params,
            }
        );
    }
    public create(dto: CreateActionDto): Observable<ActionCreatedDto> {
        const { companyId, applicationId } = dto;
        this.log.debug('Creating action', [dto]);
        return this.httpClient.post<ActionCreatedDto>(
            `${backofficeEnvironment.rest.v2.actions}company/${companyId}/application/${applicationId}`,
            dto
        );
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Deleting action <${id}>`);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.actions}company/${companyId}/application/${applicationId}/${id}`
        );
    }

    public findById(id: string, companyId: string, applicationId: string): Observable<Action> {
        this.log.debug(`Find action by id <${id}>`);
        return this.httpClient
            .get<Action>(`${backofficeEnvironment.rest.v2.actions}company/${companyId}/application/${applicationId}/${id}`, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
            })
            .pipe(map(action => plainToClass(Action, action)));
    }

    public findContextById(id: string, companyId: string, applicationId: string): Observable<ActionCtx[]> {
        this.log.debug(`Find action context by id <${id}>`);
        return this.httpClient.get<ActionCtx[]>(
            `${backofficeEnvironment.rest.v2.actions}company/${companyId}/application/${applicationId}/${id}/context`,
            { headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true') }
        );
    }

    public findContexts(companyId: string, applicationId: string): Observable<ActionCtxList[]> {
        return this.httpClient.get<ActionCtxList[]>(
            `${backofficeEnvironment.rest.v2.actions}company/${companyId}/application/${applicationId}/contexts`,
            { headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true') }
        );
    }

    public findMethodsByReferenceId(id: string, companyId: string, applicationId: string): Observable<Method[]> {
        this.log.debug(`Getting all method for reference <${id}>`);
        return this.httpClient.get<Method[]>(`${backofficeEnvironment.rest.v2.methods}company/${companyId}/application/${applicationId}`);
    }

    public update(action: Action, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Updating action`, [action]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.actions}company/${companyId}/application/${applicationId}/${action.id}`,
            action
        );
    }

    public findAll(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<OverviewActionDto>> {
        this.log.debug('Getting action overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('keyword', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<Page<OverviewActionDto>>(`${backofficeEnvironment.rest.v2.actions}`, { params });
    }
}
