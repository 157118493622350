import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { RouterFacade } from '../../../../../../../utils-routing/src/lib/facade/router.facade';
import { UserFacade } from '@core/facades/user.facade';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Page } from '../../../../../../../../shared/data-access/src/lib/model/page.model';
import { BillingFacade } from '@billing/facade/billing.facade';
import { initFlowbite } from 'flowbite';
import { UserDto } from '../../../../../edit-company/src/lib/dto/deprecated/user.dto';

@Component({
    selector: 'codex-manage-users-page',
    templateUrl: './manage-users-page.component.html',
    standalone: false,
})
export class ManageUsersPageComponent implements OnInit, OnDestroy, AfterViewInit {
    developers$: BehaviorSubject<Page<UserDto>> = new BehaviorSubject(null);
    users$: BehaviorSubject<Page<UserDto>> = new BehaviorSubject(null);

    environment: string = 'DEV';

    subscriptions: Subscription = new Subscription();

    billing$ = this.billingFacade.package;

    constructor(
        private readonly routerFacade: RouterFacade,
        private readonly userFacade: UserFacade,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService,
        private readonly billingFacade: BillingFacade
    ) {}

    ngOnInit(): void {
        this.fetchDevelopers();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    fetchDevelopers() {
        this.subscriptions.add(
            this.userFacade.findDevelopers('email', 0, 200).subscribe(developers => {
                this.developers$.next(developers);
            })
        );
    }

    onUserInvited(mail: string) {
        this.routerFacade.companyId
            .pipe(
                take(1),
                switchMap(companyId => this.userFacade.inviteUser(mail, companyId)),
                catchError(() => {
                    return of(null);
                })
            )
            .subscribe(() => {
                this.fetchDevelopers();
                this.snackBar.open(this.translate.instant('v2.usermanagement.users.invitation.sent'), null, {
                    panelClass: ['success'],
                });
            });
    }
}
