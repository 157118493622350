import { Argument } from '../../arguments/argument';
import { Method } from './method';
import { InvocationOutput } from './invocation-output';
import { NextInvocation } from './next-invocation';
import { GUIDFunctions } from '@shared/utils';
import { Type } from 'class-transformer';
import { Action } from '@backoffice/editor/data-access/action';

export class Invocation {
    public id: string;
    public name: string;
    public description: string;
    public iconName: string;
    public outlineColor: string;
    public methodKey: string;
    public method: Method = null;

    @Type(() => Argument)
    public arguments: Argument[] = [];

    @Type(() => NextInvocation)
    public nextInvocations: NextInvocation[] = [];

    @Type(() => InvocationOutput)
    public invocationOutputs: InvocationOutput[] = [];
    public x: number;
    public y: number;

    public getShape() {
        if (this.methodKey === 'switch') {
            // Change this to rhombus????
            return 'rectangle';
        } else {
            return 'rectangle';
        }
    }

    public initInvocation(methodMap: Map<string, Method>, actionMap: Map<string, Action>) {
        if (!!this.methodKey) {
            this.method = methodMap.get(this.methodKey);
            if (!!this.method) {
                if (!!this.arguments) {
                    this.arguments.forEach(argument => argument.initArgument(this.method.parameters));
                }
                if (!!this.invocationOutputs) {
                    this.invocationOutputs.forEach(output => output.initOutput(this.method.outputs, actionMap));
                }
            }
        }
    }

    public isValid(): boolean {
        return this.method && this.areArgumentsValid() && this.areNextInvocationsValid() && this.areOutputsValid();
    }

    public getNextInvocation(nextInvocationId: string): NextInvocation {
        if (!!nextInvocationId) {
            return this.nextInvocations.find(nextInvocation => nextInvocation.invocationId === nextInvocationId);
        }
        return null;
    }

    public removeNextInvocation(nextInvocationId: string) {
        if (!!nextInvocationId) {
            this.nextInvocations.splice(
                this.nextInvocations.findIndex(nextInvocation => nextInvocation.invocationId === nextInvocationId),
                1
            );
        }
    }

    public addNextInvocation(nextInvocationId: string) {
        if (!!nextInvocationId) {
            const nextInvocation = new NextInvocation();
            nextInvocation.id = new GUIDFunctions().newGuid();
            nextInvocation.invocationId = nextInvocationId;
            this.nextInvocations.push(nextInvocation);
            return nextInvocation;
        }
    }

    public copy() {
        const invocation: Invocation = new Invocation();
        invocation.name = this.name;
        invocation.description = this.description;
        invocation.iconName = this.iconName;
        invocation.arguments = this.copyArguments();
        invocation.invocationOutputs = this.copyOutputs();
        invocation.methodKey = this.methodKey;
        invocation.method = this.method;
        invocation.id = new GUIDFunctions().newGuid();
        invocation.x = this.x + 10;
        invocation.y = this.y + 10;
        return invocation;
    }

    private copyArguments(): Argument[] {
        const copiedArguments: Argument[] = [];
        this.arguments.forEach(argument => {
            copiedArguments.push(argument.copy());
        });
        return copiedArguments;
    }

    private copyOutputs(): InvocationOutput[] {
        const copiedInvocationOutputs: InvocationOutput[] = [];
        this.invocationOutputs.forEach(output => {
            copiedInvocationOutputs.push(output.copy());
        });
        return copiedInvocationOutputs;
    }

    private areArgumentsValid(): boolean {
        let valid = true;
        this.arguments.forEach(argument => {
            if (!argument.isValid()) {
                valid = false;
            }
        });
        return valid;
    }

    private areNextInvocationsValid(): boolean {
        return true;
    }

    private areOutputsValid(): boolean {
        return true;
    }
}
