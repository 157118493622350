import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { NgxFloatUiTriggers } from 'ngx-float-ui';
import { purchaseDomain, showEntri } from 'entrijs';
import { EntriService } from '@core/services/entri.service';

@Component({
    selector: 'codex-application-edit-general',
    templateUrl: './application-edit-general.component.html',
    standalone: false,
})
export class ApplicationEditGeneralComponent implements OnInit, OnChanges {
    @Input() application: ApplicationDto;

    @Output() updateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{
        application: ApplicationDto;
    }>();
    @Output() cancelUpdateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{
        application: ApplicationDto;
    }>();
    @Output() deleteApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{
        application: ApplicationDto;
    }>();
    @Output() validateDomain: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{
        application: ApplicationDto;
    }>();

    formGroup: FormGroup;

    domainConfig: any = {};
    customDomainChecks: { id: string; name: string; ipv4: string; ipv4prd: string; status: string }[] = [
        {
            id: 'DEV_FRONT',
            name: 'dev',
            ipv4: '130.211.96.238',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'TEST_FRONT',
            name: 'test',
            ipv4: '130.211.96.238',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'ACCEPT_FRONT',
            name: 'accept',
            ipv4: '130.211.96.238',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'PRODUCTION_FRONT',
            name: '@',
            ipv4: '130.211.96.238',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'DEV_BACK',
            name: 'dev-back',
            ipv4: '35.195.146.20',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'TEST_BACK',
            name: 'test-back',
            ipv4: '35.195.146.20',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'ACCEPT_BACK',
            name: 'accept-back',
            ipv4: '35.195.146.20',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'PRODUCTION_BACK',
            name: 'back',
            ipv4: '35.195.146.20',
            ipv4prd: '',
            status: 'pending',
        },
    ];

    get nameControl(): FormControl {
        return this.formGroup.get('name') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.formGroup.get('description') as FormControl;
    }

    get slugControl(): FormControl {
        return this.formGroup.get('slug') as FormControl;
    }

    get customDomainControl(): FormControl {
        return this.formGroup.get('customDomain') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private entriService: EntriService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    showEntri() {
        this.entriService.requestToken().subscribe(authToken => {
            const config = {
                applicationId: 'nocodex_dev',
                token: authToken.auth_token,

                dnsRecords: [
                    {
                        type: 'CNAME',
                        host: 'www',
                        value: 'm.test.com',
                        ttl: 300,
                    },
                    {
                        type: 'TXT',
                        host: '@',
                        value: 'sample-txt-record',
                        ttl: 300,
                    },
                    {
                        type: 'MX',
                        host: 'host',
                        value: 'mailhost1.example.com',
                        priority: 10,
                        ttl: 300,
                    },
                ],
            };
            showEntri(config);
        });
    }
    purchaseDomain() {
        this.entriService.requestToken().subscribe(authToken => {
            const config = {
                applicationId: 'nocodex_dev',
                token: authToken.auth_token,
                whiteLabel: {
                    logo: 'https://app.nocode-x.com/images/theme/nocode-x-logo-symbol-negative.svg',
                    theme: {
                        bg: '#1e293b',
                        fg: '#1e293b',
                        width: '500px',
                        links: '#9C38FF',
                        primary: '#9C38FF',
                        secondary: '#9C38FF',
                        onPrimary: '#FFF',
                        onSecondary: '#FFF',
                        headerBackground: '#1e293b',
                        interactive: '#9C38FF',
                        activating: '#9C38FF',
                        fgSecondary: '#1e293b',
                        bgSecondary: '#1e293b',
                        internalCustomAnimation: null,
                        titles: null,
                    },
                    sell: {
                        partnerLogo: 'https://app.nocode-x.com/images/theme/nocode-x-logo-symbol-negative.svg',
                        partnerIcon: 'https://app.nocode-x.com/images/theme/nocode-x-logo-symbol-negative.svg',
                        partnerName: 'NoCode-X',
                    },
                    hideEntriLogo: true,
                    hideCompanyLogo: false,
                    locale: 'en',
                },
                dnsRecords: [
                    {
                        type: 'A',
                        host: 'dev',
                        value: '130.211.96.238',
                        ttl: 300,
                    },
                    {
                        type: 'A',
                        host: 'test',
                        value: '130.211.96.238',
                        ttl: 300,
                    },
                    {
                        type: 'A',
                        host: 'accept',
                        value: '130.211.96.238',
                        ttl: 300,
                    },
                    {
                        type: 'A',
                        host: '@',
                        value: '130.211.96.238',
                        ttl: 300,
                    },
                    {
                        type: 'A',
                        host: 'dev-back',
                        value: '35.195.146.20',
                        ttl: 300,
                    },
                    {
                        type: 'A',
                        host: 'test-back',
                        value: '35.195.146.20',
                        ttl: 300,
                    },
                    {
                        type: 'A',
                        host: 'accept-back',
                        value: '35.195.146.20',
                        ttl: 300,
                    },
                    {
                        type: 'A',
                        host: 'back',
                        value: '35.195.146.20',
                        ttl: 300,
                    },
                ],
            };
            purchaseDomain(config);
        });
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: [this.application.name, Validators.required],
            description: [this.application.description],
            slug: [this.application.applicationSlug, [Validators.required, Validators.pattern(/^[a-z0-9-_]+$/)]],
            customDomain: [
                this.application.customDomain,
                Validators.pattern(/^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/),
            ],
        });
        this.updateCustomDomains();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateCustomDomains();
    }

    updateCustomDomains() {
        for (const customDomainCheck of this.customDomainChecks) {
            if (customDomainCheck.id === 'DEV_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendDevCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'TEST_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendTestCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'ACCEPT_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendAcceptCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'PRODUCTION_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendProductionCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'DEV_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendDevCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'TEST_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendTestCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'ACCEPT_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendAcceptCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'PRODUCTION_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendProductionCheck, customDomainCheck);
            }
        }
    }

    changeCustomDomainCheckStatus(status: string, customDomainCheck: { status: string }) {
        if (status === 'CHECK_SUCCESS') {
            customDomainCheck.status = 'success';
        } else if (status === 'CHECK_FAILED') {
            customDomainCheck.status = 'failed';
        } else if (status === 'CHECK_PENDING') {
            customDomainCheck.status = 'pending';
        }
    }

    onChangeIconName(iconName: string) {
        this.application.iconName = iconName;
    }

    onUpdateApplication() {
        this.application.name = this.nameControl.value;
        this.application.description = this.descriptionControl.value;
        this.application.applicationDomainName = this.application.applicationDomainName.replace(
            this.application.applicationSlug,
            this.slugControl.value
        );
        this.application.applicationSlug = this.slugControl.value;
        this.application.customDomain = this.customDomainControl.value;
        if (this.formGroup.valid) {
            this.updateApplication.emit({ application: this.application });
        }
    }

    onCancel() {
        this.cancelUpdateApplication.emit({ application: this.application });
    }

    onDeleteApplication() {
        this.deleteApplication.emit({ application: this.application });
    }

    onValidateDomains() {
        this.validateDomain.emit({ application: this.application });
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
