<ng-container
    *ngIf="{
        connections: this.connections$ | async,
        nodes: this.nodes$ | async,
    } as observables">
    <f-flow id="application-overview" fDraggable>
        <f-canvas #canvas fZoom>
            @for (connection of observables.connections; track connection.id) {
                <f-connection
                    fType="bezier"
                    class="flow-connections"
                    [fOffset]="8"
                    [fOutputId]="connection.from"
                    [fInputId]="connection.to"
                    fBehavior="fixed"
                    fStartColor="#334155"
                    fEndColor="#334155"></f-connection>
            }
            @for (node of observables.nodes; track node.id) {
                <div
                    fNode
                    [fNodePosition]="node.position"
                    fDragHandle
                    fNodeInput
                    [fInputId]="node.connectorId"
                    fInputConnectableSide="top"
                    class="flex gap-2">
                    <div
                        class="application-overview-node"
                        [ngClass]="node.id === selectedNodeId ? 'selected' : ''"
                        *ngIf="node.type === 'APPLICATION_PLAN'"
                        (click)="setSelectedNode(node.id, 'application-plan')">
                        <div class="flex gap-2 justify-end mb-2">
                            <div *ngIf="!node.editing" data-name="preview" class="link" (click)="node.editing = true">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#edit" class="ui-element"></use>
                                </svg>
                            </div>
                            <div *ngIf="node.editing" data-name="preview" class="link" (click)="node.editing = false">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#save" class="ui-element"></use>
                                </svg>
                            </div>
                            <div data-name="preview" class="link" (click)="executeGenerativeTask(node.generativeTaskId)">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#play" class="ui-element"></use>
                                </svg>
                            </div>
                            <div data-name="preview" class="link">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#generative" class="ui-element"></use>
                                </svg>
                            </div>
                        </div>
                        <h1 class="mb-4">Application description</h1>
                        <div class="flex gap-2 justify-end mb-2">
                            <ng-container *ngIf="node.editing">
                                <textarea
                                    class="small-input"
                                    rows="100"
                                    type="text"
                                    autocomplete="off"
                                    [(ngModel)]="node.prompt"></textarea>
                            </ng-container>
                            <ng-container *ngIf="!node.editing">
                                <markdown [start]="5" [data]="node.prompt" [disableSanitizer]="true"></markdown>
                            </ng-container>
                        </div>
                        <div fNodeOutput [fOutputId]="node.connectorId" fOutputConnectableSide="bottom"></div>
                    </div>
                    <div
                        class="application-overview-node"
                        [ngClass]="node.id === selectedNodeId ? 'selected' : ''"
                        *ngIf="node.type === 'GENERATE_FEATURE'"
                        (click)="setSelectedNode(node.id, 'application-plan')">
                        <div fNodeInput [fInputId]="node.connectorId" fInputConnectableSide="top"></div>
                        <div class="flex gap-2 justify-end mb-2">
                            <div *ngIf="!node.editing" data-name="preview" class="link" (click)="node.editing = true">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#edit" class="ui-element"></use>
                                </svg>
                            </div>
                            <div *ngIf="node.editing" data-name="preview" class="link" (click)="node.editing = false">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#save" class="ui-element"></use>
                                </svg>
                            </div>
                            <div data-name="preview" class="link" (click)="executeGenerativeTask(node.generativeTaskId)">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#play" class="ui-element"></use>
                                </svg>
                            </div>
                            <div data-name="preview" class="link">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#generative" class="ui-element"></use>
                                </svg>
                            </div>
                            <div data-name="preview" class="link">
                                <svg class="icon" aria-hidden="true" focusable="false">
                                    <use href="#remove" class="ui-element"></use>
                                </svg>
                            </div>
                        </div>
                        <h1 class="mb-4">Feature brief</h1>
                        <div class="flex gap-2 justify-end mb-2">
                            <ng-container *ngIf="node.editing">
                                <textarea
                                    class="small-input"
                                    rows="100"
                                    type="text"
                                    autocomplete="off"
                                    [(ngModel)]="node.prompt"></textarea>
                            </ng-container>
                            <ng-container *ngIf="!node.editing">
                                <markdown [start]="5" [data]="node.prompt" [disableSanitizer]="true"></markdown>
                            </ng-container>
                        </div>
                        <div fNodeOutput [fOutputId]="node.connectorId" fOutputConnectableSide="bottom"></div>
                    </div>
                    <div *ngIf="node.tasks && node.tasks.length > 0" class="flex flex-col gap-2">
                        <ng-container *ngFor="let task of node.tasks">
                            <div class="application-overview-task">
                                {{ task.name }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            }
        </f-canvas>
    </f-flow>
</ng-container>
