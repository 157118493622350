import { ColorV2 } from '../../../../../designsystem/models/color/colorv2.model';

export class PieChartSeriesOptions {
    readonly type = 'pie';
    id?: string;
    name?: string;
    data: PieChartDataOptions[] = [];
    emphasis?: any;
    center: any[];
    radius: any[];
    label: any;

    constructor(base?: Partial<PieChartSeriesOptions>) {
        if (base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.data = base.data ?? this.data;
            this.emphasis = base.emphasis ?? this.emphasis;
            this.center = base.center ?? this.center;
            this.radius = base.radius ?? this.radius;
            this.label = base.label ?? this.label;
        }
    }
}

export class PieChartDataOptions {
    name?: string;
    value?: any;
    itemStyle?: PieChartDataItemStyleOptions;

    constructor(base?: Partial<PieChartDataOptions>) {
        if (base) {
            this.name = base.name ?? this.name;
            this.value = base.value ?? this.value;
            this.itemStyle = base.itemStyle ?? this.itemStyle;
        }
    }
}

export class PieChartDataItemStyleOptions {
    color?: string;

    constructor(base?: Partial<PieChartDataItemStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}
