export class CompanySecurityDto {
    id: string;
    companyId: string;
    apiAccessEnabled: boolean;

    devRedirectUrls: string[];
    testRedirectUrls: string[];
    acceptRedirectUrls: string[];
    productionRedirectUrls: string[];
    devApplicationUrls: string[];
    testApplicationUrls: string[];
    acceptApplicationUrls: string[];
    productionApplicationUrls: string[];
}
