import { Parameter } from '../parameters/parameter';
import { Exclude, Type } from 'class-transformer';
import { GUIDFunctions } from '@shared/utils';

export class Argument {
    id?: string;
    selectorId?: string;
    name?: string;
    value?: string;
    calculatedValue?: string;
    contractValue: string | undefined;
    type?: string;
    subtype?: string;
    allowedTypes: string[];
    parameterId?: string;
    outputId?: string;
    operator?: string;
    order?: string;

    templateKey?: string;

    inputSelectionType?: string;

    @Type(() => Argument)
    subArguments?: Argument[];
    subArgumentsForAction?: string | null;

    parameter?: Parameter;

    @Exclude()
    output?: any;

    @Exclude()
    composedTitle: string;

    public isValid(): boolean {
        if (this.parameter && this.parameter.required) {
            if (this.inputSelectionType !== 'object' && this.inputSelectionType !== 'array') {
                return !!this.value;
            } else {
                return !!this.subArguments;
            }
        } else {
            return true;
        }
    }

    public initArgument(parameters: Parameter[]) {
        if (parameters) {
            for (let i = 0; i < parameters.length; i++) {
                const parameters1 = parameters[i];
                if (parameters1.id === this.parameterId) {
                    this.parameter = parameters1;
                    if (this.subArguments) {
                        this.subArguments.forEach(subArgument => subArgument.initArgument(parameters1.parameters));
                    }
                    break;
                }
            }
        }
    }

    public copy() {
        const argument: Argument = new Argument();
        argument.id = this.id;
        argument.name = this.name;
        argument.selectorId = new GUIDFunctions().newGuid();
        argument.value = this.value;
        argument.type = this.type;
        argument.inputSelectionType = this.inputSelectionType;
        argument.parameterId = this.parameterId;
        argument.parameter = this.parameter;
        argument.subArguments = this.copyArguments();
        return argument;
    }

    private copyArguments(): Argument[] {
        const copiedArguments: Argument[] = [];
        this.subArguments?.forEach(argument => {
            copiedArguments.push(argument.copy());
        });
        return copiedArguments;
    }

    public updateArgument(newArgument: Argument) {
        this.parameter = newArgument.parameter;
        this.name = newArgument.parameter?.name;
        this.parameterId = newArgument.parameter?.id;
        this.selectorId = newArgument.parameter?.selectorId;
    }

    public mergeArguments(newArguments: Argument[] | undefined) {
        let newArgumentAdded: boolean = false;
        if (newArguments && newArguments.length > 0 && this.parameter?.type != 'ARRAY') {
            this.subArguments?.forEach(subArgument => {
                const newArgument: Argument | undefined = newArguments?.find(
                    newArgument => subArgument.selectorId === newArgument.selectorId || subArgument.parameterId === newArgument.parameterId
                );
                if (newArgument) {
                    subArgument.updateArgument(newArgument);
                    subArgument.mergeArguments(newArgument.subArguments);
                }
            });
            newArguments.forEach(newArgument => {
                const existingSubArgument: Argument | undefined = this.subArguments?.find(
                    subArgument => subArgument.selectorId === newArgument.selectorId || subArgument.parameterId === newArgument.parameterId
                );
                if (!existingSubArgument) {
                    this.subArguments?.push(newArgument);
                    newArgumentAdded = true;
                }
            });
            this.subArguments = this.subArguments?.filter(subArgument =>
                newArguments.find(
                    newArgument => subArgument.selectorId === newArgument.selectorId || newArgument.parameterId === subArgument.parameterId
                )
            );
        } else if (
            this.parameter?.type !== 'ACTION_PARAMS' &&
            this.parameter?.type !== 'DATA_BODY' &&
            this.parameter?.type !== 'TEMPLATE_PARAMS' &&
            this.parameter?.type !== 'OBJECT' &&
            this.parameter?.type !== 'ARRAY'
        ) {
            this.subArguments = [];
        }
        return newArgumentAdded;
    }
}

export function showArgumentInput(argument: Argument, argumentList: Argument[] | undefined): boolean {
    if (!!argument?.parameter) {
        if (!!argument.parameter.hideIfEmptyParameterIds && argument.parameter.hideIfEmptyParameterIds.length > 0) {
            let allFilledIn = true;
            for (const hideIfEmptyParameterId of argument.parameter.hideIfEmptyParameterIds) {
                const argumentToCheck = findArgument(hideIfEmptyParameterId, argumentList);
                if (!!argumentToCheck && (!argumentToCheck.value || argumentToCheck.value === '' || argumentToCheck.value === "''")) {
                    allFilledIn = false;
                    break;
                }
            }
            return allFilledIn;
        }
        if (!!argument.parameter.showParameterConditionals && argument.parameter.showParameterConditionals.length > 0) {
            for (const showParameterConditional of argument.parameter.showParameterConditionals) {
                const argumentToCheck = findArgument(showParameterConditional.parameterSelectorId, argumentList);
                if (showParameterConditional.type == 'EQUALS') {
                    if (!!argumentToCheck && argumentToCheck.value === showParameterConditional.parameterValue) {
                        return true;
                    }
                } else if (showParameterConditional.type == 'NOT_EQUALS') {
                    if (
                        !!argumentToCheck &&
                        (!argumentToCheck.value || argumentToCheck.value !== showParameterConditional.parameterValue)
                    ) {
                        return true;
                    }
                } else if (showParameterConditional.type == 'CONTAINS') {
                    if (
                        !!argumentToCheck &&
                        argumentToCheck.value &&
                        argumentToCheck.value.indexOf(showParameterConditional.parameterValue) > -1
                    ) {
                        return true;
                    }
                } else if (showParameterConditional.type == 'NOT_CONTAINS') {
                    if (
                        !!argumentToCheck &&
                        (!argumentToCheck.value || argumentToCheck.value.indexOf(showParameterConditional.parameterValue) === -1)
                    ) {
                        return true;
                    }
                }
            }
            return false;
        }
        return true;
    }
    return true;
}

function findArgument(parameterIdOrSelectorId: string, argumentList: Argument[] | undefined): Argument | null {
    let foundArgument = null;
    if (argumentList) {
        for (const argument of argumentList) {
            if (argument.parameter?.id === parameterIdOrSelectorId || argument.selectorId === parameterIdOrSelectorId) {
                foundArgument = argument;
                break;
            } else if (argument.subArguments && argument.subArguments.length > 0) {
                foundArgument = findArgument(parameterIdOrSelectorId, argument.subArguments);
            }
        }
    }
    return foundArgument;
}

export function createArguments(parameters: Parameter[] | undefined): Argument[] {
    const argumentList: Argument[] = [];
    if (parameters && parameters.length > 0) {
        parameters.forEach(parameter => {
            const argument: Argument = new Argument();
            argument.id = new GUIDFunctions().newGuid();
            argument.calculatedValue = parameter.defaultValue?.calculatedValue;
            argument.value = parameter.defaultValue?.value;
            argument.type = parameter.defaultValue?.type ? parameter.defaultValue?.type : parameter.type;
            argument.inputSelectionType = parameter.defaultValue?.inputSelectionType;
            argument.parameterId = parameter.id;
            argument.parameter = parameter;
            argument.name = parameter.name;
            argument.selectorId = parameter.selectorId;
            argument.subArguments = createArguments(parameter.parameters);
            argumentList.push(argument);
        });
    }
    return argumentList;
}
