import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Invocation } from '../../../../../../../data-access/editor/src/lib/action/model/invocation';
import { Action } from '@backoffice/editor/data-access/action';
import { ActionGrid } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-actions/components/action-edit/action-grid/action-grid';
import { Method } from '../../../../../../../data-access/editor/src/lib/action/model/method';
import { NextInvocation } from '../../../../../../../data-access/editor/src/lib/action/model/next-invocation';
import { ActionCtx } from '../../../../../../../data-access/editor/src/lib/action/model/action-ctx';
import { Scope } from '../../../../../../../data-access/editor/src/lib/action/model/scope';
import { Observable } from 'rxjs';
import { ActionContextFacade } from '@backoffice/data-access/editor';
import { Parameter } from '../../../../../../../data-access/editor/src/lib/parameters/parameter';
import { TabDefinition } from '../../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-action-tool-pane',
    templateUrl: './action-tool-pane.component.html',
    standalone: false,
})
export class ActionToolPaneComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() action: Action;
    @Input() actionGrid: ActionGrid;
    @Input() language: string;
    @Input() methods: Method[];
    @Input() methodPickerOpen: { opened: boolean };
    @Input() referenceId: string;
    @Input() selectedInvocation: Invocation;
    @Input() selectedNextInvocation: NextInvocation;
    @Input() selectedNextInvocationSource: Invocation;
    @Input() selectedNextInvocationTarget: Invocation;

    @Output() actionUpdated: EventEmitter<{ action: Action }> = new EventEmitter<{ action: Action }>();
    @Output() addInvocation: EventEmitter<{ method: Method }> = new EventEmitter<{ method: Method }>();
    @Output() invocationUpdated: EventEmitter<{ invocation: Invocation; action: Action }> = new EventEmitter<{
        invocation: Invocation;
        action: Action;
    }>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();
    @Output() calculateScope = new EventEmitter<Action>();

    expandedInvocationDetail = false;

    expandedNextInvocation = false;

    scope$: Observable<Scope>;

    selectedIndex: number = 0;

    constructor(
        private actionContextFacade: ActionContextFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.scope$ = this.actionContextFacade.getScope(this.action?.id);
    }

    ngOnDestroy() {
        console.log('hier komtie !!!!2');
    }

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    reloadFlowBite() {
        setTimeout(() => initFlowbite());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedInvocation?.previousValue?.id !== changes.selectedInvocation?.currentValue?.id) {
            this.expandedInvocationDetail = !!changes.selectedInvocation?.currentValue;
            // Yup, yet another ugly hack to make angular do something correctly...
            // This one is to make sure the first tab is always selected instead of the tab without an NGIf
            this.selectedIndex = null;
            this.selectedIndex = 0;
            this.changeDetectorRef.detectChanges();
            setTimeout(() => initFlowbite());
        }

        if (changes.selectedNextInvocation?.previousValue !== changes.selectedNextInvocation?.currentValue) {
            this.expandedNextInvocation = !!changes.selectedNextInvocation?.currentValue;
        }
        setTimeout(() => initFlowbite());
    }

    onParametersUpdated($event: { parameters: Parameter[] }) {
        this.action.program.parameters = $event.parameters;
        this.actionUpdated.emit({ action: this.action });
        this.handleCalculateScope();
    }

    handleCalculateScope(): void {
        this.calculateScope.emit(this.action);
    }
}
