import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacade } from '@core/facades/app.facade';
import { ApplicationService } from '@core/services/application.service';
import { map, take } from 'rxjs/operators';
import { ApplicationDto } from '../../../../../../data-access/editor/src/lib/interfaces/application.dto';

@Component({
    selector: 'app-application-picker',
    templateUrl: './application-picker.component.html',
    standalone: false,
})
export class ApplicationPickerComponent implements OnInit {
    @Input()
    label: string;

    @Input()
    companyId: string;

    @Input()
    selectedApplicationId: string;

    applications$: Observable<ApplicationDto[]>;

    @Output()
    applicationChanged: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{
        application: ApplicationDto;
    }>();

    constructor(
        private readonly appFacade: AppFacade,
        private readonly applicationService: ApplicationService
    ) {}

    ngOnInit(): void {
        this.applications$ = this.applicationService.getApplications(this.companyId).pipe(map(page => page.content));
    }

    onApplicationSelect($event) {
        return this.applicationService
            .getApplication(this.selectedApplicationId, this.companyId)
            .pipe(take(1))
            .subscribe(application => {
                this.applicationChanged.emit({ application: application });
            });
    }
}
