import { Component, OnInit } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataFormatEditorFacade } from '@backoffice/data-access/editor';
import { data } from 'autoprefixer';
import { take } from 'rxjs/operators';
import { initFlowbite } from 'flowbite';
import { Observable } from 'rxjs';
import { BillingPackage } from '@billing/dto/billingpackage';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { BillingFacade } from '@billing/facade/billing.facade';

@Component({
    selector: 'codex-api-create-form',
    templateUrl: './api-create-form.component.html',
    standalone: false,
})
export class ApiCreateFormComponent implements OnInit {
    selectedType = 'BLANK';

    environment = backofficeEnvironment;

    dataFormats: {
        name: string;
        id: string;
    }[] = [];

    public formGroup: FormGroup;

    billingPackage$: Observable<BillingPackage>;

    constructor(
        public dialogRef: MatDialogRef<ApiCreateFormComponent>,
        public dataFormatEditorFacade: DataFormatEditorFacade,
        public fb: FormBuilder,
        public billingFacade: BillingFacade
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: ['Unnamed Api', Validators.required],
            datadescription: [''],
        });
        this.billingPackage$ = this.billingFacade.package;
    }

    onSelectType(type: string) {
        this.selectedType = type;
    }
    onDataFormatPick($event: { value: string | undefined }) {
        if ($event.value) {
            this.dataFormatEditorFacade
                .findById($event.value)
                .pipe(take(1))
                .subscribe(dataFormat => {
                    this.dataFormats.push({ id: dataFormat.id, name: dataFormat.name });
                });
        }
    }

    onRemoveDataFormat(dataFormatToRemove: { name: string; id: string }): void {
        let index = this.dataFormats.findIndex(dataFormat => dataFormat.id === dataFormatToRemove.id);
        this.dataFormats.splice(index, 1);
        setTimeout(() => initFlowbite());
    }

    onCreate() {
        let dataFormatIds = this.dataFormats.map(dataFormat => dataFormat.id);
        this.dialogRef.close({
            create: true,
            type: this.selectedType,
            dataFormatIds: dataFormatIds,
            name: this.formGroup.get('name')?.value,
            dataFormatDescription: this.formGroup.get('datadescription')?.value,
        });
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
