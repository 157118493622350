import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { OverviewTemplateDto } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/dto/overview/overview-template.dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Argument, TabDefinition } from '@backoffice/data-access/editor';
import { RouterFacade } from '@backoffice/utils-routing';
import { $e } from 'codelyzer/angular/styles/chars';

@Component({
    selector: 'codex-application-edit-error',
    templateUrl: './application-edit-error.component.html',
    standalone: false,
})
export class ApplicationEditErrorComponent implements OnInit {
    @Input()
    application: ApplicationDto;

    @Output()
    updateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();

    @Output()
    cancelUpdateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();

    formGroup: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
        private readonly routerFacade: RouterFacade
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({});
    }

    onErrorTemplateChanged($event: { value: string | undefined; arguments?: Argument[] | undefined }) {
        if ($event.value) {
            this.application.errorTemplateId = $event.value;
        } else {
            this.application.errorTemplateId = null;
        }
    }

    onNotFoundTemplateChanged($event: { value: string | undefined; arguments?: Argument[] | undefined }) {
        if ($event.value) {
            this.application.notFoundTemplateId = $event.value;
        } else {
            this.application.notFoundTemplateId = null;
        }
    }

    onUnAuthorizedTemplateChanged($event: { value: string | undefined; arguments?: Argument[] | undefined }) {
        if ($event.value) {
            this.application.unAuthorizedTemplateId = $event.value;
        } else {
            this.application.unAuthorizedTemplateId = null;
        }
    }

    onUpdateApplication() {
        this.updateApplication.emit({ application: this.application });
    }

    onOpenTemplate($event: TabDefinition) {
        this.routerFacade.navigate([`/company/${this.application.companyId}/application/${this.application.id}/editor`], {
            queryParams: {
                type: 'template',
                id: $event.typeId,
            },
        });
        this.onCancel();
    }

    onCancel() {
        this.cancelUpdateApplication.emit({ application: this.application });
    }
}
