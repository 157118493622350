import { ApplicationState } from '../application.state';
import { createSelector } from '@ngrx/store';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { selectSelectedApplication } from '../../v2-application/statemanagement/selectors/application.selector';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { CompanyUserRightDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.right.dto';
import { CompanyUserRoleDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.role.dto';
import { selectSelectedCompany } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/statemanagement/selectors/company.selector';

export const selectUser = (state: ApplicationState) => state.context.user;

export const selectUserRights = (state: ApplicationState) => state.context.rights;

export const selectEditorNavigationRights = createSelector(selectUserRights, userRights => {
    if (userRights && userRights.calculatedRightNames) {
        return {
            showTemplateNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_TEMPLATE') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_TEMPLATE') > -1,
            showApiNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_API') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_API') > -1,
            showDataNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_DATA') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_DATA') > -1,
            showDataFormatNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_DATAFORMAT') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_DATAFORMAT') > -1,
            showMediaNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_MEDIA') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_MEDIA') > -1,
            showActionsNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_ACTIONS') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_ACTIONS') > -1,
            showDesignSystemNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_DESIGNSYSTEM') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_DESIGNSYSTEM') > -1,
            showJobNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_JOB') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_JOB') > -1,
            showVersionNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_VERSION') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_VERSION') > -1,
            showGroupsNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_API') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_API') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_READ_TEMPLATE') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_TEMPLATE') > -1,
            showRightsNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_API') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_API') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_READ_TEMPLATE') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_TEMPLATE') > -1,
            showUsersNav:
                userRights.calculatedRightNames.indexOf('ROLE_READ_USER') > -1 ||
                userRights.calculatedRightNames.indexOf('ROLE_WRITE_USER') > -1,
        };
    } else {
        return {};
    }
});

export const selectAuthenticationCompanyUserRights = (state: ApplicationState) => state.data.authenticationCompanyUserRights;
export const selectAuthenticationCompanyUserRightsMap = createSelector(
    selectAuthenticationCompanyUserRights,
    (authenticationRoles: CompanyUserRightDto[]) => {
        if (authenticationRoles) {
            return new Map(authenticationRoles.map(authenticationRole => [authenticationRole.companyRightName, authenticationRole]));
        } else {
            return new Map();
        }
    }
);

export const selectAuthenticationCompanyUserRoles = (state: ApplicationState) => state.data.authenticationCompanyUserRoles;
export const selectAuthenticationCompanyUserRolesMap = createSelector(
    selectAuthenticationCompanyUserRoles,
    (authenticationRoles: CompanyUserRoleDto[]) => {
        if (authenticationRoles) {
            return new Map(authenticationRoles.map(authenticationRole => [authenticationRole.roleTag, authenticationRole]));
        } else {
            return new Map();
        }
    }
);

export const selectCurrentContext = createSelector(
    selectUser,
    selectSelectedCompany,
    selectSelectedApplication,
    (user: any, selectedCompany: CompanyDto, selectedApplication: ApplicationDto) => {
        if (user && selectedCompany && selectedApplication) {
            return {
                user,
                userLanguage: user.language,
                selectedCompany,
                selectedApplication,
            };
        }
    }
);

export const selectPackage = (state: ApplicationState) => state.context.billing;
