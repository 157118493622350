<mat-dialog-content class="scrollbar scrollbar-primary">
    <div class="tabs-header-wrapper">
        <ul
            class="tabs-header"
            data-tabs-toggle="#users-tab-content"
            role="tablist"
            data-tabs-inactive-classes="tabs-tab-inactive"
            data-tabs-active-classes="tabs-tab-active">
            <li class="tabs-tab">
                <button
                    id="users-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#users-mgmt"
                    role="tab"
                    aria-controls="users"
                    aria-selected="false">
                    <svg aria-hidden="true" focusable="false" class="icon"><use href="#users" class="ui-element"></use></svg>
                    {{ 'v2.usermanagement.users.title' | translate }}
                </button>
            </li>
            <li class="tabs-tab">
                <button
                    id="groups-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#groups-mgmt"
                    role="tab"
                    aria-controls="groups"
                    aria-selected="false">
                    <svg aria-hidden="true" focusable="false" class="icon"><use href="#groups" class="ui-element"></use></svg>
                    Groups
                </button>
            </li>
            <li class="tabs-tab">
                <button
                    id="rights-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#rights-mgmt"
                    role="tab"
                    aria-controls="rights"
                    aria-selected="false">
                    <svg aria-hidden="true" focusable="false" class="icon"><use href="#rights" class="ui-element"></use></svg>
                    Rights
                </button>
            </li>
        </ul>
    </div>
    <div id="users-tab-content" class="p-2">
        <div id="users-mgmt" class="hidden" role="tabpanel" aria-labelledby="users-tab">
            <codex-user-overview (userInvited)="onUserInvited($event)"></codex-user-overview>
        </div>
        <div id="groups-mgmt" class="hidden" role="tabpanel" aria-labelledby="groups-tab">
            <codex-search-group-dialog></codex-search-group-dialog>
        </div>
        <div id="rights-mgmt" class="hidden" role="tabpanel" aria-labelledby="rights-tab">
            <codex-search-right-dialog></codex-search-right-dialog>
        </div>
    </div>
</mat-dialog-content>
