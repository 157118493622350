import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
    actionDialogFacetsChanged,
    actionDialogFilterPluginsChanged,
    actionDialogPaginationChanged,
    actionDialogSearchTermChanged,
    clearActionDialogFilter,
    createActionSuccess,
    deleteAction,
    deleteActions,
    deleteActionsSuccess,
    deleteActionSuccess,
    fetchActionError,
    fetchActionSuccess,
    findAction,
    loadActionDialogData,
    loadActionDialogSuccess,
    updateAction,
    updateActionSuccess,
} from '../actions/action-editor.actions';
import { ActionService } from '../../../../../../editor/data-access/action/src/lib/services/action.service';
import { Action } from '../../action/model/action';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { actionEditorSelectors } from '../selectors/action-editor.selectors';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import {
    calculateScope,
    fetchActionContextSuccess,
    fetchActionMethodsSuccess,
    findActionCalledActions,
    findActionContext,
    findActionMethod,
    updateActionScope,
    updateCalledActions,
} from '../actions/action-context.actions';
import { OverviewActionDto, toFilter } from '@backoffice/data-access/editor';
import { ActionContextFacade } from '../facades/action-context.facade';
import { ActionEditorFacade } from '../facades/action-editor.facade';
import { forkJoin, of } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { Page } from '@shared/data-access';
import { ActionContext } from '../../interfaces/action-context.interface';
import {
    refreshUserAchievements,
    requestUserAchievements,
    selectApplicationSuccess,
} from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { driver } from 'driver.js';
import { AchievementsService } from '@core/services/achievements.service';

@Injectable()
export class ActionEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly actionService: ActionService,
        private readonly actionContextFacade: ActionContextFacade,
        private readonly editorFacade: ActionEditorFacade,
        private readonly achievementsService: AchievementsService,
        private readonly log: LoggerService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearActionDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActions),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.actionService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteActionsSuccess({ ids }))
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActionSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'action' }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActionsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'action' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAction),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.delete(id, companyId, applicationId).pipe(map(() => deleteActionSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findAction),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(actionEditorSelectors.byId(id)),
            ]),
            filter(([_action, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail && !detail.invalidated) {
                    return of(fetchActionSuccess({ action: detail }));
                } else if (!detail) {
                    return this.actionService.findById(id, companyId, applicationId).pipe(
                        map(result => fetchActionSuccess({ action: result })),
                        catchError(() => of(fetchActionError({ action: Action.createDeleted(id) })))
                    );
                } else if (detail.invalidated) {
                    return this.actionService.findById(id, companyId, applicationId).pipe(
                        map(result => {
                            result.backEndUpdate = true;
                            return updateActionSuccess({ action: result });
                        }),
                        catchError(() => of(fetchActionError({ action: Action.createDeleted(id) })))
                    );
                } else {
                    return of(fetchActionError({ action: Action.createDeleted(id) }));
                }
            })
        )
    );

    fetchContext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionContext),
            debounceTime(500),
            concatLatestFrom(({ action }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([_action, company, application]) => !!company && !!application),
            mergeMap(([{ action }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.findContextById(action.id, companyId, applicationId).pipe(
                    take(1),
                    map(result =>
                        fetchActionContextSuccess({
                            action,
                            actionContext: result,
                        })
                    )
                )
            )
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteActionsSuccess,
                loadActionDialogData,
                actionDialogPaginationChanged,
                actionDialogSearchTermChanged,
                actionDialogFacetsChanged,
                actionDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.actionService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewActionDto>) => loadActionDialogSuccess({ data }))
        )
    );

    recalculateMethods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionMethod),
            debounceTime(500),
            concatLatestFrom(({ action: Action }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            mergeMap(([{ action }, company, application]) =>
                this.actionService.findMethodsByReferenceId(action.id, company.id, application.id).pipe(
                    take(1),
                    map(result =>
                        fetchActionMethodsSuccess({
                            action: action,
                            methods: result,
                        })
                    )
                )
            )
        )
    );

    recalculateCalledActions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionCalledActions),
            debounceTime(500),
            map(({ action }) => {
                const calledActionIds = new Set<string>();
                action.program.invocations.forEach(invocation => {
                    if (invocation && invocation.invocationOutputs) {
                        invocation.invocationOutputs.forEach(invocationOutput => {
                            if (!!invocationOutput.subOutputsForAction && !invocationOutput.subOutputsForAction.startsWith('{')) {
                                calledActionIds.add(invocationOutput.subOutputsForAction);
                            }
                        });
                    }
                });
                return updateCalledActions({
                    id: action.id,
                    calledActions: Array.from(calledActionIds.values()),
                });
            })
        )
    );

    recalculateScope$ = createEffect(() =>
        this.actions$.pipe(
            ofType(calculateScope),
            debounceTime(500),
            concatLatestFrom(({ action }) => [
                this.actionContextFacade.getActionContext(action.id).pipe(
                    take(1),
                    filter((actionContext: ActionContext | undefined): actionContext is ActionContext => {
                        return !!actionContext && !!actionContext.actionCtxs && !!actionContext.methods && !!actionContext.language;
                    })
                ),
            ]),
            mergeMap(([{ action }, actionContext]) => {
                return this.actionContextFacade.getActionCalledActions(action.id).pipe(
                    take(1),
                    switchMap(calledActions => {
                        return this.actionContextFacade
                            .loadActionScope(action, actionContext.methods, calledActions, actionContext.actionCtxs, actionContext.language)
                            .pipe(
                                map(scope =>
                                    updateActionScope({
                                        id: action.id,
                                        scope,
                                    })
                                )
                            );
                    })
                );
            })
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAction),
            debounceTime(backofficeEnvironment.autosavedebounce),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([{ action }, company, application]) => action.isValid() && !!company && !!application),
            switchMap(([{ action }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.update(action, companyId, applicationId).pipe(map(() => updateActionSuccess({ action })))
            )
        )
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createActionSuccess),
            map(({ id }) => registerTab({ definition: { type: 'action', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionSuccess),
            map(({ action }) => {
                const { id: typeId, name, iconName: icon } = action;
                return updateTab({ definition: { type: 'action', typeId, name, icon } });
            })
        )
    );

    activateActionOnboarding$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createActionSuccess),
                concatLatestFrom(() => [this.appFacade.user, this.appFacade.context]),
                tap(([{ id }, userContext, context]) => {
                    let genericOnboardingAchievement = userContext.achievements?.find(
                        achievement => achievement.code === 'ACTION_ONBOARDING'
                    );
                    if (!genericOnboardingAchievement) {
                        const driverObj = driver({
                            showProgress: true,
                            onDestroyed: () => {
                                this.achievementsService
                                    .createAchievement(userContext.id, 'ACTION_ONBOARDING')
                                    .pipe(take(1))
                                    .subscribe(() => {
                                        this.store.dispatch(refreshUserAchievements({ info: userContext }));
                                    });
                            },
                            steps: [
                                {
                                    popover: {
                                        title: 'Getting Started with Logic Building',
                                        description:
                                            '<img src="images/theme/guide.gif" class="intro" width="300" style="height: 300px; margin:auto;"/> ' +
                                            '<p>Getting started with logic building in NoCode-X can feel overwhelming due to the many possibilities at your disposal. But don’t worry—we’re here to guide you through the basics. We’ll explain everything step by step!</p>',
                                        popoverClass: 'driver-intro',
                                    },
                                },
                                {
                                    element: 'foreignObject .invocation',
                                    popover: {
                                        title: 'Start block (1/2)',
                                        description:
                                            "<p>Every action (or logic) begins with a Start Block. This block is permanent and cannot be deleted. <br></br> As you build your logic, you'll connect other blocks to it. Keep in mind that execution always begins here, <br></br>flowing sequentially from one block to the next.</p>",
                                    },
                                },
                                {
                                    element: 'foreignObject .invocation',
                                    popover: {
                                        title: 'Start block (2/2)',
                                        description:
                                            '<img src="images/theme/start_block.gif" width="600" style="margin:auto;"/> ' +
                                            '<p>Clicking the Start Block allows you to customize its settings. You can modify its name and description, add parameters for incoming data, and define outputs for data flowing out of the action.</p>',
                                    },
                                },
                                {
                                    element: '.left-drawer-block',
                                    popover: {
                                        title: 'Function picker',
                                        description:
                                            '<p>Functions are the core components of logic, and you can combine them in any way you like. The function selector offers a wide range of functions to choose from.</p>',
                                    },
                                },
                                {
                                    element: '.left-drawer-block .drawer-search-section input',
                                    popover: {
                                        title: 'Function filter',
                                        description:
                                            '<p>Too many functions too browse? We understand there are a lot! Use the filter function to quickly find what you are looking for.</p>',
                                    },
                                },
                                {
                                    element: '.left-drawer-block .draggables-grid button:first-of-type',
                                    popover: {
                                        title: 'Function',
                                        description:
                                            '<img src="images/theme/drag_drop_function.gif" width="600" style="margin:auto;"/> ' +
                                            '<p>Choose the function that will do the job & drag & drop it into place.</p>',
                                    },
                                },
                                {
                                    popover: {
                                        title: 'Connect it up!',
                                        description:
                                            '<img src="images/theme/hook_em_up.gif" width="600" style="margin:auto;"/> ' +
                                            "<p>Connect up your blocks, configure it according to your needs & you're ready to go!</p>",
                                        popoverClass: 'driver-intro',
                                    },
                                },
                                {
                                    element: '.extra-menu',
                                    popover: {
                                        title: 'Controls',
                                        description:
                                            '<p>Fine tune your experience with these extra tools</p><br></br>' +
                                            '<ul>\n' +
                                            '  <li><strong>Zoom in/out</strong></li>' +
                                            '  <li><strong>Rearrange blocks hierarchically</strong></li>' +
                                            '  <li><strong>Where is this action called / What does it call</strong></li>' +
                                            '  <li><strong>Test your actions</strong></li>' +
                                            '</ul><br>',
                                    },
                                },
                                {
                                    element: 'codex-top-right-navigation div[data-name="application-logs"]',
                                    popover: {
                                        title: 'Application logs',
                                        description:
                                            '<p>You can use the application logs to debug faster and gain clear insights into what’s happening under the hood. <br></br>Logs provide real-time information about system events, errors, and processes, helping you quickly identify and resolve issues. <br></br>By analyzing logs, you can track API calls, database interactions, and performance bottlenecks, making troubleshooting more efficient.</p>',
                                    },
                                },
                                {
                                    element: 'codex-top-right-navigation div[data-name="audit-logs"]',
                                    popover: {
                                        title: 'Audit logs',
                                        description:
                                            '<p>Audit logs help you track and monitor all critical actions within the system, providing a clear record of who did what and when.<br></br> They enhance security, ensure compliance, and make it easier to investigate issues by offering a transparent view of user activities and system changes.<br></br> With audit logs, you can quickly detect unauthorized access, identify anomalies, and maintain accountability across your application.</p>',
                                    },
                                },
                            ],
                        });
                        setTimeout(() => {
                            driverObj.drive();
                        }, 1000);
                    }
                })
            ),
        { dispatch: false }
    );
}
