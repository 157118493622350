<ng-container [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-label>{{ label ? label : ('v2.action.argument.template.select' | translate) }}</mat-label>
        <input type="text" matInput formControlName="template" autocomplete="disabled" [matAutocomplete]="auto" />
        <mat-autocomplete class="toolbar-autocomplete" #auto="matAutocomplete" [displayWith]="displayTemplate">
            <mat-option *ngFor="let template of templates | async" (click)="onChange(template)" [value]="template">
                <mat-icon>{{ template.iconName }}</mat-icon>
                <span>{{ template.name }} ({{ template.languageCode }})</span> <br />
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix (click)="clearTemplate()" *ngIf="!!(this.currentTemplateFound | async)">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</ng-container>
<mat-error *ngIf="this.required && !(this.currentTemplateFound | async)"
    >{{ 'v2.action.argument.template.select.not.found' | translate }}
</mat-error>
