import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import {
    getPartPositioningByNumber,
    Part,
    ScreenType,
    selectBasedOnPartPositioning,
    TemplateEditorFacade,
} from '@backoffice/data-access/editor';
import { NgxFloatUiContentComponent, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-size-position-title',
    templateUrl: './size-position-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class SizePositionTitleComponent implements OnDestroy, AfterViewInit {
    @Input()
    part: Part;

    @Input()
    set viewType(viewType: number) {
        this._viewType.next(viewType);
    }

    public _viewType: BehaviorSubject<number> = new BehaviorSubject(0);

    @Output()
    changeSizePosition: EventEmitter<any> = new EventEmitter<any>();

    basedOnPartPositioning$: Observable<{
        type: string;
        icon: string;
        isCurrentScreenType: boolean;
    }> = combineLatest([this.templateEditorFacade.selectedPart, this._viewType]).pipe(
        filter(([selectedPart, viewType]) => !!selectedPart),
        map(([selectedPart, viewType]) => {
            const type = selectBasedOnPartPositioning(viewType, selectedPart.positions);
            const icon = this.getBasedOnPartPositioningIcon(type);
            return {
                type,
                icon,
                isCurrentScreenType: type === ScreenType[viewType],
            };
        })
    );

    @Output()
    updateSelectedViewType: EventEmitter<{}> = new EventEmitter<any>();

    @ViewChild('screenTypeInheritPopover') popperContent: NgxFloatUiContentComponent;

    protected readonly ScreenType = ScreenType;

    subscriptions: Subscription = new Subscription();

    constructor(private templateEditorFacade: TemplateEditorFacade) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => initFlowbite());
    }

    getBasedOnPartPositioningIcon(partPositioning: string) {
        if (partPositioning === 'SMALL') {
            return 'smartphone';
        } else if (partPositioning === 'MEDIUM') {
            return 'tablet_android';
        } else if (partPositioning === 'LARGE') {
            return 'tablet';
        } else if (partPositioning === 'EXTRA_LARGE') {
            return 'laptop';
        } else if (partPositioning === 'EXTRA_EXTRA_LARGE') {
            return 'desktop_windows';
        } else if (partPositioning === 'ALL_SCREENS') {
            return 'devices';
        }
    }

    setSelectedViewType(viewType: number) {
        this.updateSelectedViewType.emit({ selectedViewType: viewType });
        document.getElementById('screenSizeDropDown').classList.add('hidden');
        setTimeout(() => initFlowbite());
    }

    onResetStyle() {
        this.subscriptions.add(
            this.basedOnPartPositioning$.pipe(take(1)).subscribe(basedOnPartPositioning => {
                const positionIndex = this.part.positions.findIndex(
                    partPosition => partPosition.screenType === basedOnPartPositioning.type
                );
                this.part.positions.splice(positionIndex, 1);
                this.changeSizePosition.emit({ part: this.part });
            })
        );
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly environment = backofficeEnvironment;
    protected readonly getPartPositioningByNumber = getPartPositioningByNumber;
}
