import { Component, Input, OnInit } from '@angular/core';
import { BillingPackage } from '@billing/dto/billingpackage';
import { map } from 'rxjs/operators';
import { BillingFacade } from '@billing/facade/billing.facade';

@Component({
    selector: 'codex-application-usage',
    templateUrl: './application-usage.component.html',
    styleUrls: ['./application-usage.component.scss'],
    standalone: false,
})
export class ApplicationUsageComponent implements OnInit {
    public math: Math = Math;

    @Input()
    public billingPackage: BillingPackage;

    @Input()
    public companyId: string;

    constructor(private billingFacade: BillingFacade) {}

    ngOnInit(): void {}

    initBuyMoreAICredits() {
        this.billingFacade
            .updateAiCredits(window.location.href, this.companyId)
            .pipe(map(result => result.redirectUrl))
            .subscribe(redirectUrl => {
                window.location.href = redirectUrl;
            });
    }
}
