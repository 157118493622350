import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Role } from '@usermanagement/models/role.model';
import { Group } from '@usermanagement/models/group.model';
import { RightDto } from '../../../../../../../data-access/editor/src/lib/right/dto/right.dto';
import { GroupDto } from '../../../../../../../data-access/editor/src/lib/group/dto/group.dto';
import { EditorFacade } from '@backoffice/data-access/editor';
import { RightEditorFacade } from '../../../../../../../data-access/editor/src/lib/right/store/facades/right-editor.facade';

@Component({
    selector: 'codex-edit-group-form',
    templateUrl: './edit-group-form.component.html',
    styleUrls: ['./edit-group-form.component.scss'],
    standalone: false,
})
export class EditGroupFormComponent implements OnInit {
    readonly selectedRolesIdentifier = 'selectedRoles';
    readonly availableRolesIdentifier = 'availableRoles';

    form: FormGroup;

    @Input() availableRoles: RightDto[] = [];

    @Input() selectedRoles: RightDto[] = [];

    @Input() selectedGroup: GroupDto;

    @Output() roleAssigned = new EventEmitter<RightDto>();

    @Output() roleRemoved = new EventEmitter<RightDto>();

    @Output() save = new EventEmitter<GroupDto>();

    @Output() cancel = new EventEmitter<void>();

    get nameControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.form.get('description') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private rightFacade: RightEditorFacade
    ) {}

    handleCreateRightClicked() {
        this.rightFacade.create().subscribe();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [this.selectedGroup.name, [Validators.required]],
            description: [this.selectedGroup.description],
        });
    }

    drop(event: CdkDragDrop<RightDto[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            const { previousContainer, previousIndex } = event;
            const { data } = previousContainer;
            const role: RightDto = data[previousIndex];
            if (event.previousContainer.id === this.availableRolesIdentifier) {
                this.roleAssigned.emit(role);
            } else {
                this.roleRemoved.emit(role);
            }

            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    onCancelClicked(): void {
        this.cancel.emit();
    }

    onSaveClicked(): void {
        this.save.emit({
            ...this.selectedGroup,
            ...this.form.value,
        });
    }
}
