<ng-container>
    <h1 class="grow !text-xl pt-1 !mb-2">API Security</h1>
    <label class="toggle-line">
        Enable API Security
        <input type="checkbox" class="peer hidden" [(ngModel)]="companySecurity.apiAccessEnabled" (change)="onCompanySecurityChanged()" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>

    <ng-container *ngIf="companySecurity.apiAccessEnabled">
        <h2 class="grow !text-xl pt-1 !mb-2">Retrieve an accesstoken</h2>
        <p class="text-white">Retrieve an access token to authenticate in the following way:</p>
        <codex-select-search
            [items]="[
                { label: 'Development', value: 'DEV' },
                { label: 'Test', value: 'TEST' },
                { label: 'Accept', value: 'ACCEPT' },
                { label: 'Production', value: 'PRODUCTION' },
            ]"
            [showSearch]="false"
            [value]="this.chosenEnvironment"
            [placeHolder]="'Choose environment'"
            (select)="onChangeEnvironment($event)"></codex-select-search>
        <ng-container *ngIf="this.chosenEnvironment === 'DEV'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }} login{{
                        company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : ''
                    }}
                    .co-dex.eu/auth/realms/dev-{{ company.id }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/dev-' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('DEV')">Show secret</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}
                        login{{ company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : '' }}
                        .nocode-x.com/auth/realms/dev-{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************' }}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.chosenEnvironment === 'TEST'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }} login{{
                        company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : ''
                    }}
                    .co-dex.eu/auth/realms/test-{{ company.id }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/test-' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('TEST')">Show secret</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}
                        login{{ company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : '' }}
                        .nocode-x.com/auth/realms/test-{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************' }}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="this.chosenEnvironment === 'ACCEPT'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }} login{{
                        company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : ''
                    }}
                    .co-dex.eu/auth/realms/accept-{{ company.id }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/accept-' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('ACCEPT')">Show secret</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}
                        login{{ company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : '' }}
                        .nocode-x.com/auth/realms/accept-{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************' }}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="this.chosenEnvironment === 'PRODUCTION'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }} login{{
                        company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : ''
                    }}
                    .co-dex.eu/auth/realms/{{ company.id }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('PRODUCTION')">Show secret</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}
                        login{{ company.idpCode && company.idpCode !== '1' ? '-' + company.idpCode : '' }}
                        .nocode-x.com/auth/realms/{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************' }}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <hr class="seperator" />

    <ng-container *ngIf="companySecurity.apiAccessEnabled">
        <h2 class="grow !text-xl pt-1 !mb-2">Integrating Front end Authentication</h2>
        <p class="text-white mb-2">Securely authenticate third-party front end applications using the built-in authentication system.</p>
        <codex-select-search
            [items]="[{ label: 'React Single Page Applications (SPA)', value: 'REACT_SPA' }]"
            [showSearch]="false"
            [value]="this.chosenTechIntegration"
            [placeHolder]="'Choose technology'"
            (select)="onChangeIntegrationTech($event)"></codex-select-search>
        <codex-select-search
            [items]="[
                { label: 'Development', value: 'DEV' },
                { label: 'Test', value: 'TEST' },
                { label: 'Accept', value: 'ACCEPT' },
                { label: 'Production', value: 'PRODUCTION' },
            ]"
            [showSearch]="false"
            [value]="this.chosenEnvironmentIntegration"
            [placeHolder]="'Choose environment'"
            (select)="onChangeIntegrationEnvironment($event)"></codex-select-search>
        <ng-container *ngIf="this.chosenTechIntegration === 'REACT_SPA'">
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Start by installing OIDC SPA (More info: https://docs.oidc-spa.dev/):</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" [cdkCopyToClipboard]="reactSpaInstall1">Copy</button>
                    </div>
                </div>
                <pre id="react-spa-install" class="text-white text-left whitespace-pre">
                    <code [textContent]="reactSpaInstall1"></code>
                </pre>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Initialize OIDC SPA:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" [cdkCopyToClipboard]="reactSpaInstall2">Copy</button>
                    </div>
                </div>
                <pre id="react-spa-install-2" class="text-white text-left whitespace-pre">
                    <code [textContent]="reactSpaInstall2"></code>
                </pre>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Check if user is logged in:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" [cdkCopyToClipboard]="reactSpaInstall3">Copy</button>
                    </div>
                </div>
                <pre id="react-spa-install-3" class="text-white text-left whitespace-pre">
                    <code [textContent]="reactSpaInstall3"></code>
                </pre>
            </div>
        </ng-container>
    </ng-container>

    <hr class="seperator" />
    <h1 class="grow !text-xl pt-1 !mb-2">Configuration</h1>

    <float-ui-content #directRegistrationInformationPopover>
        <div class="popover">
            The URL where users can directly register for an account without going through the usual login process. Use this to provide a
            dedicated registration page.
        </div>
    </float-ui-content>

    <label
        class="small-input-label"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="redirectUriInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.RIGHT">
        Direct registration links
        <svg class="icon icon-button" aria-hidden="true" focusable="false">
            <use href="#help" class="ui-element"></use>
        </svg>
    </label>
    <ul class="url-list">
        <li>
            {{ devDirectRegistrationLink }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="devDirectRegistrationLink">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            {{ testDirectRegistrationLink }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="testDirectRegistrationLink">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            {{ acceptDirectRegistrationLink }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="acceptDirectRegistrationLink">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            {{ prdDirectRegistrationLink }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="prdDirectRegistrationLink">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
    </ul>

    <label
        class="small-input-label mt-2"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="issuerUriInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.RIGHT">
        Issuer Uri
        <svg class="icon icon-button" aria-hidden="true" focusable="false">
            <use href="#help" class="ui-element"></use>
        </svg>
    </label>
    <ul class="url-list">
        <li>
            {{ devIssuerUri }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="devIssuerUri">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            {{ testIssuerUri }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="testIssuerUri">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            {{ acceptIssuerUri }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="acceptIssuerUri">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            {{ prdIssuerUri }}
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="prdIssuerUri">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
    </ul>

    <label
        class="small-input-label mt-2"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="clientIdInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.RIGHT">
        Client Id
        <svg class="icon icon-button" aria-hidden="true" focusable="false">
            <use href="#help" class="ui-element"></use>
        </svg>
    </label>
    <ul class="url-list">
        <li>
            nocodex-broker
            <svg class="icon icon-button" [matTooltip]="'Copy'" [cdkCopyToClipboard]="'nocodex-broker'">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
    </ul>

    <float-ui-content #clientIdInformationPopover>
        <div class="popover">
            Specifies the unique identifier assigned to a client application in NoCode-X. The client ID is used to authenticate and
            authorize the application, ensuring that only registered clients can access protected resources.
        </div>
    </float-ui-content>

    <float-ui-content #issuerUriInformationPopover>
        <div class="popover">
            Specifies the base URL of the NoCode-X server that issued the authentication tokens. The issuer URI is used to validate and
            verify tokens, ensuring that they originate from a trusted authentication provider and preventing unauthorized access.
        </div>
    </float-ui-content>

    <float-ui-content #redirectUriInformationPopover>
        <div class="popover">
            Specifies the allowed URLs where users can be redirected after authentication. Only the registered URIs are trusted, ensuring
            the security of your authentication flow.
        </div>
    </float-ui-content>

    <float-ui-content #applicationUriInformationPopover>
        <div class="popover">
            The base URL of your frontend application. This is where NoCode-X will send users after authentication or authorization
            processes are completed.
        </div>
    </float-ui-content>

    <label
        class="mt-2 small-input-label"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="redirectUriInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.RIGHT"
        >Redirect Uri's (DEV)
        <svg class="icon icon-button" aria-hidden="true" focusable="false">
            <use href="#help" class="ui-element"></use>
        </svg>
    </label>
    <ng-container *ngFor="let redirectUri of companySecurity.devRedirectUrls; index as i; trackBy: identifyRedirectUrl">
        <div class="flex flex-row gap-1 flex-auto items-center">
            <input
                [attr.id]="'redirect-uri-dev-' + i"
                class="small-input"
                [attr.name]="'redirect-uri-dev-' + i"
                data-name="form-url"
                [(ngModel)]="companySecurity.devRedirectUrls[i]"
                (ngModelChange)="this.onCompanySecurityChanged()"
                autocomplete="off" />
            <button
                *ngIf="i + 1 === companySecurity.devRedirectUrls.length"
                class="primary-button button-extra-small mb-2"
                data-name="create-new-action"
                (click)="addRedirectUrl('DEV')"
                [matTooltip]="'Create redirect uri'"
                type="button">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </ng-container>
    <label
        class="small-input-label"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="redirectUriInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.RIGHT"
        >Redirect Uri's (TEST)
        <svg class="icon icon-button" aria-hidden="true" focusable="false">
            <use href="#help" class="ui-element"></use>
        </svg>
    </label>
    <ng-container *ngFor="let redirectUri of companySecurity.testRedirectUrls; index as i; trackBy: identifyRedirectUrl">
        <div class="flex flex-row gap-1 flex-auto items-center">
            <input
                [attr.id]="'redirect-uri-test-' + i"
                class="small-input"
                [attr.name]="'redirect-uri-test-' + i"
                data-name="form-url"
                [(ngModel)]="companySecurity.testRedirectUrls[i]"
                (ngModelChange)="this.onCompanySecurityChanged()"
                autocomplete="off" />
            <button
                *ngIf="i + 1 === companySecurity.testRedirectUrls.length"
                class="primary-button button-extra-small mb-2"
                data-name="create-new-action"
                (click)="addRedirectUrl('TEST')"
                [matTooltip]="'Create redirect uri'"
                type="button">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </ng-container>
    <label
        class="small-input-label"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="redirectUriInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.RIGHT"
        >Redirect Uri's (ACCEPT)
        <svg class="icon icon-button" aria-hidden="true" focusable="false">
            <use href="#help" class="ui-element"></use>
        </svg>
    </label>
    <ng-container *ngFor="let redirectUri of companySecurity.acceptRedirectUrls; index as i; trackBy: identifyRedirectUrl">
        <div class="flex flex-row gap-1 flex-auto items-center">
            <input
                [attr.id]="'redirect-uri-accept-' + i"
                class="small-input"
                [attr.name]="'redirect-uri-accept-' + i"
                data-name="form-url"
                [(ngModel)]="companySecurity.acceptRedirectUrls[i]"
                (ngModelChange)="this.onCompanySecurityChanged()"
                autocomplete="off" />
            <button
                *ngIf="i + 1 === companySecurity.acceptRedirectUrls.length"
                class="primary-button button-extra-small mb-2"
                data-name="create-new-action"
                (click)="addRedirectUrl('ACCEPT')"
                [matTooltip]="'Create redirect uri'"
                type="button">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </ng-container>
    <label
        class="small-input-label"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="redirectUriInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.RIGHT"
        >Redirect Uri's (PRODUCTION)
        <svg class="icon icon-button" aria-hidden="true" focusable="false">
            <use href="#help" class="ui-element"></use>
        </svg>
    </label>
    <ng-container *ngFor="let redirectUri of companySecurity.productionRedirectUrls; index as i; trackBy: identifyRedirectUrl">
        <div class="flex flex-row gap-1 flex-auto items-center">
            <input
                [attr.id]="'redirect-uri-production-' + i"
                class="small-input"
                [attr.name]="'redirect-uri-production-' + i"
                data-name="form-url"
                [(ngModel)]="companySecurity.productionRedirectUrls[i]"
                (ngModelChange)="this.onCompanySecurityChanged()"
                autocomplete="off" />
            <button
                *ngIf="i + 1 === companySecurity.productionRedirectUrls.length"
                class="primary-button button-extra-small mb-2"
                data-name="create-new-action"
                (click)="addRedirectUrl('PRODUCTION')"
                [matTooltip]="'Create redirect uri'"
                type="button">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </ng-container>
</ng-container>
