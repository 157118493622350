<form [formGroup]="formGroup" novalidate cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="1 1 auto">
            <app-template-picker
                [label]="'v2.application.edit.error.page' | translate"
                [value]="application.errorTemplateId"
                [addQuotesToValue]="false"
                (valueUpdated)="onErrorTemplateChanged($event)"
                (openTab)="onOpenTemplate($event)"></app-template-picker>
            <app-template-picker
                [label]="'v2.application.edit.notfound.page' | translate"
                [value]="application.notFoundTemplateId"
                [addQuotesToValue]="false"
                (valueUpdated)="onNotFoundTemplateChanged($event)"
                (openTab)="onOpenTemplate($event)"></app-template-picker>
            <app-template-picker
                [label]="'v2.application.edit.unauthorized.page' | translate"
                [value]="application.unAuthorizedTemplateId"
                [addQuotesToValue]="false"
                (valueUpdated)="onUnAuthorizedTemplateChanged($event)"
                (openTab)="onOpenTemplate($event)"></app-template-picker>
            <hr class="seperator" />
            <div class="flex gap-1 justify-end mt-2 mb-2">
                <button
                    class="h-9"
                    mat-button
                    mat-raised-button
                    data-name="save"
                    type="submit"
                    color="primary"
                    (click)="onUpdateApplication()">
                    <mat-icon>save</mat-icon>
                    {{ 'general.save' | translate }}
                </button>
                <button class="h-9" data-name="cancel" type="button" (click)="onCancel()" mat-button mat-raised-button>
                    <mat-icon>cancel</mat-icon>
                    {{ 'general.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</form>
