import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { catchError, concatMap, debounceTime, exhaustMap, filter, map, mergeMap, retry, switchMap, take, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { OverviewTemplateDto, Template, toFilter } from '@backoffice/data-access/editor';
import { TemplateEditorFacade } from '../facades/template-editor.facade';
import { TemplateService } from '../../services/template/template.service';
import {
    clearTemplateDialogFilter,
    createTemplateSuccess,
    deleteTemplate,
    deleteTemplates,
    deleteTemplatesSuccess,
    deleteTemplateSuccess,
    fetchTemplateError,
    fetchTemplateSuccess,
    findTemplate,
    loadTemplateDialogData,
    loadTemplateDialogSuccess,
    refreshTemplate,
    refreshTemplateSuccess,
    templateDialogFacetsChanged,
    templateDialogFilterPluginsChanged,
    templateDialogPaginationChanged,
    templateDialogSearchTermChanged,
    updateTemplate,
    updateTemplateSuccess,
} from '../actions/templates-editor.actions';
import { templateEditorSelectors } from '../selectors/template-editor.selectors';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import { backofficeEnvironment } from '@shared/environment';
import { forkJoin, of } from 'rxjs';
import { Page } from '@shared/data-access';
import {
    refreshUserAchievements,
    requestUserAchievements,
    selectApplicationSuccess,
} from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { createActionSuccess } from '../actions/action-editor.actions';
import { driver } from 'driver.js';
import { AchievementsService } from '@core/services/achievements.service';

@Injectable()
export class TemplateEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: TemplateEditorFacade,
        private readonly templateService: TemplateService,
        private readonly achievementsService: AchievementsService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearTemplateDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplates),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.templateService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteTemplatesSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplatesSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'template' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplate),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.templateService.delete(id, companyId, applicationId).pipe(map(() => deleteTemplateSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findTemplate),
            concatLatestFrom(({ id, templateApplicationId, languageCode }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(templateEditorSelectors.byId(id)),
            ]),
            filter(([_template, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id, templateApplicationId, languageCode }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail) {
                    return of(fetchTemplateSuccess({ template: detail }));
                } else {
                    return this.templateService
                        .findById(id, companyId, templateApplicationId ? templateApplicationId : applicationId, languageCode)
                        .pipe(
                            tap(result => {
                                result.yPan = 0;
                                result.xPan = 0;
                                if (result.languageSyncStatus !== 'IDLE') {
                                    throw 'SYNCING';
                                }
                            }),
                            map(result => {
                                result.backEndUpdate = false;
                                return fetchTemplateSuccess({ template: result });
                            }),
                            catchError(errors => {
                                if (errors !== 'SYNCING') {
                                    return of(fetchTemplateError({ template: Template.createDeleted(id) }));
                                } else {
                                    throw errors;
                                }
                            }),
                            retry({ count: 10, delay: 5000 })
                        );
                }
            })
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteTemplatesSuccess,
                loadTemplateDialogData,
                templateDialogPaginationChanged,
                templateDialogSearchTermChanged,
                templateDialogFacetsChanged,
                templateDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.templateService.find(companyId, applicationId, {
                    page: page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewTemplateDto>) => loadTemplateDialogSuccess({ data }))
        )
    );

    refresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(refreshTemplate),
            concatLatestFrom(({ id }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([_, company, application]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.templateService.findById(id, companyId, applicationId).pipe(
                    map(result => {
                        result.backEndUpdate = true;
                        return refreshTemplateSuccess({ template: result });
                    })
                )
            )
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTemplate),
            debounceTime(backofficeEnvironment.autosavedebounce),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([{ template }, company, application]) => template.isValid() && !!company && !!application),
            concatMap(([{ template }, { id: companyId }, { id: applicationId }]) =>
                this.templateService.update(template, companyId, applicationId).pipe(
                    exhaustMap(() => this.templateService.findById(template.id, companyId, applicationId)),
                    map(fetchedTemplate => updateTemplateSuccess({ template: fetchedTemplate }))
                )
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplateSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'template' }))
        )
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createTemplateSuccess),
            map(({ id }) => registerTab({ definition: { type: 'template', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTemplateSuccess, fetchTemplateSuccess),
            map(({ template }) => {
                const { id: typeId, name, iconName: icon, languageCode } = template;
                return updateTab({ definition: { type: 'template', typeId, name: name + ' (' + languageCode + ')', icon } });
            })
        )
    );

    activateTemplateOnboarding$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createTemplateSuccess),
                concatLatestFrom(() => [this.appFacade.user, this.appFacade.context]),
                tap(([{ id }, userContext, context]) => {
                    let genericOnboardingAchievement = userContext.achievements?.find(
                        achievement => achievement.code === 'TEMPLATE_ONBOARDING'
                    );
                    if (!genericOnboardingAchievement) {
                        const driverObj = driver({
                            showProgress: true,
                            onDestroyed: () => {
                                this.achievementsService
                                    .createAchievement(userContext.id, 'TEMPLATE_ONBOARDING')
                                    .pipe(take(1))
                                    .subscribe(() => {
                                        this.store.dispatch(refreshUserAchievements({ info: userContext }));
                                    });
                            },
                            steps: [
                                {
                                    popover: {
                                        title: 'Aah, your very first page!',
                                        description:
                                            '<img src="images/theme/guide.gif" class="intro" width="300" style="height: 300px; margin:auto;"/> ' +
                                            "<p>Ready? Let’s dive in and start our journey — You'll see soon that the possibilities are endless!</p>",
                                        popoverClass: 'driver-intro',
                                    },
                                },
                                {
                                    element: 'template-edit .left-drawer-block:first-child',
                                    popover: {
                                        title: 'Element selector',
                                        description:
                                            "<p>Choose from a list of available UI elements. We've got quite a lot of elements. Pick your poison</p>",
                                    },
                                },
                                {
                                    element: '#filter_parttypes',
                                    popover: {
                                        title: 'Element filter',
                                        description:
                                            '<p>Find exactly what you are looking for by filtering the list of available elements.</p>',
                                    },
                                },
                                {
                                    element: 'template-part-picker-items .draggables-grid button:first-of-type',
                                    popover: {
                                        title: 'Element',
                                        description:
                                            '<video src="images/theme/build_templates.mp4" loop="" preload="metadata" muted="muted" controlslist="nodownload" autoplay></video>' +
                                            '<p>If you found the UI element you need, just drag & drop it into place & start configuring the details!</p>',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .screensize-selector',
                                    popover: {
                                        title: 'Design for all screensizes',
                                        description:
                                            '<img src="images/theme/design_screensize.gif" style="margin:auto;"/> ' +
                                            '<p>You can design each screensize by just toggling them on/off</p>',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .view-controls',
                                    popover: {
                                        title: 'Center/Grid/Borders',
                                        description:
                                            '<img src="images/theme/center_tools.gif" style="margin:auto;"/> ' +
                                            '<p>Fine tune your experience with these extra tools</p><br></br>' +
                                            '<ul>\n' +
                                            '  <li><strong>Center:</strong> If you are lost, click the center button to hop back to your page</li>' +
                                            '  <li><strong>Grid: </strong> Need to design according to a grid: No problem!</li>' +
                                            '  <li><strong>Borders</strong> Toggle borders on/off on each UI element</li>' +
                                            '</ul><br>',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .embed-controls',
                                    popover: {
                                        title: 'Embed your template!',
                                        description:
                                            'Every page/ component you build with NoCode-X can be embedded in any web based application. Just copy paste the embed code & your done!',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .language-controls',
                                    popover: {
                                        title: 'Multi-language by nature',
                                        description:
                                            'NoCode-x Offers multi-language support out of the box. Just create a new template in another language & translate using AI!',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .hierarchy-controls',
                                    popover: {
                                        title: 'Template hierarchy',
                                        description:
                                            '<img src="images/theme/template_hierarchy.gif" style="margin:auto;"/> ' +
                                            'Your templates can be arranged in a hierarchy structure, where children inherit the properties/UI elements of their parents. ' +
                                            'This is very handy when you are building pages that have recurring elements. ',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .authentication-controls',
                                    popover: {
                                        title: 'Authentication/Authorization',
                                        description:
                                            'By default all your pages are protected by Authentication/Authorization. Switch it off here or create some authorization rules for even stricter security.',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .settings-controls',
                                    popover: {
                                        title: 'Template settings',
                                        description:
                                            '<img src="images/theme/template_settings.gif" style="margin:auto;"/> ' +
                                            'Adjust some basic settings on your templates such as: name, description, parameters & style!',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .toolbox-controls',
                                    popover: {
                                        title: 'Toolbox',
                                        description:
                                            '<img src="images/theme/template_tools.gif" style="margin:auto;"/> ' +
                                            '<p>2 Tools for you</p><br></br>' +
                                            '<ul>\n' +
                                            '<li><strong>Panning:</strong> Change the placement of the canvas by dragging & dropping.</li>' +
                                            '<li><strong>Selection: </strong> Select multiple UI elements & drag/drop them into place</li>' +
                                            '</ul><br><br>' +
                                            'Tip: Check out the handy fastkeys to switch between both.' +
                                            '',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .play',
                                    popover: {
                                        title: 'Test your page',
                                        description: 'Quickly test out your creations by using this button!',
                                    },
                                },
                            ],
                        });
                        setTimeout(() => {
                            driverObj.drive();
                        }, 100);
                    }
                })
            ),
        { dispatch: false }
    );
}
