<ng-container *ngIf="this.selectedCompany">
    <div class="workspace-selector" (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <div class="workspace-selector-container">
            <ngx-avatars size="28" [round]="false" cornerRadius="5" [name]="selectedCompany.companyName"></ngx-avatars>
            <div class="workspace-name-container">
                <span class="workspace-name">{{ selectedCompany.companyName }}</span>
                <div *ngIf="!isOpen" class="material-icons">expand_more</div>
                <div *ngIf="isOpen" class="material-icons">expand_less</div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOpen"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="isOpen = false">
        <mat-card data-name="recent-companies" class="workspace-selector-last-used">
            <mat-card-content>
                <mat-card-header>
                    <mat-card-title>
                        <span class="recent-workspaces-title">{{ 'workspace.selector.recent.workspaces' | translate }}</span>
                        <span (click)="onAllCompaniesClicked()" class="see-all-workspaces simple-link"
                            >{{ 'workspace.selector.all.workspaces' | translate }} ></span
                        >
                    </mat-card-title>
                </mat-card-header>
                <ul class="workspace-list">
                    <ng-container *ngFor="let company of recentCompanies">
                        <li (click)="onCompanySelected(company)" [class.selected]="company.id === selectedCompany.id">
                            <ngx-avatars
                                size="35"
                                [round]="false"
                                cornerRadius="5"
                                [name]="company.companyName"
                                matListItemIcon></ngx-avatars>
                            <div class="workspace-name">{{ company.companyName }}</div>
                            <button
                                (click)="onCompanySelected(company); onEditWorkspace(company.id); $event.stopPropagation()"
                                class="primary-button button-extra-small">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </li>
                    </ng-container>
                </ul>

                <button class="mt-2 text-button ml-[23px]" type="button" (click)="onAddCompany()">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#plus" class="ui-element"></use>
                    </svg>
                    {{ 'workspace.selector.add.company' | translate }}
                </button>
            </mat-card-content>
        </mat-card>
    </ng-template>
</ng-container>
