<div class="p-2 mb-2 flex justify-items-stretch justify-start">
    <div class="grow flex flex-row gap-3 items-center">
        <h1 class="mb-2">{{ 'v2.hub.overview.title' | translate }}</h1>
        <div class="grow">
            <div
                *ngIf="availableButtons | async as buttons"
                class="button-group !mb-0 max-w-[200px]"
                data-name="public-private-hub"
                role="group">
                <button
                    *ngFor="let button of buttons"
                    [matTooltip]="button.text | translate"
                    type="button"
                    class="button-group-button-left"
                    [ngClass]="hubType === button.value ? 'button-group-button-selected' : ''"
                    (click)="handleHubTypeChanged(button.value)">
                    <mat-icon>{{ button.icon }}</mat-icon>
                </button>
            </div>
        </div>
        <button class="primary-button button-large" (click)="onPublish()" data-name="register-application" type="button">
            <mat-icon>add_circle</mat-icon>
            {{ 'v2.hub.overview.publish' | translate }}
        </button>
    </div>
</div>
<div class="p-2 mt-1 flex-col flex">
    <search-form
        (search)="handleSearchTermChanged($event)"
        [searchPlaceHolder]="'v2.hub.overview.search.data'"
        class="w-full"
        data-name="hub-search">
    </search-form>

    <div *ngIf="page$ | async as page">
        <ng-container *ngIf="page.content && page.content.length > 0; else noResult">
            <div class="overview-items-full">
                <div (click)="handleProductClicked(product.id)" *ngFor="let product of page.content" data-name="product">
                    <codex-product-overview-row
                        [data]="product"
                        (installedClicked)="handleInstall(product)"
                        (uninstallClicked)="handleUninstall(product.id)">
                    </codex-product-overview-row>
                </div>
            </div>
            <mat-paginator
                (page)="handlePaginationChanged($event)"
                [length]="page.count"
                [pageSizeOptions]="[5, 10, 25, 100]"
                [pageSize]="10"
                aria-label="Select page">
            </mat-paginator>
        </ng-container>
        <ng-template #noResult>
            <div class="pt-2 flex-auto mb-5 gap-1 flex flex-col scrollbar scrollbar-primary !overflow-x-visible self-center">
                <div class="flex-col flex justify-center items-center">
                    <img class="w-[350px] h-[150px] object-cover" src="/src/images/theme/no_results_found.gif" />
                    <h1 class="text-center !mb-2">We couldn't find any available plugins for your search.</h1>
                    <p class="!mb-8 text-white">
                        Maybe you can build it yourself & make others happy with your plugin. Or maybe you can ask our community at Discord.
                    </p>
                    <a href="https://discord.gg/36Ju8sjYvy"
                        ><img class="grow shrink object-contain w-[150px]" src="/src/images/theme/discord-logo-white.png"
                    /></a>
                </div>
            </div>
        </ng-template>
    </div>
</div>
