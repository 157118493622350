import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { BillingFacade } from '../../facade/billing.facade';
import { BillingEntry } from '../../dto/billingentry';
import { BillingPackage } from '../../dto/billingpackage';
import { LoggerService } from '@backoffice/utils';
import { backofficeEnvironment } from '@shared/environment';
import { ChoosePackageComponent } from '../../../add-company/components/choose-package/choose-package.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'codex-application-billing',
    templateUrl: './application-billing.component.html',
    styleUrls: ['./application-billing.component.scss'],
    standalone: false,
})
export class ApplicationBillingComponent implements OnInit {
    form: FormGroup;

    applications$: Observable<ApplicationDto[]>;

    billingEntries$: Observable<BillingEntry[]>;

    billingPackage$: Observable<BillingPackage>;

    environment = backofficeEnvironment;

    window = window;

    @Input()
    companyId: string;

    constructor(
        private fb: FormBuilder,
        private billingFacade: BillingFacade,
        private dialog: MatDialog,
        private readonly logger: LoggerService
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.initApplications();
        this.initBillingPackage();
    }

    initForm() {
        this.form = this.fb.group({
            applicationId: [Validators.required],
            billingView: ['MONTH', Validators.required],
        });
    }

    initApplications() {
        this.applications$ = this.billingFacade.getApplicationsByCompanyId(this.companyId).pipe(map(page => page.content));
    }

    initUpdateBillingDetails() {
        this.billingFacade
            .updateBillingDetails(this.companyId, window.location.href)
            .pipe(map(result => result.redirectUrl))
            .subscribe(redirectUrl => {
                window.location.href = redirectUrl;
            });
    }

    initBillingPackage() {
        this.billingPackage$ = this.billingFacade.getBillingPackageByCompanyId(this.companyId);
    }

    initApplicationBillingEntries() {
        this.form.get('applicationId').valueChanges.subscribe(test => {
            this.logger.info(test);
        });

        this.billingEntries$ = combineLatest([
            this.form.get('applicationId').valueChanges.pipe(startWith(null)),
            this.form.get('billingView').valueChanges.pipe(startWith('MONTH')),
        ]).pipe(
            switchMap(([applicationId, billingView]) => {
                if (applicationId) {
                    return this.billingFacade.getBillingEntriesByApplication(applicationId, billingView);
                } else {
                    return of([]);
                }
            })
        );
    }

    initChooseSubscription() {
        this.dialog.open(
            ChoosePackageComponent,
            Object.assign(
                {
                    disableClose: false,
                    data: {
                        company: this.companyId,
                        showFree: false,
                    },
                },
                backofficeEnvironment.dialogConfig.big
            )
        );
    }
}
