<h3 *ngIf="!!selectedInvocation" class="invocation-settings-title">
    <mat-icon class="invocation-icon">{{ selectedInvocation.iconName }}</mat-icon>
    <span class="grow shrink text-left">{{ selectedInvocation.name }}</span>
    <button
        class="accordion-heading-button destructive-accordion-heading-button"
        (click)="this.close.emit(); $event.stopPropagation()"
        [matTooltip]="'Close'">
        <mat-icon>clear</mat-icon>
    </button>
</h3>
<div *ngIf="!!selectedInvocation">
    <div class="invocation-config">
        <div class="alt-tabs-header-wrapper">
            <ul
                class="alt-tabs-header"
                id="default-styled-tab"
                data-tabs-toggle="#invocation-configuration-tabs"
                data-tabs-inactive-classes="alt-tabs-tab-inactive"
                data-tabs-active-classes="alt-tabs-tab-active"
                role="tablist">
                <li class="me-2" role="presentation" *ngIf="selectedInvocation.methodKey !== 'start'">
                    <button
                        class="alt-tabs-tab-button"
                        id="invocation-configuration-arguments-tab"
                        data-tabs-target="#invocation-configuration-arguments"
                        type="button"
                        role="tab"
                        aria-controls="invocation-configuration-arguments"
                        aria-selected="true"
                        (click)="reloadFlowBite()">
                        {{ 'v2.action.toolpane.invocation.arguments' | translate }}
                    </button>
                </li>
                <li class="me-2" role="presentation">
                    <button
                        class="alt-tabs-tab-button"
                        id="invocation-configuration-properties-tab"
                        data-tabs-target="#invocation-configuration-properties"
                        type="button"
                        role="tab"
                        aria-controls="invocation-configuration-properties"
                        aria-selected="false"
                        (click)="reloadFlowBite()">
                        {{ 'v2.action.toolpane.invocation.properties' | translate }}
                    </button>
                </li>
            </ul>
        </div>
        <div id="invocation-configuration-tabs">
            <div
                *ngIf="selectedInvocation.methodKey !== 'start'"
                id="invocation-configuration-arguments"
                class="hidden alt-tabs-tab-content"
                role="tabpanel"
                aria-labelledby="invocation-configuration-arguments-tab">
                <action-invocation-arguments
                    [invocation]="selectedInvocation"
                    [language]="language"
                    [action]="action"
                    [scope]="scope$ | async"
                    (invocationUpdated)="invocationUpdated.emit($event)"
                    (openTab)="this.openTab.emit($event)"
                    (calculateScope)="handleCalculateScope()">
                </action-invocation-arguments>
                <codex-action-edit-invocation-outputs
                    [invocation]="selectedInvocation"
                    [action]="action"
                    [language]="language"
                    (invocationUpdated)="invocationUpdated.emit($event)"
                    (openTab)="this.openTab.emit($event)"></codex-action-edit-invocation-outputs>
            </div>
            <div
                id="invocation-configuration-properties"
                class="hidden alt-tabs-tab-content"
                role="tabpanel"
                aria-labelledby="invocation-configuration-properties-tab">
                <codex-action-edit-invocation-properties
                    [invocation]="selectedInvocation"
                    [action]="action"
                    (invocationUpdated)="invocationUpdated.emit($event)"></codex-action-edit-invocation-properties>

                <codex-action-edit-invocation-nextinvocations
                    [invocation]="selectedInvocation"
                    [action]="action"
                    (invocationUpdated)="invocationUpdated.emit($event)"></codex-action-edit-invocation-nextinvocations>
            </div>
        </div>
    </div>
</div>
