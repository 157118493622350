<ng-container *ngIf="currentView === 'browse'">
    <codex-marketplace-overview-page
        (loadProductDetailPage)="handleLoadProductDetailPage($event)"
        (loadAddProductPage)="handleLoadAddProductPage()"></codex-marketplace-overview-page>
</ng-container>
<ng-container *ngIf="currentView === 'detail' && !!productId">
    <codex-product-detail-page [productId]="productId" (back)="currentView = 'browse'"></codex-product-detail-page>
</ng-container>
<ng-container *ngIf="currentView === 'add'">
    <codex-add-to-marketplace-page></codex-add-to-marketplace-page>
</ng-container>
