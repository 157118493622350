import { TemplateStyle } from './template-style.model';
import { TemplateActions } from './template-actions.model';
import { TemplateLanguageBundle } from './template.language.bundle.model';
import { GUIDFunctions } from '@shared/utils';
import { Type } from 'class-transformer';
import { Parameter } from '../../parameters/parameter';
import { Argument } from '../../arguments/argument';
import { ScreenType } from './screentype.enum';

export class Template {
    public id: string;
    public readonly companyId: string;
    public readonly applicationId: string;
    public readonly languageCode: string;
    public readonly type: string;
    public name: string;
    public description: string;
    public iconName: string;
    public domainName: string;
    public endpoint: string;
    public layers: Array<string>;
    public languageBundleDto: TemplateLanguageBundle;
    public style: TemplateStyle;
    public actions: TemplateActions;

    public chosenScreenType: string;

    @Type(() => Parameter)
    public parameters: Parameter[];

    public sizeX: number;
    public sizeY: number;
    public sizeXUnit: string;
    public sizeYUnit: string;

    public initialPositionX: number | null;
    public initialPositionY: number | null;
    public initialPositionXUnit: string | null;
    public initialPositionYUnit: string | null;

    public copySyncStatus: string;
    public languageSyncStatus: string;
    public inheritSyncStatus: string;
    public inheritTreeSyncStatus: string;
    public inheritFromTemplateId: string;

    public authenticatedAccess: boolean;
    public component: boolean;
    public deleted: boolean;
    public reference: string;

    public zoomLevel: number;
    public xPan: number;
    public yPan: number;
    public enableBorders: boolean;

    public backEndUpdate: boolean;

    public static hasChosenScreenType(template: Template, screenType: number) {
        let chosenScreenTypes = template.chosenScreenType.split(';');
        return chosenScreenTypes.indexOf(ScreenType[screenType]) > -1;
    }

    public static createDeleted(id: string): Template {
        const template = new Template();
        template.id = id;
        template.deleted = true;
        return template;
    }

    addLayer(layerId: string) {
        if (!!layerId) {
            this.layers.push(layerId);
        }
    }

    deleteLayer(layerId: string) {
        if (!!layerId && this.layers.indexOf(layerId) > -1) {
            this.layers.splice(this.layers.indexOf(layerId), 1);
        }
    }

    isValid(): boolean {
        return !!this.name && this.name !== '' && this.areParametersValid();
    }

    private areParametersValid(): boolean {
        let valid = true;
        this.parameters.forEach(parameter => {
            valid = valid && parameter.isValid();
        });
        return valid;
    }

    public getWidth(): string {
        if (this.sizeXUnit === 'pixels') {
            return this.sizeX + 'px';
        } else if (this.sizeXUnit === 'percentage') {
            return this.sizeX + '%';
        } else if (this.sizeXUnit === 'viewport') {
            return this.sizeX + 'vh';
        } else if (this.sizeYUnit === 'fit-content') {
            return 'fit-content';
        } else {
            return '';
        }
    }

    public getHeight(): string {
        if (this.sizeYUnit === 'pixels') {
            return this.sizeY + 'px';
        } else if (this.sizeYUnit === 'percentage') {
            return this.sizeY + '%';
        } else if (this.sizeYUnit === 'viewport') {
            return this.sizeY + 'vh';
        } else if (this.sizeYUnit === 'fit-content') {
            return 'fit-content';
        } else {
            return '';
        }
    }
}
