import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { CompanySecurityDto } from '@shared/interfaces/company-security.dto';
import { backofficeEnvironment } from '@shared/environment';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-company-edit-api-security',
    templateUrl: './company-edit-api-security.component.html',
    standalone: false,
})
export class CompanyEditApiSecurityComponent implements AfterViewInit {
    @Input()
    company: CompanyDto;

    @Input()
    companySecurity: CompanySecurityDto;

    @Input()
    apiSecret: string;

    @Output()
    companySecurityChanged: EventEmitter<CompanySecurityDto> = new EventEmitter<CompanySecurityDto>();

    @Output()
    viewSecret: EventEmitter<{ companySecurity: CompanySecurityDto; environment: string }> = new EventEmitter<{
        companySecurity: CompanySecurityDto;
        environment: string;
    }>();

    chosenEnvironment: string = 'DEV';
    chosenTechIntegration: string = 'REACT_SPA';
    chosenEnvironmentIntegration: string = 'DEV';

    reactSpaInstall1: string;
    reactSpaInstall2: string;
    reactSpaInstall3: string;

    devDirectRegistrationLink: string;
    testDirectRegistrationLink: string;
    acceptDirectRegistrationLink: string;
    prdDirectRegistrationLink: string;

    devIssuerUri: string;
    testIssuerUri: string;
    acceptIssuerUri: string;
    prdIssuerUri: string;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.setInstructions();
        this.setRegistrationLinks();
        this.setApplicationUrls();
        this.setRedirectUrls();
        this.setIssuerUris();
    }

    setApplicationUrls() {
        if (!this.companySecurity.devApplicationUrls) {
            this.companySecurity.devApplicationUrls = [''];
        }
        if (!this.companySecurity.testApplicationUrls) {
            this.companySecurity.testApplicationUrls = [''];
        }
        if (!this.companySecurity.acceptApplicationUrls) {
            this.companySecurity.acceptApplicationUrls = [''];
        }
        if (!this.companySecurity.productionApplicationUrls) {
            this.companySecurity.productionApplicationUrls = [''];
        }
    }

    setRedirectUrls() {
        if (!this.companySecurity.devRedirectUrls) {
            this.companySecurity.devRedirectUrls = [''];
        }
        if (!this.companySecurity.testRedirectUrls) {
            this.companySecurity.testRedirectUrls = [''];
        }
        if (!this.companySecurity.acceptRedirectUrls) {
            this.companySecurity.acceptRedirectUrls = [''];
        }
        if (!this.companySecurity.productionRedirectUrls) {
            this.companySecurity.productionRedirectUrls = [''];
        }
    }

    addApplicationUrl(environment: string) {
        if (environment === 'DEV') {
            this.companySecurity.devApplicationUrls.push('');
        } else if (environment === 'TEST') {
            this.companySecurity.testApplicationUrls.push('');
        } else if (environment === 'ACCEPT') {
            this.companySecurity.acceptApplicationUrls.push('');
        } else {
            this.companySecurity.productionApplicationUrls.push('');
        }
    }

    removeApplicationUrl(environment: string, urlToRemove: string) {
        if (environment === 'DEV') {
            let indexToRemove = this.companySecurity.devApplicationUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.devApplicationUrls.splice(indexToRemove, 1);
            }
        } else if (environment === 'TEST') {
            let indexToRemove = this.companySecurity.testApplicationUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.testApplicationUrls.splice(indexToRemove, 1);
            }
        } else if (environment === 'ACCEPT') {
            let indexToRemove = this.companySecurity.acceptApplicationUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.acceptApplicationUrls.splice(indexToRemove, 1);
            }
        } else {
            let indexToRemove = this.companySecurity.productionApplicationUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.productionApplicationUrls.splice(indexToRemove, 1);
            }
        }
    }

    addRedirectUrl(environment: string) {
        if (environment === 'DEV') {
            this.companySecurity.devRedirectUrls.push('');
        } else if (environment === 'TEST') {
            this.companySecurity.testRedirectUrls.push('');
        } else if (environment === 'ACCEPT') {
            this.companySecurity.acceptRedirectUrls.push('');
        } else {
            this.companySecurity.productionRedirectUrls.push('');
        }
    }

    removeRedirectUrl(environment: string, urlToRemove: string) {
        if (environment === 'DEV') {
            let indexToRemove = this.companySecurity.devRedirectUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.devRedirectUrls.splice(indexToRemove, 1);
            }
        } else if (environment === 'TEST') {
            let indexToRemove = this.companySecurity.testRedirectUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.testRedirectUrls.splice(indexToRemove, 1);
            }
        } else if (environment === 'ACCEPT') {
            let indexToRemove = this.companySecurity.acceptRedirectUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.acceptRedirectUrls.splice(indexToRemove, 1);
            }
        } else {
            let indexToRemove = this.companySecurity.productionRedirectUrls.findIndex(applicationUrl => applicationUrl === urlToRemove);
            if (indexToRemove > -1) {
                this.companySecurity.productionRedirectUrls.splice(indexToRemove, 1);
            }
        }
    }

    setRegistrationLinks() {
        this.devDirectRegistrationLink = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/dev-${this.company.id}/protocol/openid-connect/registrations?client_id=nocodex-broker&response_type=code&scope=openid&redirect_uri=https://app.nocode-x.com`;
        this.testDirectRegistrationLink = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/test-${this.company.id}/protocol/openid-connect/registrations?client_id=nocodex-broker&response_type=code&scope=openid&redirect_uri=https://app.nocode-x.com`;
        this.acceptDirectRegistrationLink = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/accept-${this.company.id}/protocol/openid-connect/registrations?client_id=nocodex-broker&response_type=code&scope=openid&redirect_uri=https://app.nocode-x.com`;
        this.prdDirectRegistrationLink = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/${this.company.id}/protocol/openid-connect/registrations?client_id=nocodex-broker&response_type=code&scope=openid&redirect_uri=https://app.nocode-x.com`;
    }

    setIssuerUris() {
        this.devIssuerUri = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/dev-${this.company.id}`;
        this.testIssuerUri = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/test-${this.company.id}`;
        this.acceptIssuerUri = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/accept-${this.company.id}`;
        this.prdIssuerUri = `https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/${this.company.id}`;
    }

    setInstructions() {
        this.reactSpaInstall1 = `
    npm install oidc-spa`;
        this.reactSpaInstall2 = `
    import { createOidc } from "oidc-spa";
    import { z } from "zod";

    const oidc = await createOidc({
        issuerUri: "https://${backofficeEnvironment.production ? '' : 'dev-'}login${this.company.idpCode && this.company.idpCode !== '1' ? '-' + this.company.idpCode : ''}.nocode-x.com/auth/realms/${this.chosenEnvironmentIntegration === 'PRODUCTION' ? this.company.id : this.chosenEnvironmentIntegration.toLowerCase() + '-' + this.company.id}",
        clientId: "nocodex-broker",
        homeUrl: "/",
        scopes: ["profile", "email"],
        extraQueryParams: () => ({
           ui_locales: "en"
        }),
        decodedIdTokenSchema: z.object({
            preferred_username: z.string(),
            name: z.string()
            email: z.string().email().optional()
        })
    });`;
        this.reactSpaInstall3 = `
    if (!oidc.isUserLoggedIn) {
    // The user is not logged in.

    // We can call login() to redirect the user to the login/register page.
    // This return a promise that never resolve.
    oidc.login({
         /**
          * If you are calling login() in the callback of a click event
          * set this to false.
          */
         doesCurrentHrefRequiresAuth: false
         /**
          * Optionally, you can add some extra parameter
          * to be added on the login url.
          * (Can also be a parameter of createOidc \`extraQueryParams: ()=> ({ ui_locales: "fr" })\`)
          */
         //extraQueryParams: { kc_idp_hint: "google", ui_locales: "fr" }
         /**
          * You can allso set where to redirect the user after
          * successful login
          */
          // redirectUrl: "/dashboard"
    });
    } else {
        // The user is logged in.

        const {
            // The accessToken is what you'll use as a Bearer token to
            // authenticate to your APIs
            accessToken
        } = await oidc.getTokens_next();

        fetch("https://api.your-domain.net/orders", {
            headers: {
                Authorization: \`Bearer \${accessToken}\`
            }
        })
         .then(response => response.json())
         .then(orders => console.log(orders));

        // To call when the user click on logout.
        // You can also redirect to a custom url with
        // { redirectTo: "specific url", url: "/bye" }
        oidc.logout({ redirectTo: "home" });

        const decodedIdToken = oidc.getDecodedIdToken();

        console.log(\`Hello \${decodedIdToken.preferred_username}\`);
    }
        `;
    }
    onCompanySecurityChanged() {
        console.log(this.companySecurity);
        this.companySecurityChanged.emit(this.companySecurity);
    }

    onViewSecret(environment: string) {
        this.viewSecret.emit({ companySecurity: this.companySecurity, environment: environment });
        this.changeDetectorRef.detectChanges();
    }

    onChangeEnvironment($event: { label: string; value: string }) {
        this.chosenEnvironment = $event.value;
        this.apiSecret = null;
    }

    onChangeIntegrationEnvironment($event: { label: string; value: string }) {
        this.chosenEnvironmentIntegration = $event.value;
        this.setInstructions();
    }

    onChangeIntegrationTech($event: { label: string; value: string }) {
        this.chosenTechIntegration = $event.value;
        this.setInstructions();
    }

    identifyApplicationUrl(index, item) {
        return index;
    }

    identifyRedirectUrl(index, item) {
        return index;
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
