import 'reflect-metadata';
import { Component } from '@angular/core';

@Component({
    selector: 'codex-progress',
    template: `
        <div
            class="p-2 rounded-full border border-dark-border absolute bottom-1 right-1 transform -translate-x-1/2 -translate-y-1/2 z-[111111] bg-dark-background_level_1 shadow">
            <video autoplay loop muted playsinline style="width: 70px; height: 70px">
                <source src="/src/images/theme/loading.webm" type="video/webm" />
            </video>
        </div>
    `,
    standalone: false,
})
export class ProgressComponent {}
