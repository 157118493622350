import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CurrentContext, LoggerService } from '@backoffice/utils';
import {
    Layer,
    OverviewTemplateDto,
    Part,
    PartType,
    PartTypeDto,
    SelectedFacets,
    Template,
    TemplateCreatedDto,
} from '@backoffice/data-access/editor';
import { PartTypeService } from '../../services/template/part-type.service';
import { PartService } from '../../services/template/part.service';
import { LayerService } from '../../services/template/layer.service';
import { TemplateService } from '../../services/template/template.service';
import {
    addPartToSelection,
    changeCurrentScreenType,
    clearTemplateDialogData,
    createTemplateSuccess,
    deleteTemplates,
    deselectPart,
    disablePanningToolSwitch,
    disableSelectionToolSwitch,
    enablePanningTool,
    enablePanningToolSwitch,
    enableSelectionTool,
    enableSelectionToolSwitch,
    endDrag,
    findTemplate,
    loadTemplateDialogData,
    refreshTemplate,
    selectPart,
    startDrag,
    templateDialogFacetsChanged,
    templateDialogFilterPluginsChanged,
    templateDialogPaginationChanged,
    templateDialogSearchTermChanged,
    updateTemplate,
} from '../actions/templates-editor.actions';
import { templateContextSelectors, templateEditorSelectors } from '../selectors/template-editor.selectors';
import { AppFacade } from '@core/facades/app.facade';
import { MatDialog } from '@angular/material/dialog';
import { backofficeEnvironment } from '@shared/environment';
import { Page } from '@shared/data-access';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { plainToClass } from 'class-transformer';
import { ComponentType } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { clearActionFromCache } from '../actions/action-editor.actions';

declare var jQuery: any;

@Injectable()
export class TemplateEditorFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.applicationStateStore.select(selectCurrentContext);

    get dialogData(): Observable<Page<OverviewTemplateDto> | undefined> {
        return this.store.select(templateEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(templateEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(templateEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(templateEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(templateEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins: boolean;
    }> {
        return this.store.select(templateEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly applicationStateStore: Store<ApplicationState>,
        private readonly templateService: TemplateService,
        private readonly layerService: LayerService,
        private readonly partService: PartService,
        private readonly partTypeService: PartTypeService,
        private readonly translateService: TranslateService,
        private readonly appFacade: AppFacade,
        private readonly dialog: MatDialog,
        private readonly log: LoggerService
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearTemplateDialogData());
    }

    create(componentType: ComponentType<any>, reusableComponent: boolean = false): Observable<string> {
        this.log.info('Create new template');
        return combineLatest([
            this.appFacade.selectedCompany,
            this.appFacade.selectedApplication,
            this.dialog
                .open(
                    componentType,
                    Object.assign(
                        { disableClose: true, data: { reusableComponent: reusableComponent } },
                        backofficeEnvironment.dialogConfig.extrasmall
                    )
                )
                .afterClosed(),
        ]).pipe(
            take(1),
            filter(([company, application, { create }]) => !!create && !!company && !!application),
            switchMap(([{ id: companyId }, { id: applicationId }, { language, type, templateName, dataFormatIds }]) => {
                if (type === 'FORM_COMPONENT_FROM_DATAFORMAT') {
                    return this.templateService
                        .create(
                            {
                                languageCode: language,
                                type,
                                companyId,
                                applicationId,
                                name: templateName,
                                dataFormatIds,
                            },
                            companyId,
                            applicationId
                        )
                        .pipe(
                            tap(({ id, generateTemplateResponse }) => {
                                if (
                                    !!generateTemplateResponse &&
                                    !!generateTemplateResponse.generatedTemplates &&
                                    generateTemplateResponse.generatedTemplates.length > 0
                                ) {
                                    generateTemplateResponse.generatedTemplates.forEach(generatedTemplate => {
                                        this.store.dispatch(createTemplateSuccess({ id: generatedTemplate }));
                                    });
                                } else {
                                    this.store.dispatch(createTemplateSuccess({ id }));
                                }
                            }),
                            map(({ id }) => id)
                        );
                } else {
                    return this.templateService
                        .create(
                            {
                                languageCode: language,
                                type,
                                companyId,
                                applicationId,
                                name: templateName,
                                dataFormatIds,
                            },
                            companyId,
                            applicationId
                        )
                        .pipe(
                            tap(({ id }) => this.store.dispatch(createTemplateSuccess({ id }))),
                            map(({ id }) => id)
                        );
                }
            })
        );
    }

    refreshTemplate(templateId: string): void {
        this.store.dispatch(refreshTemplate({ id: templateId }));
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(templateDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(templateDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(templateDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(templateDialogFilterPluginsChanged({ filterPlugins }));
    }

    copyTemplate(copyFromTemplateId: string, languageCode: string): Observable<TemplateCreatedDto> {
        return this.currentContext$.pipe(
            filter((currentContext): currentContext is CurrentContext => !!currentContext),
            take(1),
            switchMap(currentContext =>
                this.templateService.create(
                    {
                        languageCode,
                        companyId: currentContext.selectedCompany.id,
                        applicationId: currentContext.selectedApplication.id,
                        copyFromTemplateId,
                    },
                    currentContext.selectedCompany.id,
                    currentContext.selectedApplication.id
                )
            )
        );
    }

    copyTemplates(templates: { id: string; languageCode: string }[]): Observable<TemplateCreatedDto[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return forkJoin(templates.map(template => this.copyTemplate(template.id, template.languageCode)));
            })
        );
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting template <${ids}>`);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteTemplates({ ids }));
        }
    }

    deselectPart(templateId: string, part: Part): void {
        this.store.dispatch(deselectPart({ templateId, deselectedPartId: part.id }));
    }

    endDrag(templateId: string): void {
        this.store.dispatch(endDrag({ templateId }));
    }

    addPartToSelection(part: Part, templateId: string): void {
        this.store.dispatch(addPartToSelection({ part, templateId }));
    }

    selectPart(part: Part, templateId: string): void {
        this.store.dispatch(selectPart({ part, templateId }));
    }

    enableSelectionTool(templateId: string) {
        this.store.dispatch(enableSelectionTool({ templateId }));
    }

    enablePanningTool(templateId: string) {
        this.store.dispatch(enablePanningTool({ templateId }));
    }

    enableSelectionToolSwitch(templateId: string): void {
        this.store.dispatch(enableSelectionToolSwitch({ templateId }));
    }

    enablePanningToolSwitch(templateId: string): void {
        this.store.dispatch(enablePanningToolSwitch({ templateId }));
    }

    disableSelectionToolSwitch(templateId: string): void {
        this.store.dispatch(disableSelectionToolSwitch({ templateId }));
    }

    disablePanningToolSwitch(templateId: string): void {
        this.store.dispatch(disablePanningToolSwitch({ templateId }));
    }

    startDrag(part: Part, templateId: string): void {
        this.store.dispatch(startDrag({ part, templateId }));
    }

    changeCurrentScreenType(currentScreenTypes: number[], templateId: string): void {
        this.store.dispatch(changeCurrentScreenType({ templateId, currentScreenTypes }));
    }

    findAll(
        keyword: string | undefined,
        filters: string[],
        orderBy: string,
        page: number,
        maxResults: number,
        applicationId?: string | undefined
    ): Observable<Page<OverviewTemplateDto>> {
        return this.currentContext$.pipe(
            filter((currentContext): currentContext is CurrentContext => !!currentContext),
            take(1),
            switchMap(currentContext =>
                this.templateService.find(
                    currentContext.selectedCompany.id,
                    applicationId ? applicationId : currentContext.selectedApplication.id,
                    {
                        keyword,
                        orderBy,
                        filters,
                        page,
                        maxResults,
                    }
                )
            )
        );
    }

    public findLayers(templateId: string): Observable<Layer[]> {
        if (!!templateId) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => {
                    return this.layerService.getLayers(
                        templateId,
                        currentContext.selectedCompany.id,
                        currentContext.selectedApplication.id
                    );
                })
            );
        } else {
            return of([]);
        }
    }

    public findParts(containerId: string): Observable<Part[]> {
        if (!!containerId) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => {
                    return this.partService.getParts(containerId, currentContext.selectedCompany.id, currentContext.selectedApplication.id);
                })
            );
        } else {
            return of([]);
        }
    }

    removeInheritance(templateId: string): Observable<void> {
        return this.currentContext$.pipe(
            filter((currentContext): currentContext is CurrentContext => !!currentContext),
            take(1),
            switchMap(currentContext =>
                this.templateService.removeInheritance(templateId, currentContext.selectedCompany.id, currentContext.selectedApplication.id)
            )
        );
    }

    findById(id: string, templateApplicationId?: string | undefined, languageCode?: string | undefined): Observable<Template> {
        this.log.info(`Find template by id ${id}`);
        this.store.dispatch(findTemplate({ id, templateApplicationId, languageCode }));
        return this.store.select(templateEditorSelectors.byId(id)).pipe(
            filter((template: Template | undefined): template is Template => !!template),
            map(template => plainToClass(Template, template))
        );
    }

    get currentScreenTypes(): Observable<number[] | undefined> {
        return this.store.select(templateContextSelectors.currentScreenTypes());
    }

    get selectedPart(): Observable<Part | undefined> {
        return this.store.select(templateContextSelectors.selectedPart());
    }

    get selectedParts(): Observable<Part[] | undefined> {
        return this.store.select(templateContextSelectors.selectedParts());
    }

    get currentTool(): Observable<string | undefined> {
        return this.store.select(templateContextSelectors.currentTool());
    }

    get draggingPart(): Observable<Part | undefined> {
        return this.store.select(templateContextSelectors.draggingPart());
    }

    isPartSelected(partToCheck: Part): Observable<boolean> {
        return this.selectedParts.pipe(
            map(selectedParts => {
                return !!selectedParts && !!selectedParts.find(part => part.id === partToCheck.id);
            })
        );
    }

    isDraggingPart(partToCheck: Part): Observable<boolean> {
        return this.draggingPart.pipe(
            map(draggingPart => {
                return !!draggingPart && draggingPart.id === partToCheck.id;
            })
        );
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadTemplateDialogData());
    }

    update(template: Template): void {
        this.log.info(`Update template`, [template]);
        this.store.dispatch(updateTemplate({ template }));
    }

    getPartTypes(): Observable<PartType[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.partTypeService.getPartTypes(currentContext?.selectedCompany.id, currentContext?.selectedApplication.id).pipe(
                    map((partTypeDtos: PartTypeDto[]) =>
                        partTypeDtos.map(partTypeDto => {
                            return plainToClass(PartType, {
                                id: partTypeDto.id,
                                key: partTypeDto.key,
                                name: partTypeDto.name
                                    ? partTypeDto.name
                                    : this.translateService.instant('v2.part.type.' + partTypeDto.key + '.name'),
                                description: partTypeDto.description
                                    ? partTypeDto.description
                                    : this.translateService.instant('v2.part.type.' + partTypeDto.key + '.description'),
                                iconName: partTypeDto.iconName,
                                initialSizeX: partTypeDto.initialSizeX,
                                initialSizeY: partTypeDto.initialSizeY,
                                initialSizeXUnit: partTypeDto.initialSizeXUnit,
                                initialSizeYUnit: partTypeDto.initialSizeYUnit,
                                initialPositionX: partTypeDto.initialPositionX,
                                initialPositionY: partTypeDto.initialPositionY,
                                initialPositionXUnit: partTypeDto.initialPositionXUnit,
                                initialPositionYUnit: partTypeDto.initialPositionYUnit,
                                category: partTypeDto.category,
                                arguments: partTypeDto.arguments,
                            });
                        })
                    )
                );
            })
        );
    }
}
