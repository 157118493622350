import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ActionEditorFacade,
    DataFormatEditorFacade,
    EditorFacade,
    JobEditorFacade,
    OverviewActionDto,
    OverviewDataFormatDto,
    OverviewTemplateDto,
    TemplateEditorFacade,
} from '@backoffice/data-access/editor';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { Page } from '@shared/data-access';
import * as d3 from 'd3';
import { ActionCtxList } from '../../../../../../data-access/editor/src/lib/action/model/action-ctx-list';
import * as dagre from '@dagrejs/dagre';
import { graphlib } from '@dagrejs/dagre';
import { GenerativeTaskDto } from '../../../../../../data-access/editor/src/lib/generative-tasks/interfaces/generative-task.dto';
import { IPoint } from '@foblex/2d';
import { generateGuid } from '@foblex/utils';
import { FCanvasComponent } from '@foblex/flow';
import Graph = graphlib.Graph;
import { GenerativeTaskService } from '../../../../../../data-access/editor/src/lib/services/generative-task.service';
import { map, switchMap, tap } from 'rxjs/operators';

interface NodeDatum extends d3.SimulationNodeDatum {
    id: string;
    name: string;
    type: string;
    img: string;
    icon?: string;
}

interface INodeViewModel {
    id: string;
    generativeTaskId: string;
    connectorId: string;
    position: IPoint;
    description: string;
    prompt: string;
    editing: boolean;
    type: string;
    tasks: {
        name: string;
        type: string;
        prompt: string;
    }[];
}

interface IConnectionViewModel {
    id: string;
    from: string;
    to: string;
}

@Component({
    selector: 'codex-application-overview',
    templateUrl: './application-overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ApplicationOverviewComponent implements OnInit, AfterViewInit {
    actions$: Observable<Page<OverviewActionDto>>;
    generativeTasks$: BehaviorSubject<GenerativeTaskDto[]> = new BehaviorSubject<GenerativeTaskDto[]>([]);

    nodes$: BehaviorSubject<INodeViewModel[]> = new BehaviorSubject<INodeViewModel[]>([]);
    connections$: BehaviorSubject<IConnectionViewModel[]> = new BehaviorSubject<IConnectionViewModel[]>([]);

    @ViewChild('canvas')
    protected fCanvasComponent!: FCanvasComponent;

    private resizeSubject = new Subject<void>();

    public selectedNodeId: string;
    public selectedNodeType: string;

    constructor(
        public editorFacade: EditorFacade,
        public templateEditorFacade: TemplateEditorFacade, // Ensure correct facade class is used
        public dataFormatEditorFacade: DataFormatEditorFacade,
        public changeDetectorRef: ChangeDetectorRef,
        public generativeTaskService: GenerativeTaskService,
        public fb: FormBuilder
    ) {}

    ngAfterViewInit() {}

    @HostListener('window:resize')
    onResize() {
        this.resizeSubject.next();
    }

    initializeApplicationPlan() {
        this.templateEditorFacade.currentContext$
            .pipe(
                take(1),
                switchMap(currentContext => {
                    return this.generativeTaskService.getApplicationPlan(
                        currentContext.selectedApplication.id,
                        currentContext.selectedCompany.id
                    );
                })
            )
            .subscribe(applicationPlan => {
                this.generativeTasks$.next(applicationPlan);
            });
    }

    ngOnInit() {
        this.initializeApplicationPlan();

        let graph = new dagre.graphlib.Graph();
        graph.setGraph({ rankdir: 'TB', nodesep: 400, ranksep: 100 });
        this.initializeNodes(graph);

        setTimeout(() => {
            this.fCanvasComponent.centerGroupOrNode('main', true);
            this.fCanvasComponent.redraw();
            console.log(this.fCanvasComponent.getPosition());
        }, 5000);
        /*this.templateEditorFacade.currentContext$.pipe(take(1)).subscribe(context => {
            this.templates$ = this.templateEditorFacade.findAll(
                '',
                ['component:false'],
                'score desc',
                0,
                1000,
                context?.selectedApplication.id
            );
            this.actions$ = this.actionEditorFacade.findAll('', [], 'score desc', 0, 1000);
            this.dataformats$ = this.dataFormatEditorFacade.findAll('', [], 'score desc', 0, 1000);
            this.actionsContexts$ = this.actionEditorFacade.getAllContexts();
            combineLatest([this.templates$, this.actions$, this.dataformats$, this.actionsContexts$])
                .pipe()
                .subscribe(
                    ([templates, actions, dataformats, actionContexts]) => {}
                    //this.initGraph(templates, actions, dataformats, actionContexts)
                );
        });*/
    }

    public executeGenerativeTask(generativeTaskId: string) {
        this.templateEditorFacade.currentContext$
            .pipe(
                take(1),
                switchMap(currentContext => {
                    return this.generativeTaskService.execute(
                        generativeTaskId,
                        currentContext.selectedApplication.id,
                        currentContext.selectedCompany.id
                    );
                })
            )
            .subscribe(() => {
                this.initializeApplicationPlan();
            });
    }

    private getConnections(graph: Graph): IConnectionViewModel[] {
        return graph.edges().map(x => ({ id: generateGuid(), from: x.v, to: x.w }));
    }

    public setSelectedNode(nodeId: string, nodeType: string) {
        this.selectedNodeType = nodeType;
        this.selectedNodeId = nodeId;
    }
    private initializeNodes(graph: Graph) {
        let generativeNodes = [];
        this.generativeTasks$
            .pipe(
                map(generativeTasks => {
                    generativeTasks.forEach(generativeTask => {
                        generativeNodes.push({
                            id: generativeTask.id,
                            generativeTaskId: generativeTask.id,
                            type: generativeTask.type,
                            parentId: generativeTask.parentId,
                            prompt: generativeTask.prompt,
                        });
                    });
                    console.log('testje');
                    console.log(generativeTasks);
                    generativeNodes.forEach(node => {
                        graph.setNode(node.id, { width: node.type === 'main' ? 1000 : 500, height: node.type === 'main' ? 500 : 120 });
                        if (node.parentId != null) {
                            graph.setEdge(node.parentId, node.id, {});
                        }
                    });
                    dagre.layout(graph);

                    const minX = Math.min(
                        ...graph.nodes().map(n => {
                            let node = graph.node(n);
                            return node.x;
                        })
                    );
                    const maxX = Math.max(
                        ...graph.nodes().map(n => {
                            let node = graph.node(n);
                            return node.x;
                        })
                    );

                    const graphWidth = maxX - minX;
                    let offsetX = 0;
                    const viewportWidth = window.innerWidth - 500;
                    if (viewportWidth) {
                        offsetX = (viewportWidth - graphWidth) / 2 - minX;
                    }
                    // @ts-ignore
                    this.nodes$.next(
                        graph.nodes().map(x => {
                            let node = graph.node(x);
                            let generativeNode = generativeNodes.find(generativeNode => generativeNode.id === x);
                            return {
                                id: generateGuid(),
                                generativeTaskId: generativeNode?.generativeTaskId,
                                connectorId: x,
                                position: { x: node.x + offsetX, y: node.y + (generativeNode.parentId ? 500 : 0) },
                                editing: generativeNode?.editing,
                                description: generativeNode?.description,
                                type: generativeNode?.type,
                                tasks: generativeNode?.tasks,
                                prompt: generativeNode?.prompt,
                            };
                        })
                    );
                    this.connections$.next(this.getConnections(graph));
                    dagre.layout(graph);
                    this.changeDetectorRef.detectChanges();
                })
            )
            .subscribe();
    }
}
