<mat-dialog-content class="scrollbar scrollbar-primary">
    <div class="tabs-header-wrapper">
        <ul
            class="tabs-header"
            data-tabs-toggle="#plugins-tab-content"
            role="tablist"
            data-tabs-inactive-classes="tabs-tab-inactive"
            data-tabs-active-classes="tabs-tab-active">
            <li class="tabs-tab">
                <button
                    id="hub-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#hub-tab-panel"
                    role="tab"
                    aria-controls="hub-tab-panel"
                    aria-selected="true">
                    <svg aria-hidden="true" focusable="false" class="icon"><use href="#hub" class="ui-element"></use></svg>
                    Hub (Marketplace)
                </button>
            </li>
            <li class="tabs-tab">
                <button
                    id="installed-plugins-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#installed-plugins"
                    role="tab"
                    aria-controls="installed-plugins"
                    aria-selected="false">
                    <svg aria-hidden="true" focusable="false" class="icon"><use href="#plugins" class="ui-element"></use></svg>
                    Installed plugins
                </button>
            </li>
        </ul>
    </div>
    <div id="plugins-tab-content" class="p-2">
        <div id="hub-tab-panel" class="hidden" role="tabpanel" aria-labelledby="hub-tab">
            <codex-marketplace-wrapper-page></codex-marketplace-wrapper-page>
        </div>
        <div id="installed-plugins" class="hidden" role="tabpanel" aria-labelledby="installed-plugins-tab">
            <codex-plugin-overview-page></codex-plugin-overview-page>
        </div>
    </div>
</mat-dialog-content>
