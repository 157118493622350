import { BrowserModule } from '@angular/platform-browser';
import { inject, NgModule, provideAppInitializer } from '@angular/core';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { reducerProvider, reducerToken } from './store/application.reducer';
import { Store, StoreModule } from '@ngrx/store';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { appRoutingModule } from './app.routing.module';
import { AuthenticatedModule } from './authenticated/authenticated.module';
import { Router } from '@angular/router';
import { ApplicationState } from './store/application.state';
import { CodexMissingTranslationHandler } from './translation/missing.translation.handler';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Environment } from './common/lib/environment/environment';
import { CommonsModule } from './common/common.module';
import { backofficeEnvironment } from '@shared/environment';
import { StatusModule } from './status/status.module';
import { NgHttpCachingConfig, NgHttpCachingModule } from 'ng-http-caching';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ApplicationAndCompanySerializer } from './store/router-store/router.serializer';
import { CoreModule } from '@core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { Effects } from './store/context/context.effects';
import { DatePipe } from '@angular/common';
import { BackofficeUtilsModule } from '@backoffice/utils';
import { BackofficeUtilsRoutingModule } from '@backoffice/utils-routing';
import { ExtendedRenderer, MarkdownModule, MARKED_OPTIONS, MarkedOptions, MarkedRenderer, provideMarkdown } from 'ngx-markdown';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, 'i18n/', '.json');
}

const ngHttpCachingConfig: NgHttpCachingConfig = {
    lifetime: 2000,
};

export const markedOptionsFactory = (): MarkedOptions => {
    const renderer = new ExtendedRenderer();

    renderer.link = ({ href, text }): string => {
        return `<a target="_blank" href="${href}">${text}</a>`;
    };

    return {
        renderer: renderer,
        gfm: true,
        breaks: true,
        pedantic: false,
    };
};

function initializeKeycloak(keycloak: KeycloakService) {
    return () => {
        return keycloak.init({
            config: {
                url: `${backofficeEnvironment.amLink}`,
                realm: 'nocodex',
                clientId: 'nocodex-broker',
            },
            initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false,
            },
        });
    };
}

@NgModule({
    providers: [
        {
            provide: MissingTranslationHandler,
            useClass: CodexMissingTranslationHandler,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        provideMarkdown({ markedOptions: { provide: MARKED_OPTIONS, useFactory: markedOptionsFactory } }),
        provideAppInitializer(() => {
            const initializerFn = initializeKeycloak(inject(KeycloakService));
            return initializerFn();
        }),
        DatePipe,
        Environment,
        reducerProvider,
    ],
    declarations: [AppComponent],
    imports: [
        AnimationModule(),
        appRoutingModule,
        AuthenticatedModule,
        BrowserModule,
        CommonsModule,
        CoreModule,
        HttpClientModule,
        KeycloakAngularModule,
        MonacoEditorModule.forRoot(),
        StatusModule,
        HttpClientXsrfModule,
        MarkdownModule.forRoot(),
        NgHttpCachingModule.forRoot(ngHttpCachingConfig),
        StoreRouterConnectingModule.forRoot({
            serializer: ApplicationAndCompanySerializer,
        }),
        StoreModule.forRoot(reducerToken, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        /*StoreDevtoolsModule.instrument({
            maxAge: 20,
            logOnly: backofficeEnvironment.production,
        }),*/
        EffectsModule.forRoot([Effects]),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BackofficeUtilsModule,
        BackofficeUtilsRoutingModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private translateService: TranslateService,
        private router: Router,
        private store: Store<ApplicationState>
    ) {
        translateService.setDefaultLang('en');
    }
}

export function AnimationModule(): any {
    return backofficeEnvironment.disableAnimations ? NoopAnimationsModule : BrowserAnimationsModule;
}
