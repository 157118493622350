import { Component, Input, OnInit } from '@angular/core';
import { BillingFacade } from '@billing/facade/billing.facade';
import { GenerativeTaskService } from '../../../../../data-access/editor/src/lib/services/generative-task.service';
import { BehaviorSubject, catchError, combineLatest, interval, Observable, of, switchMap, takeWhile } from 'rxjs';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { selectCurrentContext } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { filter, map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { ActionEditorFacade, EditorFacade, TemplateEditorFacade } from '@backoffice/data-access/editor';
import { GenerativeTaskDto } from '../../../../../data-access/editor/src/lib/generative-tasks/interfaces/generative-task.dto';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import 'deep-chat';
import { AppFacade } from '@core/facades/app.facade';
import { KeycloakService } from 'keycloak-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { signalSetFn } from '@angular/core/primitives/signals';
import { CompanyEditComponent } from '../../../../../feature/company/edit-company/src/lib/page/my-company-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'codex-generative',
    templateUrl: './generative.component.html',
    styleUrls: ['./generative.component.scss'],
    standalone: false,
})
export class GenerativeComponent implements OnInit {
    private billingRefresh$ = new BehaviorSubject<void>(undefined);

    public billingPackage$ = this.billingRefresh$.pipe(switchMap(() => this.billingFacade.getBillingPackage()));

    public activeActionId: string;

    public prompt: string;

    public actions: string[] = [];

    @Input()
    public showHeader: boolean = true;

    history = [
        {
            role: 'ai',
            text:
                '## Hey there! \n\n' +
                'I am your personal assistant within NoCode-X. I am still in alpha but you can already test me out! \n\n' +
                '### Currently you can do the following: \n\n' +
                '- **Build Actions:** Let me help you create actions effortlessly.\n' +
                '' +
                '### In the future you will be able to: \n\n\n\n' +
                '- Create & update templates\n' +
                "- Create & update data formats **(now available through 'create data format')**\n" +
                '- Create & update images in your media library \n' +
                '- Create & update APIs \n' +
                "- Generate test data **(now available through 'create data')**\n" +
                '- Create an application from one single prompt\n',
        },
    ];

    currentContext$: Observable<{
        userLanguage: string;
        selectedCompany: CompanyDto;
        selectedApplication: ApplicationDto;
    }> = this.store.select(selectCurrentContext);

    currentConversation: BehaviorSubject<GenerativeTaskDto[]> = new BehaviorSubject<GenerativeTaskDto[]>([]);

    rows: number = 3;
    textareaHeight: string = `${this.rows * 2.5}em`;

    chatElementRef: any;

    generativeTaskHistory$: BehaviorSubject<GenerativeTaskDto[]> = new BehaviorSubject<GenerativeTaskDto[]>([]);

    constructor(
        private billingFacade: BillingFacade,
        private appFacade: AppFacade,
        private editorFacade: EditorFacade,
        private actionFacade: ActionEditorFacade,
        private templateFacade: TemplateEditorFacade,
        private generativeTaskService: GenerativeTaskService,
        private keycloakService: KeycloakService,
        private store: Store<ApplicationState>,
        private snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {}

    changeRows() {
        if (this.rows === 3) {
            this.rows = 10;
        } else {
            this.rows = 3;
        }

        this.textareaHeight = `${this.rows * 2.5}em`;
    }

    ngOnInit(): void {
        this.initializeChatMessages();
        setTimeout(() => {
            this.appFacade.context.pipe().subscribe(context => {
                if (context && context.selectedApplication && context.selectedCompany) {
                    this.chatElementRef = document.getElementById('nocode-x-assistant');
                    this.chatElementRef.messageStyles = {
                        html: {
                            shared: {
                                bubble: {
                                    backgroundColor: 'unset',
                                    padding: '0px',
                                    width: '100%',
                                    textAlign: 'right',
                                    border: 'none',
                                },
                            },
                        },
                        default: {
                            shared: {
                                outerContainer: {
                                    padding: '2px',
                                },
                                innerContainer: {
                                    width: '100%',
                                },
                            },
                            ai: {
                                bubble: {
                                    backgroundColor: '#DD7EFF15',
                                    border: '1px solid #9C38FF',
                                    color: '#94a3b8',
                                },
                            },
                            system: {
                                bubble: {
                                    backgroundColor: '#DD7EFF15',
                                    border: '1px solid #334155',
                                    color: '#94a3b8',
                                },
                            },
                            actions: {
                                bubble: {
                                    backgroundColor: 'transparent',
                                    border: '1px solid #334155',
                                    color: '#94a3b8',
                                },
                            },
                            user: {
                                bubble: {
                                    backgroundColor: '#DD7EFF15',
                                    border: '1px solid #334155',
                                    color: '#94a3b8',
                                },
                            },
                        },
                    };
                    this.chatElementRef.htmlClassUtilities = {
                        generating: {
                            styles: {
                                default: {
                                    width: 'fit-content',
                                    marginBottom: '12px',
                                    position: 'relative',
                                    border: '1px solid rgb(156, 56, 255)',
                                    borderRadius: '10px',
                                    backgroundColor: '#DD7EFF15',
                                    stroke: '#94a3b8',
                                    padding: '0.42em 0.55em',
                                    textAlign: 'left',
                                },
                            },
                        },
                        'generating::before': {
                            styles: {
                                default: {
                                    content: '',
                                    position: 'absolute',
                                    top: '5px',
                                    left: '-20px',
                                    right: '-20px',
                                    bottom: '5px',
                                    background: 'linear-gradient(90deg, #c4acff, #5f00bc)',

                                    zIndex: '-1',
                                    animation: 'rotate 2s linear infinite',
                                },
                            },
                        },
                        'deep-chat-button': {
                            styles: {
                                default: {
                                    width: '2rem',
                                    height: '2rem',
                                    cursor: 'pointer',
                                    border: '1px solid #334155',
                                    backgroundColor: '#DD7EFF15',
                                    borderRadius: '8px',
                                    padding: '0.25rem',
                                    paddingRight: '0px',
                                    paddingLeft: '0px',
                                    stroke: '#94a3b8',
                                },
                                hover: { backgroundColor: '#d1d1d1' },
                            },
                        },
                    };
                    this.connectChatElementNoStream(context.selectedApplication.id, context.selectedCompany.id);
                }
            });
        }, 1000);
        this.billingRefresh$.next();
        this.editorFacade.activeTab.pipe(take(1)).subscribe(activeTab => {
            if (activeTab.type === 'action') {
                this.activeActionId = activeTab.typeId;
                this.actions = ['generate-name', 'generate-description', 'explain-action'];
            }
        });

        this.initCurrentConversation();
        window['codex'] = window['codex'] || {};
        window['codex'].generativeActions = window['codex'].generativeActions || {};
        window['codex'].generativeActions.onExecuteActionChange = this.onExecuteActionChange.bind(this);
        window['codex'].generativeActions.onExecuteChangeReference = this.onExecuteChangeReference.bind(this);
        window['codex'].generativeActions.onExecuteActionUndo = this.onExecuteActionUndo.bind(this);
        window['codex'].generativeActions.onChangeQuality = this.onChangeQuality.bind(this);
    }

    handleGeneratingMessage(message: string) {
        console.log(this.chatElementRef.getMessages());
        let messages = this.chatElementRef.getMessages();
        console.log(messages[messages.length - 1].role);
        if (messages[messages.length - 1].role == 'user' || messages[messages.length - 1].text === 'Ok, let me see what I can do') {
            this.chatElementRef.addMessage({
                html:
                    '<div class="generative-message-text generating"><div class="h-5 w-12 rounded">' +
                    message +
                    '</div></div>' +
                    '' +
                    '<div class="generative-message-text generating"><div class="h-5 w-12 rounded"><img height="20" width="50" class="object-cover" src="/src/images/theme/chat_loading.gif" style="height: 50px; object-fit: scale-down; margin-top: -10px; margin-bottom: -16px; margin-left: -5px; margin-right: -5px;" /></div></div>' +
                    '',
                role: 'ai',
                overwrite: false,
                isUpdate: true,
            });
        } else {
            this.chatElementRef.addMessage({
                html:
                    '<div class="generative-message-text generating"><div class="h-5 w-12 rounded">' +
                    message +
                    '</div></div>' +
                    '' +
                    '<div class="generative-message-text generating"><div class="h-5 w-12 rounded"><img height="20" width="50" class="object-cover" src="/src/images/theme/chat_loading.gif" style="height: 50px; object-fit: scale-down; margin-top: -10px; margin-bottom: -16px; margin-left: -5px; margin-right: -5px;" /></div></div>' +
                    '',
                role: 'ai',
                overwrite: true,
                isUpdate: true,
            });
        }
    }

    handleSuccess(generativeTaskDto: GenerativeTaskDto) {
        if (generativeTaskDto.changeType === 'ACTION') {
            this.generativeTaskHistory$.value.push(generativeTaskDto);
            this.onExecuteActionChange(generativeTaskDto.id).subscribe(() => {
                const settings = document.getElementById('invocation-settings');
                if (settings) {
                    settings.classList.remove('show');
                }
                this.actionFacade.clearFromCache(generativeTaskDto.reference);
                this.actionFacade.findById(generativeTaskDto.reference);
            });
            this.chatElementRef.addMessage({
                text: generativeTaskDto.answer,
                html:
                    '<div style="display: flex; justify-content: start; gap: 0.25rem">\n' +
                    '<button class="deep-chat-button" onclick="window[\'codex\'].generativeActions.onExecuteActionUndo(\'' +
                    generativeTaskDto.id +
                    '\')">\n' +
                    '<svg id="undo-changes" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path d="M16.6666 5.83333H8.33325C5.57183 5.83333 3.33325 8.07191 3.33325 10.8333C3.33325 13.5948 5.57183 15.8333 8.33325 15.8333H16.6666M16.6666 5.83333L13.3333 2.5M16.6666 5.83333L13.3333 9.16667" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />\n' +
                    '</svg>' +
                    '</button>' +
                    '</div>',
                role: 'ai',
                overwrite: true,
            });
            this.chatElementRef.disableSubmitButton(false);
        } else if (generativeTaskDto.changeType === 'TEMPLATE') {
            console.log('hier hier hier!!');
            this.onExecuteActionChange(generativeTaskDto.id).subscribe(() => {
                const settings = document.getElementById('invocation-settings');
                if (settings) {
                    settings.classList.remove('show');
                }

                this.chatElementRef.addMessage({
                    text: generativeTaskDto.answer,
                    html:
                        '<div style="display: flex; justify-content: start; gap: 0.25rem">\n' +
                        '<button class="deep-chat-button" onclick="window[\'codex\'].generativeActions.onExecuteActionUndo(\'' +
                        generativeTaskDto.id +
                        '\')">\n' +
                        '<svg id="undo-changes" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                        '<path d="M16.6666 5.83333H8.33325C5.57183 5.83333 3.33325 8.07191 3.33325 10.8333C3.33325 13.5948 5.57183 15.8333 8.33325 15.8333H16.6666M16.6666 5.83333L13.3333 2.5M16.6666 5.83333L13.3333 9.16667" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />\n' +
                        '</svg>' +
                        '</button>' +
                        '</div>',
                    role: 'ai',
                    overwrite: true,
                });
                this.chatElementRef.disableSubmitButton(false);
                this.templateFacade.refreshTemplate(generativeTaskDto.reference);
                setTimeout(() => {
                    this.templateFacade.findById(generativeTaskDto.reference, generativeTaskDto.applicationId);
                }, 1000);
            });
        } else if (generativeTaskDto.changeType === 'TEMPLATE_INTERMEDIATE') {
            this.generativeTaskService
                .getByParentId(generativeTaskDto.id, generativeTaskDto.applicationId, generativeTaskDto.companyId)
                .subscribe(result => {
                    let generativeTaskHistory = this.generativeTaskHistory$.value;
                    generativeTaskHistory = generativeTaskHistory.concat(result);
                    this.generativeTaskHistory$.next(generativeTaskHistory);
                });
            this.chatElementRef.addMessage({
                html:
                    '<div class="generative-message-text generating"><div class="h-5 w-12 rounded">' +
                    generativeTaskDto.answer +
                    '</div></div>' +
                    '' +
                    '<div class="generative-message-text generating"><div class="h-5 w-12 rounded"><img height="20" width="50" class="object-cover" src="/src/images/theme/chat_loading.gif" style="height: 50px; object-fit: scale-down; margin-top: -10px; margin-bottom: -16px; margin-left: -5px; margin-right: -5px;" /></div></div>' +
                    '',
                role: 'ai',
                overwrite: true,
                isUpdate: true,
            });
        }
        this.billingRefresh$.next();
    }

    initializeChatMessages() {
        this.generativeTaskHistory$
            .pipe(
                map(taskHistory => {
                    return taskHistory.slice(-1)[0];
                }),
                filter(lastTask => !!lastTask),
                switchMap(lastTask => {
                    return interval(2000).pipe(
                        take(1000), // Poll every 1 second
                        switchMap(() => this.generativeTaskService.get(lastTask.id, lastTask.applicationId, lastTask.companyId)),
                        tap(generativeTaskDto => {
                            if (generativeTaskDto.status === 'GENERATING') {
                                this.handleGeneratingMessage(generativeTaskDto.answer);
                            } else if (generativeTaskDto.status === 'SUCCESS') {
                                this.handleSuccess(generativeTaskDto);
                            } else if (generativeTaskDto.status === 'FAILED') {
                                this.chatElementRef.addMessage({
                                    error: 'Something went wrong',
                                    role: 'error',
                                    overwrite: true,
                                    isUpdate: true,
                                });
                                this.chatElementRef.disableSubmitButton(false);
                            }
                        }),
                        catchError(() => this.handleError()),
                        takeWhile(generativeTask => generativeTask.status === 'GENERATING', true)
                    ); // Keep polling while status is 'GENERATING'
                })
            )
            .subscribe();
    }

    connectChatElementNoStream(applicationId: string, companyId: string) {
        this.chatElementRef.connect = {
            handler: (body, signals) => {
                combineLatest([this.editorFacade.activeTab, this.keycloakService.getToken()])
                    .pipe(
                        take(1),
                        switchMap(([activeTab, token]) => {
                            return this.generativeTaskService
                                .create({
                                    prompt: body.messages[0].text,
                                    type: 'OPEN',
                                    name: 'test',
                                    companyId: companyId,
                                    applicationId: applicationId,
                                    context: {
                                        contextReferenceId: activeTab.typeId,
                                        contextType: activeTab.type,
                                    },
                                })
                                .pipe(
                                    tap(generativeTaskCreated => {
                                        let generativeTaskHistory = this.generativeTaskHistory$.value;
                                        generativeTaskHistory.push({
                                            id: generativeTaskCreated.id,
                                            companyId,
                                            applicationId,
                                        });
                                        this.generativeTaskHistory$.next(generativeTaskHistory);
                                        signals.onResponse({ text: 'Ok, let me see what I can do', overwrite: false });
                                        this.chatElementRef.disableSubmitButton(true);
                                    })
                                );
                        })
                    )
                    .subscribe();
            },
        };
    }

    onExecuteActionUndo = (generativeTaskId: string) => {
        let message = this.generativeTaskHistory$.value.find(generativeTask => generativeTask.id === generativeTaskId);
        if (message) {
            this.generativeTaskService
                .executeUndo(message, message.id, message.applicationId, message.companyId)
                .pipe(
                    switchMap(() => {
                        const settings = document.getElementById('invocation-settings');
                        if (settings) {
                            settings.classList.remove('show');
                        }
                        this.actionFacade.clearFromCache(message.reference);
                        return this.actionFacade.findById(message.reference);
                    }),
                    take(1)
                )
                .subscribe(() => {
                    this.snackBar.open('Reversed changed action', null, {
                        panelClass: ['success'],
                    });
                });
        }
    };

    onChangeQuality = (generativeTaskId: string) => {
        let message = { ...this.generativeTaskHistory$.value.find(generativeTask => generativeTask.id === generativeTaskId) };
        if (message) {
            message.quality = 9;
            this.generativeTaskService
                .changeQuality(message, message.id, message.applicationId, message.companyId)
                .pipe(take(1))
                .subscribe(() => {
                    this.snackBar.open('Set quality successfull', null, {
                        panelClass: ['success'],
                    });
                });
        }
    };

    onExecuteChangeReference = (generativeTaskId: string) => {
        let message = this.generativeTaskHistory$.value.find(generativeTask => generativeTask.id === generativeTaskId);
        if (message) {
            this.generativeTaskService
                .executeChangeReference(message, message.id, message.applicationId, message.companyId)
                .pipe(take(1))
                .subscribe(() => {
                    this.snackBar.open('Change generated content successfull', null, {
                        panelClass: ['success'],
                    });
                });
        }
    };

    handleError() {
        console.log('hier komen wei si!!!');
        // First remove the last message that was indicating a working AI assistant.
        let messages = this.chatElementRef.getMessages();
        if (messages[messages.length - 1].role !== 'user') {
            this.chatElementRef.updateMessage({ text: "I could not finish your task, i'm sorry", html: '' }, messages.length - 1);
        }

        // Add an error message.
        this.chatElementRef.addMessage({
            error: 'Something went wrong',
            role: 'error',
            overwrite: true,
            isUpdate: true,
        });

        // Scroll down
        setTimeout(() => {
            const chatMessageContainer = document.querySelector('.generative-chat-messages');
            if (chatMessageContainer) {
                chatMessageContainer.scrollTo({
                    top: chatMessageContainer.scrollHeight,
                    behavior: 'smooth',
                });
            }
        });
        // Enable the submit button for next messages.
        this.chatElementRef.disableSubmitButton(false);
        return of(null);
    }

    onExecuteActionChange = (generativeTaskId: string): Observable<any> => {
        let message = this.generativeTaskHistory$.value.find(generativeTask => generativeTask.id === generativeTaskId);
        const chatMessages = document.querySelector('.generative-chat-messages');
        if (message) {
            return this.generativeTaskService.executeChange(message, message.id, message.applicationId, message.companyId).pipe(
                tap(() => {
                    this.chatElementRef.addMessage({
                        text: 'Change implemented, anything else I can help you with?',
                        role: 'ai',
                        overwrite: true,
                    });
                }),
                catchError((err, caught) => {
                    return this.handleError();
                })
            );
        }
    };

    initCurrentConversation() {
        if (this.currentConversation.value.length === 0) {
            let currenConversationList = this.currentConversation.value;

            currenConversationList.push({
                id: 'hello',
                status: 'SUCCESS',
                answer:
                    '#Hey there! \n\n\n' +
                    'I am your personal assistant within NoCode-X. I am still in alpha but you can already test me out! \n\n' +
                    '## Currently you can do the following: \n\n\n\n' +
                    '* **Explain Actions:** Quickly understand what the currently opened action does. \n\n' +
                    '* **Generate Action Names:** Get a clear, professional name for your opened action.\n\n' +
                    '* **Create Action Descriptions:** Automatically generate concise and accurate descriptions for the opened action.\n\n' +
                    '* **Build Actions:** Let me help you create actions effortlessly.\n\n' +
                    '* **Get information on NoCode-X:** Instantly find details from the documentation. \n\n' +
                    '' +
                    '##### In the future you will be able to: \n\n\n\n' +
                    '* Create & update templates \n\n' +
                    "* Create & update data formats (now available through 'create data format') \n\n" +
                    '* Create & update images in your media library \n\n' +
                    '* Create & update APIs \n\n' +
                    "* Generate test data (now available through 'create data') \n\n" +
                    '* Vibe program from our application overview \n\n',
                prompt: null,
                type: null,
                name: 'test',
                applicationId: null,
                companyId: null,
                quality: 0,
            });

            const textarea = document.getElementById('sender-chat');
            const chatMessages = document.querySelector('.generative-chat-messages');

            setTimeout(() => {
                if (textarea) {
                    textarea.focus(); // Focus on the textarea
                    // @ts-ignore
                    textarea.setSelectionRange(0, 0);
                }
                if (chatMessages) {
                    chatMessages.scrollTo({ top: chatMessages.scrollHeight, behavior: 'smooth' });
                }
            }, 500);

            this.currentConversation.next(currenConversationList);
        }
    }

    onCloseGenerative() {
        const generativeChat = document.getElementById('generative-chat');
        setTimeout(() => {
            if (generativeChat) {
                generativeChat.classList.remove('show'); // Start the slide-in animation
            }
        }, 10);
    }

    executeGenerativeTask(taskType: string, referenceId: string, referenceType: string) {
        let prompt = this.prompt;
        this.prompt = '';
        const textarea = document.getElementById('sender-chat');
        const chatMessages = document.querySelector('.generative-chat-messages');

        let currenConversationList = this.currentConversation.value;
        currenConversationList.push({
            id: 'current',
            status: 'GENERATING',
            answer: null,
            prompt: prompt,
            type: taskType,
            name: 'test',
            applicationId: null,
            companyId: null,
            quality: 0,
        });
        setTimeout(() => {
            if (textarea) {
                textarea.focus(); // Focus on the textarea
                // @ts-ignore
                textarea.setSelectionRange(0, 0);
            }
            if (chatMessages) {
                chatMessages.scrollTo({ top: chatMessages.scrollHeight, behavior: 'smooth' });
            }
        }, 500);

        this.currentConversation.next(currenConversationList);

        return combineLatest([this.currentContext$, this.editorFacade.activeTab])
            .pipe(
                take(1),
                switchMap(([currentContext, activeTab]) => {
                    return this.generativeTaskService
                        .create({
                            prompt: prompt,
                            type: taskType,
                            name: 'test',
                            companyId: currentContext.selectedCompany.id,
                            applicationId: currentContext.selectedApplication.id,
                            context: {
                                contextReferenceId: activeTab.typeId,
                                contextType: activeTab.type,
                            },
                        })
                        .pipe(
                            tap(() => {
                                setTimeout(() => {
                                    if (textarea) {
                                        textarea.focus(); // Focus on the textarea
                                        // @ts-ignore
                                        textarea.setSelectionRange(0, 0);
                                    }
                                    if (chatMessages) {
                                        chatMessages.scrollTo({ top: chatMessages.scrollHeight, behavior: 'smooth' });
                                    }
                                }, 500);
                            }),
                            switchMap(generativeTaskCreated =>
                                interval(2000).pipe(
                                    take(1000), // Poll every 1 second
                                    switchMap(() =>
                                        this.generativeTaskService.get(
                                            generativeTaskCreated.id,
                                            currentContext.selectedApplication.id,
                                            currentContext.selectedCompany.id
                                        )
                                    ),
                                    tap(generativeTaskDto => {
                                        let existingGenerativeTask = this.currentConversation.value.find(
                                            currentConversationGenerativeTask =>
                                                generativeTaskDto.id === currentConversationGenerativeTask.id
                                        );
                                        if (existingGenerativeTask) {
                                            let currenConversationList = this.currentConversation.value;
                                            let index = currenConversationList.indexOf(existingGenerativeTask);
                                            currenConversationList.splice(index, 1);
                                            currenConversationList[index] = generativeTaskDto;
                                            this.currentConversation.next(currenConversationList);
                                        } else {
                                            let existingGenerativeTask = this.currentConversation.value.find(
                                                currentConversationGenerativeTask => 'current' === currentConversationGenerativeTask.id
                                            );
                                            let currenConversationList = this.currentConversation.value;
                                            let index = currenConversationList.indexOf(existingGenerativeTask);
                                            currenConversationList.splice(index, 1);
                                            currenConversationList[index] = generativeTaskDto;
                                            this.currentConversation.next(currenConversationList);
                                        }
                                        if (generativeTaskDto.status === 'SUCCESS' || generativeTaskDto.status === 'FAILED') {
                                            setTimeout(() => {
                                                if (chatMessages) {
                                                    chatMessages.scrollTo({
                                                        top: chatMessages.scrollHeight,
                                                        behavior: 'smooth',
                                                    });
                                                }
                                                this.billingRefresh$.next();
                                            }, 500);
                                        }
                                    }),
                                    takeWhile(generativeTask => generativeTask.status === 'GENERATING', true) // Keep polling while status is 'GENERATING'
                                )
                            ),
                            catchError((err, caught) => {
                                return this.handleError();
                            })
                        );
                })
            )
            .subscribe();
    }

    identifyConversation(index, item) {
        return item.id;
    }

    openTopUp() {
        this.currentContext$.pipe(take(1)).subscribe(currentContext => {
            this.dialog.open(CompanyEditComponent, {
                minWidth: '80vw',
                maxHeight: '95vh',
                data: {
                    companyId: currentContext.selectedCompany.id,
                },
            });
        });
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
