import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { SelectedFacets } from '../../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';
import { ApiDialogState } from '../../../store/state/api-dialog.state';
import { OverviewApiDto } from '../../../dto/overview/overview-api.dto';
import { DesignSystemEditorState } from '../state/design-system-editor.state';
import {
    clearDesignSystemDialogData,
    clearDesignSystemDialogFilter,
    deleteDesignSystemSuccess,
    designSystemDialogFacetsChanged,
    designSystemDialogFilterPluginsChanged,
    designSystemDialogPaginationChanged,
    designSystemDialogSearchTermChanged,
    fetchDesignSystemSuccess,
    loadDesignSystemDialogSuccess,
    updateColorDefinition,
    updateDesignSystem,
    updateTypography,
} from '../actions/design-system-editor.actions';
import { DesignSystem } from '@backoffice/data-access/editor';

const adapterAction = createEntityAdapter<DesignSystem>();
export const designSystemInitialState: DesignSystemEditorState = adapterAction.getInitialState();
export const designSystemDialogState: ApiDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const designSystemReducer = (initialData: DesignSystemEditorState) =>
    createReducer(
        initialData,
        on(fetchDesignSystemSuccess, (state, { designSystem }) => adapterAction.addOne(designSystem, state)),
        on(deleteDesignSystemSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state)),
        on(updateDesignSystem, (state, { designSystem }) => {
            const existingDesignsystem = new DesignSystem({ ...state.entities[designSystem.id] });
            if (!!existingDesignsystem) {
                existingDesignsystem.name = designSystem.name;
                existingDesignsystem.active = designSystem.active;
                return adapterAction.updateOne(
                    {
                        id: existingDesignsystem.id,
                        changes: new DesignSystem({ ...existingDesignsystem }),
                    },
                    state
                );
            }
            return state;
        }),
        on(updateColorDefinition, (state, { definition }) => {
            const designsystem = new DesignSystem({ ...state.entities[definition.designSystemId] });
            if (!!designsystem) {
                const theme = definition.theme === 'DARK' ? designsystem.dark : designsystem.standard;
                const { designSystemId, variable, type, value } = definition;
                theme.updateColor(variable, type, value);

                return adapterAction.updateOne(
                    {
                        id: designSystemId,
                        changes: new DesignSystem({ ...designsystem }),
                    },
                    state
                );
            }
            return state;
        }),
        on(updateTypography, (state, { typography }) => {
            const designsystem = new DesignSystem({ ...state.entities[typography.designSystemId] });
            if (!!designsystem) {
                const { id } = designsystem;
                designsystem.update(typography);
                return adapterAction.updateOne(
                    {
                        id,
                        changes: new DesignSystem({ ...designsystem }),
                    },
                    state
                );
            }
            return state;
        })
    );

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(designSystemDialogFacetsChanged, (state, { facets }) => facets),
        on(clearDesignSystemDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(designSystemDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearDesignSystemDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(designSystemDialogPaginationChanged, (_, { page }) => page),
        on(designSystemDialogSearchTermChanged, () => 0),
        on(clearDesignSystemDialogFilter, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(designSystemDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearDesignSystemDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(designSystemDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearDesignSystemDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewApiDto> | undefined) =>
    createReducer(
        initialData,
        on(loadDesignSystemDialogSuccess, (_, { data }) => data),
        on(clearDesignSystemDialogData, () => undefined)
    );

export const designSystemDialogReducers = combineReducers({
    page: pageReducer(designSystemDialogState.page),
    maxResults: maxResultsReducer(designSystemDialogState.maxResults),
    searchTerm: searchTermReducer(designSystemDialogState.searchTerm),
    result: resultReducer(designSystemDialogState.result),
    facets: facetsReducer(designSystemDialogState.facets),
    filterPlugins: filterPluginsReducer(designSystemDialogState.filterPlugins),
});
